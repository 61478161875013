import { Radio } from 'antd';
import * as React from 'react';

import { ID } from '@/shared/types/meta';
import { Options } from '@/shared/types/values';

import { TransComp } from '../../TransComp';

type RadioGroupProps<Value extends ID> = {
  options?: Options<Value>;
  disabled?: boolean;
};

const RadioGroup = <Value extends ID>({ options = [], ...props }: RadioGroupProps<Value>) => {
  const optionsList = React.useMemo(() => {
    return options.map((option) => ({
      ...option,
      label: <TransComp>{option.label}</TransComp>,
    }));
  }, [options]);

  return <Radio.Group {...props} options={optionsList}></Radio.Group>;
};

export default RadioGroup;
