import { ManagerTransportationStep } from '@/entities/stage/types';

export const stepsOrder: ManagerTransportationStep[] = [
  ManagerTransportationStep.transitCountryCargoArrival,
  ManagerTransportationStep.cargoArrival,
  ManagerTransportationStep.supplierAcceptanceCertificate,
  ManagerTransportationStep.supplierAcceptanceCertificateVerifying,
  ManagerTransportationStep.supplierSignedAcceptanceCertificate,
  ManagerTransportationStep.destinationCountryDocs,
  ManagerTransportationStep.destinationCountryAwb,
  ManagerTransportationStep.transitCountryCargoShipment,
  ManagerTransportationStep.destinationCountryCargoArrival,
  ManagerTransportationStep.transfer,
];
