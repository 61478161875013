import { ID } from '../types/meta';

export const apiPrefix = '/api';

const getUserPrefix = (endpoint: string): string => `/user/${endpoint}`;
const getTradePrefix = (endpoint: string): string => `/trade/${endpoint}`;
const getDocumentPrefix = (endpoint: string): string => `/document/${endpoint}`;
const getDictionaryPrefix = (endpoint: string): string => `/handbook/${endpoint}`;
const getNotificationPrefix = (endpoint: string): string => `/notification/${endpoint}`;
const getSettingsPrefix = (endpoint?: string): string =>
  endpoint ? getNotificationPrefix(`settings/${endpoint}`) : getNotificationPrefix('settings');
const getChatPrefix = (endpoint: string): string => `/chat/${endpoint}`;
const getThreadPrefix = (endpoint: string): string => `/thread/${endpoint}`;
const getMessagePrefix = ({ threadId, endpoint = '' }: { threadId: ID; endpoint?: string }): string =>
  getThreadPrefix(`${threadId}/message/${endpoint}`);
const getBidPrefix = (endpoint: string): string => `/bid/${endpoint}`;
const getBidListPrefix = (endpoint: string): string => getBidPrefix(`list/${endpoint}`);
const getWSPrefix = (endpoint: string): string => `/ws/${endpoint}`;
const getCompanyPrefix = (endpoint: string): string => `/company/${endpoint}`;
const getCompanyEmployeePrefix = (endpoint: string): string => getUserPrefix(`company/employee/${endpoint}`);

export const apiUrls = {
  user: {
    current: getUserPrefix('current'),
    add: getUserPrefix('add'),
    edit: (id: ID) => getUserPrefix(String(id)),
    login: getUserPrefix('login'),
    createPassword: getUserPrefix('create_password'),
    updatePassword: getUserPrefix('update_password'),
  },
  company: {
    list: getCompanyPrefix('list'),
    create: getCompanyPrefix('create'),
    edit: (id: ID) => getCompanyPrefix(String(id)),
    employeeList: (id: ID) => getCompanyPrefix(`${id}/employee/list`),
  },
  profile: {
    company: {
      company: getUserPrefix('company'),
    },
    employee: {
      list: getCompanyEmployeePrefix('list'),
      invite: getCompanyEmployeePrefix('invite'),
      edit: (id: ID) => getCompanyEmployeePrefix(String(id)),
    },
  },
  trade: {
    list: getTradePrefix('list'),
    downloadList: getTradePrefix('list/download'),
    create: getTradePrefix('create'),
    details: (id: ID) => getTradePrefix(String(id)),
    edit: (id: ID) => getTradePrefix(`${id}/edit`),
    action: (id: ID) => getTradePrefix(`${id}/action`),
    history: (id: ID) => getTradePrefix(`${id}/logs`),
    upload: (id: ID) => getTradePrefix(`${id}/upload`),
    downloadDocs: (id: ID) => getTradePrefix(`${id}/download`),
    delete: (id: ID) => getTradePrefix(`${id}/delete`),
  },
  document: {
    list: getDocumentPrefix('list'),
    download: getDocumentPrefix('list/download'),
    filters: getDocumentPrefix('filters'),
    original: (id: ID) => getDocumentPrefix(`${id}/original`),
    update: (id: ID) => getDocumentPrefix(String(id)),
    generationByDocType: <T extends string>(docType: T) => getDocumentPrefix(`generation/${docType}/`),
    generationById: (id: ID) => getDocumentPrefix(`generation/${id}`),
  },
  dictionaries: {
    legalCompanyList: getDictionaryPrefix('legal_company'),
    logisticCompanyList: getDictionaryPrefix('logistic_company'),
    currencyList: getDictionaryPrefix('currency'),
    productTypeList: getDictionaryPrefix('product_type'),
    productList: getDictionaryPrefix('product'),
    product: (id: ID) => getDictionaryPrefix(`product/${id}`),
    metalList: getDictionaryPrefix('metal'),
    signatoryList: getDictionaryPrefix('signatory'),
    countryList: getDictionaryPrefix('country'),
  },
  notifications: {
    list: getNotificationPrefix('list'),
    read: getNotificationPrefix('read'),
    tgBotLink: getNotificationPrefix('tg_bot_link'),
  },
  settings: {
    settings: getSettingsPrefix(),
    addEmail: getSettingsPrefix('email_backup/add'),
    deleteEmail: getSettingsPrefix('email_backup/delete'),
  },
  chats: {
    chatList: getChatPrefix('list'),
    chat: (id: ID) => getChatPrefix(String(id)),
    threadList: (id: ID) => getChatPrefix(`${id}/list`),
    thread: (id: ID) => getThreadPrefix(String(id)),
    messageList: (threadId: ID) => getMessagePrefix({ threadId, endpoint: 'list' }),
    message: ({ threadId, messageId }: { threadId: ID; messageId: ID }) =>
      getMessagePrefix({ threadId, endpoint: String(messageId) }),
    messageCreate: (threadId: ID) => getMessagePrefix({ threadId, endpoint: 'create' }),
    messageRead: ({ threadId, messageId }: { threadId: ID; messageId: ID }) =>
      getMessagePrefix({ threadId, endpoint: `${messageId}/read` }),
    templateCreate: getChatPrefix(`template/create`),
    templateList: getChatPrefix(`template/list`),
    template: (id: ID) => getChatPrefix(`template/${id}`),
  },
  bid: {
    create: getBidPrefix('create'),
    managerCreate: getBidPrefix('create/manager'),
    bid: (id: ID) => getBidPrefix(String(id)),
    reply: (id: ID) => getBidPrefix(`${id}/reply`),
    publish: (id: ID) => getBidPrefix(`${id}/publish`),
    approve: (bidId: ID, replyId: ID) => getBidPrefix(`${bidId}/reply/${replyId}`),
    createTrade: (id: ID) => getBidPrefix(`${id}/create_trade`),
    bidReplyList: (id: ID) => getBidPrefix(`${id}/reply/list`),
    buyList: getBidListPrefix('buy'),
    buyBid: (bidId: ID) => getBidListPrefix(`buy/${bidId}`),
    sellList: getBidListPrefix('sell'),
    sellBid: (bidId: ID) => getBidListPrefix(`sell/${bidId}`),
    replyList: getBidListPrefix('reply'),
    replyBid: (bidId: ID) => getBidListPrefix(`reply/${bidId}`),
    myBidsList: getBidListPrefix('my'),
    myBid: (bidId: ID) => getBidListPrefix(`my/${bidId}`),
    managerBuyList: getBidListPrefix('manager/buy'),
    managerSellList: getBidListPrefix('manager/sell'),
    managerMyBidsList: getBidListPrefix('manager/my'),
  },
  ws: {
    connection: getWSPrefix('connection'),
    subscription: getWSPrefix('subscription'),
  },
};
