import {
  AsyncFilesUploadModel,
  DocumentCheckStatus,
  DocumentFileType,
  DocumentServer,
  DocumentWithStatusServer,
} from '@/entities/file';
import { AwbType } from '@/entities/stage/types';
import { mapUserServerToCompanyType } from '@/entities/user';
import { UploadFileModel, ValueModel } from '@/shared/model';
import { InputModel } from '@/shared/model/form/InputModel';
import { Nullable } from '@/shared/types/values';

import { BaseFieldsModelParams } from '../BaseFieldsModel';
import { BaseUploadFilesFieldsModel, BaseUploadFilesFieldsModelParams } from '../BaseUploadFilesFieldsModel';

type Payload<FileAction, NumberAction> =
  | { action: FileAction }
  | {
      action: NumberAction;
      flight_number: string;
    };

type OmitFields = 'approveAction' | 'rejectAction';

type Params<Step, FileAction, NumberAction, Doctype extends DocumentFileType> = Omit<
  BaseUploadFilesFieldsModelParams<Step, FileAction | NumberAction, Doctype>,
  OmitFields
> & {
  awbType: ValueModel<AwbType>;
  flightNumber: InputModel;
  approveFileAction: FileAction;
  approveNumberAction: NumberAction;
};

export class UploadAwbOrFlightNumberModel<
  Step,
  FileAction,
  NumberAction,
  Doctype extends DocumentFileType,
> extends BaseUploadFilesFieldsModel<Step, FileAction | NumberAction, Doctype, Payload<FileAction, NumberAction>> {
  readonly awbType: ValueModel<AwbType>;
  readonly flightNumber: InputModel;
  private readonly approveFileAction: FileAction;
  private readonly approveNumberAction: NumberAction;

  constructor({
    awbType,
    flightNumber,
    approveFileAction,
    approveNumberAction,
    ...params
  }: Params<Step, FileAction, NumberAction, Doctype>) {
    super({
      ...params,
      approveAction: approveFileAction || approveNumberAction,
    });

    this.approveFileAction = approveFileAction;
    this.approveNumberAction = approveNumberAction;
    this.awbType = awbType;
    this.flightNumber = flightNumber;
  }

  get approveDisabled(): boolean {
    if (this.awbType.value === AwbType.file) {
      return !this.files.isUploaded || this.files.isError;
    }

    if (this.awbType.value === AwbType.number) {
      return !this.flightNumber.value.trim();
    }

    return true;
  }

  toApproveJson() {
    const awbType = this.awbType.value;
    const flightNumber = this.flightNumber.value.trim();

    if (awbType === AwbType.number && !flightNumber) {
      return null;
    }

    return this.awbType.value === AwbType.file
      ? { action: this.approveFileAction }
      : {
          action: this.approveNumberAction,
          flight_number: flightNumber,
        };
  }

  reset() {
    this.flightNumber.reset();
    this.files.reset();
  }

  static fromServer<Step, FileAction, NumberAction, DocType extends DocumentFileType>({
    data,
    docType,
    ...params
  }: {
    data: Nullable<
      | DocumentServer
      | DocumentWithStatusServer
      | { flight_number: string; status: DocumentCheckStatus; error_message: Nullable<string> }
    >;
    approveFileAction: FileAction;
    approveNumberAction: NumberAction;
    docType: DocType;
  } & Omit<BaseFieldsModelParams<Step, FileAction | NumberAction>, OmitFields>): UploadAwbOrFlightNumberModel<
    Step,
    FileAction,
    NumberAction,
    DocType
  > {
    const isDataDocument = !!data && 'document' in data;
    const isDataFlightNumber = !!data && 'flight_number' in data && data.flight_number !== null;

    const awbType: AwbType = isDataFlightNumber ? AwbType.number : AwbType.file;

    return new UploadAwbOrFlightNumberModel({
      awbType: new ValueModel(awbType),
      flightNumber: new InputModel({
        initialValue: isDataFlightNumber ? data.flight_number : '',
        description: (t) => t('requiredStep.awbDoc.descriptionNumber', { ns: 'stage' }),
        placeholder: (t) => t('requiredStep.awbDoc.numberPlaceholder', { ns: 'stage' }),
        required: true,
        ...params,
      }),
      filesModel: new AsyncFilesUploadModel<DocType>({
        initialValue: UploadFileModel.fileListFromJson(isDataDocument ? data.document : null),
        description: (t) => t('requiredStep.awbDoc.descriptionFile', { ns: 'stage' }),
        docType,
        required: true,
        tradeWorkflowStore: params.tradeWorkflowStore,
      }),
      status: data && 'status' in data ? data.status : null,
      error: data && 'error_message' in data ? data.error_message : null,
      uploadedBy: isDataDocument && data.document ? mapUserServerToCompanyType(data.document.uploaded_by) : null,

      ...params,
    });
  }
}
