import { ID } from '@/shared/types/meta';

const getProfilePrefix = (endpoint: string) => `/profile/${endpoint}`;
const getBidsPrefix = (endpoint: string, bidId?: ID) => (bidId ? `/bids/${endpoint}/${bidId}` : `/bids/${endpoint}`);

export const routerUrls = {
  main: {
    mask: '/',
    create: () => '/',
  },
  login: {
    mask: '/login',
    create: () => '/login',
  },
  createPassword: {
    mask: '/create_password',
  },
  companies: {
    mask: '/companies',
    create: () => '/companies',
  },
  agents: {
    mask: '/agents',
    create: () => '/agents',
  },
  trades: {
    mask: '/trades',
    create: () => '/trades',
  },
  trade: {
    mask: '/trades/:tradeId',
    create: (id: ID) => `/trades/${id}`,
  },
  documents: {
    mask: '/documents',
    create: () => '/documents',
  },
  profile: {
    mask: '/profile',
    create: () => '/profile',
    children: {
      me: {
        mask: getProfilePrefix('me'),
        create: () => getProfilePrefix('me'),
      },
      company: {
        mask: getProfilePrefix('company'),
        create: () => getProfilePrefix('company'),
      },
      employees: {
        mask: getProfilePrefix('employees'),
        create: () => getProfilePrefix('employees'),
      },
      notifications: {
        mask: getProfilePrefix('notifications'),
        create: () => getProfilePrefix('notifications'),
        children: {
          settings: {
            mask: getProfilePrefix('notifications/settings'),
            create: () => getProfilePrefix('notifications/settings'),
          },
        },
      },
    },
  },
  notifications: {
    mask: getProfilePrefix('notifications'),
    create: () => getProfilePrefix('notifications'),
  },
  chats: {
    mask: '/chats/:chatId?/:threadId?',
    create: (params?: { chatId: ID; threadId?: ID }) => {
      const chats = '/chats';

      if (!params) {
        return chats;
      }

      if (!params.threadId) {
        return `${chats}/${params.chatId}`;
      }

      return `${chats}/${params.chatId}/${params.threadId}`;
    },
  },
  bids: {
    mask: '/bids',
    create: () => '/bids',
    children: {
      buy: {
        mask: getBidsPrefix('buy/:bidId?'),
        create: (bidId?: ID) => getBidsPrefix('buy', bidId),
      },
      sell: {
        mask: getBidsPrefix('sell/:bidId?'),
        create: (bidId?: ID) => getBidsPrefix('sell', bidId),
      },
      my: {
        mask: getBidsPrefix('my/:bidId?'),
        create: (bidId?: ID) => getBidsPrefix('my', bidId),
      },
      reply: {
        mask: getBidsPrefix('reply/:bidId?'),
        create: (bidId?: ID) => getBidsPrefix('reply', bidId),
      },
    },
  },
};
