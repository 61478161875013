import { BaseResponse } from '@kts-front/types';

export enum EmailListHandlingMode {
  add = 'add',
  delete = 'delete',
}

export type ChangeEmailListParams = {
  email: string;
  mode: EmailListHandlingMode;
};

export type ChangeNotificationsSettingsResponse = BaseResponse<{
  showSuccessMessage: boolean;
  emailList?: Array<string>;
}>;
