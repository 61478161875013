import { BaseResponse } from '@kts-front/types';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { action, computed, makeObservable } from 'mobx';

import { isString } from '@/shared/types/typesGuard';
import { Nullable } from '@/shared/types/values';
import {
  ACCEPT_FILE_FORMATS,
  AcceptFileFormats,
  fileFomatValidator,
  fileSizeValidator,
} from '@/shared/utils/validators';

import { ToggleModel } from '../ToggleModel';
import { ValueModel } from '../ValueModel';

import { BaseFieldModel, BaseFieldModelParams } from './BaseFieldModel';

export type UploadFilesModelParams = BaseFieldModelParams<UploadFile[]> & {
  maxCount?: number;
  acceptFileFormats?: Nullable<AcceptFileFormats[]>;
};

export type UploadFilesModelProps = {
  fileList: UploadFile[];
  onChange: (info: UploadChangeParam) => void;
  disabled: boolean;
  required: boolean;
  maxCount: number;
  accept?: string;
  openFileDialogOnClick?: boolean;
};

export class UploadFilesModel extends BaseFieldModel<UploadFile[]> {
  protected readonly _maxCount: number;
  protected readonly _accept: string;

  readonly preview = new ValueModel<Nullable<string>>(null);
  readonly previewModal = new ToggleModel();

  constructor({
    acceptFileFormats = ACCEPT_FILE_FORMATS,
    maxCount = 1,
    validators = [],
    ...params
  }: UploadFilesModelParams) {
    const validatorsArr = [...validators, fileSizeValidator];

    if (acceptFileFormats !== null) {
      validatorsArr.push(fileFomatValidator(acceptFileFormats));
    }

    super({
      ...params,
      validators: validatorsArr,
    });

    this._accept = acceptFileFormats === null ? '' : acceptFileFormats?.join(',') || '';
    this._maxCount = maxCount;

    makeObservable(this, {
      props: computed,
      isUploaded: computed,

      onChange: action.bound,
      onPreview: action.bound,
    });
  }

  get isUploaded(): boolean {
    return this._value.length > 0;
  }

  get props(): UploadFilesModelProps {
    return {
      fileList: this._value,
      onChange: this.onChange,
      required: this.required,
      maxCount: this._maxCount,
      accept: this._accept,
      disabled: this.disabled,
      openFileDialogOnClick: this._value.length < this._maxCount,
    };
  }

  async onPreview(file: UploadFile): Promise<void> {
    const previewValue = file.preview ?? file.url;

    if (previewValue) {
      this.preview.change(previewValue);
      this.previewModal.open();

      return;
    }

    if (!previewValue && file.originFileObj) {
      const previewResponse = await this._getBase64(file.originFileObj);

      if (previewResponse.isError) {
        return;
      }

      this.preview.change(previewResponse.data);
      this.previewModal.open();

      return;
    }
  }

  onChange({ fileList }: UploadChangeParam): void {
    this.change(fileList);
    this.validate();
  }

  private readonly _getBase64 = (file: File): Promise<BaseResponse<string>> =>
    new Promise<BaseResponse<string>>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (isString(reader.result)) {
          resolve({
            isError: false,
            data: reader.result,
          });
        } else {
          reject({
            isError: true,
          });
        }
      };

      reader.onerror = () =>
        reject({
          isError: true,
        });
    });
}
