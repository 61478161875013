import { action, computed, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';
import { InputModel, InputType } from '@/shared/model/form/InputModel';
import { RadioListModel } from '@/shared/model/form/RadioListModel';
import { Nullable, Options } from '@/shared/types/values';
import { emailValidator, emptyValueValidator, phoneValidator, stringLengthValidator } from '@/shared/utils/validators';

import { clientCompanyOptions } from '../config';
import { ClientCompanyType, CompanyType, CreateCompanyPayload, EditCompanyPayload } from '../types';
import { ICompany } from '../types/client';

enum HandlingType {
  create = 'create',
  edit = 'edit',
}

export type CompanyFieldsModelParams = {
  handlingType: HandlingType;
  data: Nullable<ICompany>;
  rootStore: IRootStore;
};

const T_OPTIONS = { ns: 'company' } as const;

export class CompanyFieldsModel {
  readonly type: RadioListModel<ClientCompanyType>;
  readonly name: InputModel;
  readonly email: InputModel;
  readonly phone: InputModel;
  readonly address: InputModel;

  protected readonly _handlingType: HandlingType;

  protected readonly _rootStore: IRootStore;

  protected fields: BaseFieldModel<any>[];

  constructor({ handlingType, data, rootStore }: CompanyFieldsModelParams) {
    this._handlingType = handlingType;
    this._rootStore = rootStore;

    this.type = new RadioListModel<ClientCompanyType>({
      initialValue: data?.type ?? CompanyType.supplier,
      options: clientCompanyOptions,
      validators: [emptyValueValidator()],
      required: true,
    });

    this.name = new InputModel({
      initialValue: data?.name ?? '',
      label: (t) => t('fields.name', T_OPTIONS),
      placeholder: (t) => t('fields.name', T_OPTIONS),
      validators: [emptyValueValidator(), stringLengthValidator(128)],
      required: true,
    });

    this.email = new InputModel({
      initialValue: data?.email ?? '',
      label: (t) => t('fields.email', T_OPTIONS),
      placeholder: (t) => t('fields.email', T_OPTIONS),
      validators: [emptyValueValidator(), emailValidator, stringLengthValidator(128)],
      required: true,
      disabled: handlingType === HandlingType.edit,
    });

    this.phone = new InputModel({
      initialValue: data?.phone ?? '',
      type: InputType.phone,
      label: (t) => t('fields.phone', T_OPTIONS),
      placeholder: (t) => t('fields.phone', T_OPTIONS),
      validators: [phoneValidator],
    });

    this.address = new InputModel({
      initialValue: data?.address ?? '',
      label: (t) => t('fields.address', T_OPTIONS),
      placeholder: (t) => t('fields.address', T_OPTIONS),
      validators: [stringLengthValidator(512)],
    });

    this.fields = [this.type, this.name, this.address, this.email, this.phone];

    makeObservable(this, {
      isError: computed,
      isChange: computed,
      countryOptions: computed,

      validate: action.bound,
      reset: action.bound,
      toJson: action.bound,
    });
  }

  get isError(): boolean {
    return this.fields.some((field) => field.isError);
  }

  get isChange(): boolean {
    return this.fields.some((field) => field.touched);
  }

  get countryOptions(): Options<number> {
    return this._rootStore.dictionariesStore.countries.list.items;
  }

  readonly validate = (): boolean => {
    this.fields.forEach((field) => field.validate());

    return this.isError;
  };

  readonly reset = (): void => {
    this.fields.forEach((field) => field.reset());
  };

  readonly toJson = (): Nullable<CreateCompanyPayload | EditCompanyPayload> => {
    return this._handlingType === HandlingType.edit ? this._toEditJson() : this._toCreateJson();
  };

  protected _toCreateJson(): Nullable<CreateCompanyPayload> {
    const editJson = this._toEditJson();
    const email = this.email.value;

    if (!editJson || !email) {
      return null;
    }

    const { name, address, phone } = editJson;

    return {
      type: this.type.value,
      email,
      name,
      ...(address && { address }),
      ...(phone && { phone }),
    };
  }

  protected _toEditJson(): Nullable<EditCompanyPayload> {
    const name = this.name.value;

    if (!name) {
      return null;
    }

    const address = this.address.value || null;
    const phone = this.phone.value || null;

    return {
      name,
      address,
      phone,
    };
  }

  static fromDefaultParams(rootStore: IRootStore): CompanyFieldsModel {
    return new CompanyFieldsModel({
      handlingType: HandlingType.create,
      data: null,
      rootStore,
    });
  }

  static fromData(params: Omit<CompanyFieldsModelParams, 'handlingType'>): CompanyFieldsModel {
    return new CompanyFieldsModel({ ...params, handlingType: HandlingType.edit });
  }
}
