import { computed, makeObservable } from 'mobx';

import { TranslationString } from '@/shared/types/localization';
import { Nullable } from '@/shared/types/values';

import { BaseFieldModel, BaseFieldModelParams } from './BaseFieldModel';

export type SelectModelParams<Value> = BaseFieldModelParams<Nullable<Value>>;

type SelectModelProps<Value> = {
  value: Nullable<Value>;
  placeholder: TranslationString;
  onChange: (value?: Nullable<Value>) => void;
  onBlur?: VoidFunction;
  disabled: boolean;
};

export class SelectModel<Value = number> extends BaseFieldModel<Nullable<Value>> {
  constructor({
    placeholder = (t) => t('placeholders.select', { ns: 'shared' }),
    ...params
  }: SelectModelParams<Value>) {
    super({ placeholder, ...params });

    makeObservable(this, {
      isSelected: computed,
      props: computed,
    });
  }

  get isSelected(): boolean {
    return this._value !== null;
  }

  get props(): SelectModelProps<Value> {
    return {
      value: this._value,
      placeholder: this.placeholder,
      onChange: (value) => this.change(value ?? null),
      onBlur: this.ignoreOnBlurValidation ? undefined : this.validate,
      disabled: this.disabled,
    };
  }
}
