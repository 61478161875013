import { UserRole } from '@/entities/user';
import { RouteKey, routerUrls } from '@/shared/config/routes';
import { getPathname } from '@/shared/utils';

export const roleRouteKeyMap: Record<UserRole, Array<RouteKey>> = {
  [UserRole.ownerAdmin]: [
    'login',
    'createPassword',
    'companies',
    'trades',
    'documents',
    'chats',
    'bids',
    'notifications',
    'profile',
  ],
  [UserRole.ownerManager]: [
    'login',
    'createPassword',
    'companies',
    'trades',
    'documents',
    'chats',
    'bids',
    'notifications',
    'profile',
  ],
  [UserRole.ownerObserver]: ['login', 'createPassword', 'bids'],
  [UserRole.clientAdmin]: ['login', 'createPassword', 'trades', 'profile', 'notifications', 'chats', 'bids'],
  [UserRole.clientEmployee]: ['login', 'createPassword', 'trades', 'profile', 'notifications', 'chats', 'bids'],
  [UserRole.clientObserver]: ['login', 'createPassword', 'trades', 'profile', 'bids'],
};

export const roleRouteMap: Record<UserRole, string[]> = {
  [UserRole.ownerAdmin]: roleRouteKeyMap[UserRole.ownerAdmin].map((key) => getPathname(routerUrls[key].mask)),
  [UserRole.ownerManager]: roleRouteKeyMap[UserRole.ownerManager].map((key) => getPathname(routerUrls[key].mask)),
  [UserRole.ownerObserver]: roleRouteKeyMap[UserRole.ownerObserver].map((key) => getPathname(routerUrls[key].mask)),
  [UserRole.clientAdmin]: roleRouteKeyMap[UserRole.clientAdmin].map((key) => getPathname(routerUrls[key].mask)),
  [UserRole.clientEmployee]: roleRouteKeyMap[UserRole.clientEmployee].map((key) => getPathname(routerUrls[key].mask)),
  [UserRole.clientObserver]: roleRouteKeyMap[UserRole.clientObserver].map((key) => getPathname(routerUrls[key].mask)),
};

export const defaultRoleRoute: Record<UserRole, string> = {
  [UserRole.ownerAdmin]: routerUrls.companies.create(),
  [UserRole.ownerManager]: routerUrls.companies.create(),
  [UserRole.ownerObserver]: routerUrls.bids.create(),
  [UserRole.clientAdmin]: routerUrls.trades.create(),
  [UserRole.clientEmployee]: routerUrls.trades.create(),
  [UserRole.clientObserver]: routerUrls.trades.create(),
};
