import { action, computed, makeObservable } from 'mobx';

import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';
import { InputModel, InputType } from '@/shared/model/form/InputModel';
import { ApiFieldsErrorData } from '@/shared/types/api';
import { Nullable } from '@/shared/types/values';
import { emailValidator, emptyValueValidator, phoneValidator, stringLengthValidator } from '@/shared/utils/validators';

import { CreateUserPayload, EditUserPayload, User } from '../../types';

export enum UserFieldsModelHandlingType {
  create = 'create',
  edit = 'edit',
}

type PayloadKeys = keyof CreateUserPayload | keyof EditUserPayload;

type Params = {
  handlingType: UserFieldsModelHandlingType;
  data: Nullable<User>;
};

const T_OPTIONS = { ns: 'user' } as const;

export class UserFieldsModel {
  private readonly _handlingType: UserFieldsModelHandlingType;

  readonly email: InputModel;
  readonly name: InputModel;
  readonly surname: InputModel;
  readonly patronymic: InputModel;
  readonly phone: InputModel;

  protected fields: BaseFieldModel<any>[];

  constructor({ handlingType, data }: Params) {
    this._handlingType = handlingType;

    this.email = new InputModel({
      initialValue: data?.email ?? '',
      label: (t) => t('fields.email', T_OPTIONS),
      placeholder: (t) => t('fields.email', T_OPTIONS),
      validators: [emptyValueValidator(), emailValidator, stringLengthValidator(128)],
      required: true,
      disabled: handlingType === UserFieldsModelHandlingType.edit,
    });

    this.name = new InputModel({
      initialValue: data?.name ?? '',
      label: (t) => t('fields.name', T_OPTIONS),
      placeholder: (t) => t('fields.name', T_OPTIONS),
      validators: [emptyValueValidator(), stringLengthValidator(128)],
      required: true,
    });

    this.surname = new InputModel({
      initialValue: data?.surname ?? '',
      label: (t) => t('fields.surname', T_OPTIONS),
      placeholder: (t) => t('fields.surname', T_OPTIONS),
      validators: [emptyValueValidator(), stringLengthValidator(128)],
      required: true,
    });

    this.patronymic = new InputModel({
      initialValue: data?.patronymic ?? '',
      label: (t) => t('fields.patronymic', T_OPTIONS),
      placeholder: (t) => t('fields.patronymic', T_OPTIONS),
      validators: [stringLengthValidator(128)],
    });

    this.phone = new InputModel({
      initialValue: data?.phone ?? '',
      type: InputType.phone,
      label: (t) => t('fields.phone', T_OPTIONS),
      placeholder: (t) => t('fields.phone', T_OPTIONS),
      validators: [phoneValidator],
    });

    this.fields = [this.email, this.phone, this.name, this.surname, this.patronymic];

    makeObservable(this, {
      isError: computed,
      isChange: computed,

      validate: action.bound,
      reset: action.bound,
      toJson: action.bound,
      requestErrorHandling: action.bound,
    });
  }

  get isError(): boolean {
    return this.fields.some((field) => field.isError);
  }

  get isChange(): boolean {
    return this.fields.some((field) => field.touched);
  }

  readonly validate = (): boolean => {
    this.fields.forEach((field) => field.validate());

    return this.isError;
  };

  readonly reset = (): void => {
    this.fields.forEach((field) => field.reset());
  };

  readonly toJson = (): Nullable<CreateUserPayload | EditUserPayload> => {
    return this._handlingType === UserFieldsModelHandlingType.create ? this._toCreateJson() : this._toEditJson();
  };

  readonly requestErrorHandling = ({ field_problems }: ApiFieldsErrorData<PayloadKeys>): void => {
    if (field_problems) {
      (Object.keys(field_problems) as Array<PayloadKeys>).forEach((key) => {
        const errors = field_problems[key];

        if (errors?.length && this[key]) {
          this[key].changeError(errors[0]);
        }
      });
    }
  };

  protected _toCreateJson(): Nullable<CreateUserPayload> {
    const email = this.email.value;
    const editJson = this._toEditJson();

    if (!editJson || !email) {
      return null;
    }

    const { name, surname, patronymic, phone } = editJson;

    return {
      email,
      name,
      surname,
      ...(patronymic ? { patronymic } : {}),
      ...(phone ? { phone } : {}),
    };
  }

  protected _toEditJson(): Nullable<EditUserPayload> {
    const name = this.name.value;
    const surname = this.surname.value;

    if (!name || !surname) {
      return null;
    }

    const patronymic = this.patronymic.value || null;
    const phone = this.phone.value || null;

    return {
      name,
      surname,
      patronymic,
      phone,
    };
  }

  static fromDefaultParams(): UserFieldsModel {
    return new UserFieldsModel({
      handlingType: UserFieldsModelHandlingType.create,
      data: null,
    });
  }

  static fromData(params: Omit<Params, 'handlingType'>): UserFieldsModel {
    return new UserFieldsModel({ ...params, handlingType: UserFieldsModelHandlingType.edit });
  }
}
