import * as React from 'react';

import { ClientCompanyType } from '@/entities/company';
import { CustomTag } from '@/shared/ui';

import { companyTypeTagMap } from './config';

type Props = {
  className?: string;
  type: ClientCompanyType;
};

const CompanyTypeTag = ({ className, type }: Props): React.ReactElement<Props> => {
  const { color, label } = companyTypeTagMap[type];

  return <CustomTag className={className} color={color} label={label} />;
};

export default React.memo(CompanyTypeTag);
