import { CompanyType } from '@/entities/company';
import { TranslationString } from '@/shared/types/localization';
import { Nullable } from '@/shared/types/values';

const T_OPTIONS = { ns: 'stage' } as const;

type TextParams = {
  label: TranslationString;
  description: TranslationString;
};

export const getTextParams = (companyType: Nullable<CompanyType>, etdDate: Nullable<Date>): TextParams => {
  const params: TextParams = {
    label: '',
    description: '',
  };

  if (!companyType) {
    return params;
  }

  switch (companyType) {
    case CompanyType.owner: {
      params.label = (t) => t('optionalFields.expectedDeliveryTime.owner.label', T_OPTIONS);

      break;
    }

    case CompanyType.customer: {
      params.label = (t) => t('optionalFields.expectedDeliveryTime.customer.label', T_OPTIONS);
      params.description = (t) =>
        t(
          etdDate
            ? 'optionalFields.expectedDeliveryTime.customer.approveDescription'
            : 'optionalFields.expectedDeliveryTime.customer.waitDescription',
          T_OPTIONS,
        );

      break;
    }
  }

  return params;
};
