import { DocumentFileType } from '@/entities/file';
import {
  ManagerPaymentAction,
  ManagerPaymentStageDataServer,
  ManagerPaymentStep,
  StageModelJsonParams,
  StageType,
} from '@/entities/stage/types';

import { BaseManagerStageModel, BaseManagerStageModelParams } from '../../BaseManagerStageModel';
import { BaseUploadFilesFieldsModel } from '../../fieldsModel';

import { mapManagerStepToNumber } from './config';

const MAX_COUNT_PAI_BILL = 10;

type Params = BaseManagerStageModelParams<ManagerPaymentStep> & {
  uploadBillFields: BaseUploadFilesFieldsModel<ManagerPaymentStep, ManagerPaymentAction, DocumentFileType.paymentBill>;
  payBillFields: BaseUploadFilesFieldsModel<ManagerPaymentStep, ManagerPaymentAction, DocumentFileType.paidBill>;
};

export class ManagerPaymentStageModel extends BaseManagerStageModel<StageType.payment, ManagerPaymentStep> {
  readonly uploadBillFields: BaseUploadFilesFieldsModel<
    ManagerPaymentStep,
    ManagerPaymentAction,
    DocumentFileType.paymentBill
  >;
  readonly payBillFields: BaseUploadFilesFieldsModel<
    ManagerPaymentStep,
    ManagerPaymentAction,
    DocumentFileType.paidBill
  >;

  constructor({ uploadBillFields, payBillFields, ...params }: Params) {
    super({
      type: StageType.payment,
      ...params,
    });

    this.uploadBillFields = uploadBillFields;
    this.payBillFields = payBillFields;
  }

  get currentStep(): number {
    return mapManagerStepToNumber(this.step);
  }

  get steps(): ManagerPaymentStep[] {
    const steps: ManagerPaymentStep[] = [ManagerPaymentStep.uploadBill, ManagerPaymentStep.payBill];

    if (this.step === ManagerPaymentStep.transfer) {
      steps.push(ManagerPaymentStep.transfer);
    }

    return steps;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<ManagerPaymentStageDataServer>): ManagerPaymentStageModel {
    return new ManagerPaymentStageModel({
      uploadBillFields: BaseUploadFilesFieldsModel.fromJson({
        data: dataServer.steps[ManagerPaymentStep.uploadBill],
        step: ManagerPaymentStep.uploadBill,
        approveAction: ManagerPaymentStep.uploadBill,
        docType: DocumentFileType.paymentBill,
        tradeWorkflowStore,
      }),
      payBillFields: BaseUploadFilesFieldsModel.fromJson({
        maxCount: MAX_COUNT_PAI_BILL,
        data: dataServer.steps[ManagerPaymentStep.payBill],
        step: ManagerPaymentStep.payBill,
        approveAction: ManagerPaymentStep.payBill,
        docType: DocumentFileType.paidBill,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
