import { isString, stringIsNumber } from '../types/typesGuard';

import { fixNumberDecimals } from './fixNumberDecimals';

export const formatNumberWithUnit = (number: number, unit: string, decimals: number = 2) => {
  /**
   * Проверка на случай, если num не является числом
   * (иногда приходит с бэка строка вместо числа)
   */
  if (isString(number)) {
    if (stringIsNumber(number)) {
      number = Number(number);
    } else {
      throw new Error('formatNumberWithUnit: num не является числом');
    }
  }

  const formattedNum = fixNumberDecimals(number, decimals).toLocaleString();

  return `${formattedNum}\u00A0${unit}`;
};
