import { Tag } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks';
import { CustomTypographyText } from '@/shared/ui/CustomTypographyText';
import { TypographyTextWithTooltip } from '@/shared/ui/TypographyTextWithTooltip';

import s from './EmailListInput.module.scss';

type Props = {
  emailList: Array<string>;
  disabled: boolean;
  onRemove: (email: string) => void;
};

const EmailList: React.FC<Props> = ({ emailList = [], disabled = false, onRemove }) => {
  const { t } = useTypedTranslation('notifications');

  return (
    <>
      <CustomTypographyText className="list-label">{t('settings.emailList.label')}</CustomTypographyText>
      {emailList.map((email) => (
        <Tag className={cn(s.tag, disabled && s.tag_disabled)} key={email} closable onClose={() => onRemove(email)}>
          <TypographyTextWithTooltip text={email} />
        </Tag>
      ))}
    </>
  );
};

export default React.memo(EmailList);
