import { ClientCompanyType, CompanyType } from '@/entities/company';
import { ValueModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

/**
 * @field ownerAdmin - Администратор компании владельца (Arvar)
 * @field ownerManager - Менеджер компании владельца (Arvar)
 * @field ownerObserver - Наблюдатель компании владельца (Arvar)
 * @field clientAdmin - Администратор компании клиента
 * @field clientEmployee - Сотрудник компании клиента
 * @field clientObserver - Наблюдатель компании клиента
 */
export enum UserRole {
  ownerAdmin = 'administrator',
  ownerManager = 'manager',
  ownerObserver = 'observer',
  clientAdmin = 'company_administrator',
  clientEmployee = 'company_employee',
  clientObserver = 'company_observer',
}

/**
 * Роли сотрудников компании владельца (Arvar)
 */
export type OwnerUserRole = UserRole.ownerAdmin | UserRole.ownerManager | UserRole.ownerObserver;

/**
 * Роли сотрудников компании клиента
 */
export type ClientUserRole = UserRole.clientAdmin | UserRole.clientEmployee | UserRole.clientObserver;

/**
 * Роли сотрудников компании посредника
 */
export type IntermediaryUserRole = unknown;

export interface IBaseUser {
  id: number;
  email: string;
  name: string;
  surname: string;
  fio: string;
  patronymic: Nullable<string>;
  phone: Nullable<string>;
  // Кол-во новых уведомлений
  newNotificationsCount: ValueModel<number>;
}

export interface IUser<CT extends CompanyType, UR extends UserRole> extends IBaseUser {
  type: CT;
  role: UR;
}

export interface IOwnerUser<Role extends OwnerUserRole = OwnerUserRole> extends IUser<CompanyType.owner, Role> {}

export interface ICustomerUser<Role extends ClientUserRole = ClientUserRole>
  extends IUser<CompanyType.customer, Role> {}

export interface ISupplierUser<Role extends ClientUserRole = ClientUserRole>
  extends IUser<CompanyType.supplier, Role> {}

export type User = ICustomerUser | ISupplierUser | IOwnerUser;

export type CompanyAdminUser = IUser<ClientCompanyType, UserRole.clientAdmin>;
