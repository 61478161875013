import { CompanyType } from '@/entities/company';
import { DocumentServer, DocumentWithStatusServer } from '@/entities/file';
import { Nullable } from '@/shared/types/values';

import { BaseStageDataServer } from './baseStage';

export enum ManagerConfirmationStep {
  deliveryConfirmation = 'delivery_confirmation',
  confirmationVerifying = 'confirmation_verifying',
  confirmationSigning = 'confirmation_signing',
  transfer = 'transfer',
}

export enum ManagerConfirmationVerifyingAction {
  approveConfirmationVerifying = 'approve_confirmation_verifying',
  rejectConfirmationVerifying = 'reject_confirmation_verifying',
}

export type ManagerConfirmationAction =
  | Exclude<ManagerConfirmationStep, ManagerConfirmationStep.confirmationVerifying>
  | ManagerConfirmationVerifyingAction;

export enum SupplierConfirmationStep {
  deliveryConfirmation = 'delivery_confirmation',
  confirmationVerifying = 'confirmation_verifying',
  confirmationSigning = 'confirmation_signing',
  transfer = 'transfer',
}

export type SupplierConfirmationAction = Extract<
  SupplierConfirmationStep,
  SupplierConfirmationStep.deliveryConfirmation
>;

type MapUserTypeToConfirmationStageData = {
  [CompanyType.owner]: {
    [ManagerConfirmationStep.deliveryConfirmation]: Nullable<DocumentWithStatusServer>;
    [ManagerConfirmationStep.confirmationVerifying]: null;
    [ManagerConfirmationStep.confirmationSigning]: Nullable<DocumentServer>;
  };
  [CompanyType.supplier]: {
    [SupplierConfirmationStep.deliveryConfirmation]: null;
    [SupplierConfirmationStep.confirmationVerifying]: null;
    [SupplierConfirmationStep.confirmationSigning]: null;
  };
  [CompanyType.customer]: null;
};

type BaseConfirmationStageDataServer<CT extends CompanyType, Step> = BaseStageDataServer<
  Step,
  MapUserTypeToConfirmationStageData[CT]
>;

export type ManagerConfirmationStageDataServer = BaseConfirmationStageDataServer<
  CompanyType.owner,
  ManagerConfirmationStep
>;
export type SupplierConfirmationStageDataServer = BaseConfirmationStageDataServer<
  CompanyType.supplier,
  SupplierConfirmationStep
>;

export type ConfirmationStageDataServer = ManagerConfirmationStageDataServer | SupplierConfirmationStageDataServer;

export type ConfirmationAction = ManagerConfirmationAction;
