import { DictionaryField } from '@/entities/dictionary';
import { DocumentFileType, UploadArbitraryDocsModel, UploadOptionalFileModel } from '@/entities/file';
import { OptionFieldsAction, StageType } from '@/entities/stage/types';
import { ITradeWorkflowStore } from '@/entities/trade';

import { AttachingTrackingLinkModel } from '../../AttachingTrackingLinkModel';
import { ExpectedDeliveryTimeModel } from '../../ExpectedDeliveryTimeModel';
import { SelectOptionalOptionModel } from '../../SelectOptionalOptionModel';

type Params = {
  specification: UploadOptionalFileModel;
  arbitraryDocs: UploadArbitraryDocsModel;
  transitCountryLogisticCompany: SelectOptionalOptionModel<number>;
  destinationCountryLogisticCompany: SelectOptionalOptionModel<number>;
  flightTrackingLink: AttachingTrackingLinkModel;
  warrantyLoa: UploadOptionalFileModel;
  paymentTaxesCertificate: UploadOptionalFileModel;
  authorizationLetter: UploadOptionalFileModel;
  photo: UploadOptionalFileModel;
  expectedDeliveryTime: ExpectedDeliveryTimeModel;
};

const T_OPTIONS = { ns: 'stage' } as const;

export class OptionalFieldsModel {
  readonly specification: UploadOptionalFileModel;

  readonly arbitraryDocs: UploadArbitraryDocsModel;

  readonly transitCountryLogisticCompany: SelectOptionalOptionModel<number>;

  readonly destinationCountryLogisticCompany: SelectOptionalOptionModel<number>;

  readonly flightTrackingLink: AttachingTrackingLinkModel;

  readonly warrantyLoa: UploadOptionalFileModel;

  readonly paymentTaxesCertificate: UploadOptionalFileModel;

  readonly authorizationLetter: UploadOptionalFileModel;

  readonly photo: UploadOptionalFileModel;

  readonly expectedDeliveryTime: ExpectedDeliveryTimeModel;

  constructor({
    specification,
    arbitraryDocs,
    transitCountryLogisticCompany,
    destinationCountryLogisticCompany,
    flightTrackingLink,
    warrantyLoa,
    paymentTaxesCertificate,
    authorizationLetter,
    photo,
    expectedDeliveryTime,
  }: Params) {
    this.specification = specification;
    this.arbitraryDocs = arbitraryDocs;
    this.transitCountryLogisticCompany = transitCountryLogisticCompany;
    this.destinationCountryLogisticCompany = destinationCountryLogisticCompany;
    this.flightTrackingLink = flightTrackingLink;
    this.warrantyLoa = warrantyLoa;
    this.paymentTaxesCertificate = paymentTaxesCertificate;
    this.authorizationLetter = authorizationLetter;
    this.photo = photo;
    this.expectedDeliveryTime = expectedDeliveryTime;
  }

  static fromStore(tradeWorkflowStore: ITradeWorkflowStore): OptionalFieldsModel {
    const { tradeInfo, rootStore } = tradeWorkflowStore;
    const { isSupplier, isOwnerMainRole } = rootStore.userStore;

    const modelDisabled = tradeInfo.tradeDisabled || tradeInfo.stageDisabled;

    const isShipmentStage = tradeInfo.currentStageType === StageType.shipment;
    const isTransportationStage = tradeInfo.currentStageType === StageType.transportation;
    const isFinalizationStage = tradeInfo.currentStageType === StageType.finalization;

    return new OptionalFieldsModel({
      specification: new UploadOptionalFileModel({
        initialValue: tradeInfo.supplierSpecification ? [tradeInfo.supplierSpecification] : [],
        label: (t) => t('optionalFields.specification.label', T_OPTIONS),
        tooltip: (t) => t('optionalFields.specification.tooltip', T_OPTIONS),
        disabled: modelDisabled,
        docType: DocumentFileType.supplierSpecification,
        tradeWorkflowStore,
      }),

      arbitraryDocs: UploadArbitraryDocsModel.fromStore(tradeWorkflowStore, modelDisabled),

      transitCountryLogisticCompany: new SelectOptionalOptionModel<number>({
        initialValue: tradeInfo.transitLogisticCompany?.value ?? null,
        label: (t) => t('optionalFields.transitCountryLogisticCompany.label', T_OPTIONS),
        placeholder: (t) => t('optionalFields.transitCountryLogisticCompany.placeholder', T_OPTIONS),
        dictionaryFieldName: DictionaryField.logisticCompanies,
        disabled: modelDisabled || (!isShipmentStage && !isTransportationStage),
        approveAction: OptionFieldsAction.setTransitLogisticCompany,
        tradeWorkflowStore,
      }),

      destinationCountryLogisticCompany: new SelectOptionalOptionModel<number>({
        initialValue: tradeInfo.destinationLogisticCompany?.value ?? null,
        label: (t) => t('optionalFields.destinationCountryLogisticCompany.label', T_OPTIONS),
        placeholder: (t) => t('optionalFields.destinationCountryLogisticCompany.placeholder', T_OPTIONS),
        dictionaryFieldName: DictionaryField.logisticCompanies,
        disabled: modelDisabled || (!isShipmentStage && !isTransportationStage),
        approveAction: OptionFieldsAction.setDestinationLogisticCompany,
        tradeWorkflowStore,
      }),

      flightTrackingLink: AttachingTrackingLinkModel.fromStore(tradeWorkflowStore),

      warrantyLoa: new UploadOptionalFileModel({
        initialValue: tradeInfo.warrantyLoa ? [tradeInfo.warrantyLoa] : [],
        label: (t) => t('optionalFields.warrantyLoa.label', T_OPTIONS),
        disabled: modelDisabled || !isShipmentStage,
        docType: DocumentFileType.warrantyLoa,
        tradeWorkflowStore,
      }),

      paymentTaxesCertificate: new UploadOptionalFileModel({
        initialValue: tradeInfo.paymentTaxesCertificate ? [tradeInfo.paymentTaxesCertificate] : [],
        label: (t) => t('optionalFields.paymentTaxesCertificate.label', T_OPTIONS),
        tooltip: (t) => t('optionalFields.paymentTaxesCertificate.tooltip', T_OPTIONS),
        disabled: modelDisabled || (isOwnerMainRole && !isTransportationStage) || (isSupplier && !isFinalizationStage),
        docType: DocumentFileType.indirectTaxesPaymentCertificate,
        tradeWorkflowStore,
      }),

      authorizationLetter: new UploadOptionalFileModel({
        initialValue: tradeInfo.authorizationLetter ? [tradeInfo.authorizationLetter] : [],
        label: (t) => t('optionalFields.authorizationLetter.label', T_OPTIONS),
        disabled: modelDisabled || !isTransportationStage,
        docType: DocumentFileType.authorizationLetter,
        tradeWorkflowStore,
      }),

      photo: new UploadOptionalFileModel({
        initialValue: tradeInfo.finalizationPhoto ? [tradeInfo.finalizationPhoto] : [],
        label: (t) => t('optionalFields.photo.label', T_OPTIONS),
        disabled: modelDisabled,
        docType: DocumentFileType.finalizationPhoto,
        tradeWorkflowStore,
      }),

      expectedDeliveryTime: ExpectedDeliveryTimeModel.fromStore(tradeWorkflowStore, modelDisabled),
    });
  }
}
