import { Flex } from 'antd';
import { ButtonProps } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useWidth } from '@/shared/hooks';
import { TranslationString } from '@/shared/types/localization';

import { CustomButton } from '../form/CustomButton';

type buttonProps = ButtonProps & {
  text?: TranslationString;
};

type Props = {
  cancelButtonProps?: buttonProps;
  okButtonProps?: buttonProps;
  lockButtonProps?: buttonProps;
};

const GroupOfButtonsWithLock: React.FC<Props> = ({ cancelButtonProps, okButtonProps, lockButtonProps }) => {
  const { isMobile } = useWidth();

  return (
    <Flex vertical={isMobile} gap={16}>
      <CustomButton {...cancelButtonProps}>{cancelButtonProps?.text}</CustomButton>
      {lockButtonProps && <CustomButton {...lockButtonProps}>{lockButtonProps.text}</CustomButton>}
      <CustomButton {...okButtonProps}>{okButtonProps?.text}</CustomButton>
    </Flex>
  );
};

export default observer(GroupOfButtonsWithLock);
