import { TagProps } from 'antd';
import * as React from 'react';

import { Nullable } from '@/shared/types/values';
import { TelegramOutlinedIcon } from '@/shared/ui/icons';

import { NotificationService } from '../../types';

export const accountTagProps: Record<NotificationService, Nullable<TagProps>> = {
  [NotificationService.telegram]: {
    icon: <TelegramOutlinedIcon />,
    color: '#2AABEE',
  },
  [NotificationService.email]: null,
  [NotificationService.chat]: null,
};
