import { autorun, computed, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { ICompany } from '@/entities/company';
import {
  DictionaryModel,
  DictionaryServer,
  IDictionariesStore,
  IDictionary,
  IProductDictionary,
  IProductTypeDictionary,
  IWeightDictionary,
  ProductType,
  UnitDictionaryServer,
} from '@/entities/dictionary';
import { StageType } from '@/entities/stage';
import { ICustomerUser, ISupplierUser } from '@/entities/user';
import { LocalStore, ValueModel } from '@/shared/model';
import { isNullable } from '@/shared/types/typesGuard';
import { Amount, Nullable } from '@/shared/types/values';
import { getLigatureWeight } from '@/shared/utils';

import { IBaseTrade, TradeStatus } from '../types';

export type BaseTradeModelParams<Trade extends IBaseTrade> = {
  data: Omit<Trade, 'ligatureWeight'>;
  rootStore: IRootStore;
};

export type TradeDictionaries = {
  product: Nullable<IProductDictionary>;
  productType: IProductTypeDictionary;
  weightUnit: IWeightDictionary;
};

export abstract class BaseTradeModel<Trade extends IBaseTrade> extends LocalStore {
  readonly id: number;
  readonly tradeStatus: TradeStatus;
  readonly stageType: StageType;
  readonly productTypeId: number;
  readonly productId: Nullable<number>;
  readonly weight: number;
  readonly weightUnitId: number;
  readonly customerEmployee: Nullable<ICustomerUser>;
  readonly supplierEmployee: Nullable<ISupplierUser>;
  readonly customerCompany: Nullable<ICompany>;
  readonly supplierCompany: Nullable<ICompany>;
  readonly sellAmount: Nullable<Amount>;
  readonly sellAmountTotal: Nullable<Amount>;
  readonly customerPayment: Nullable<number>;
  readonly fixingDate: Date;
  readonly transitCountryCargoArrivalDate: Nullable<Date>;
  readonly transitCountryCargoShipmentDate: Nullable<Date>;
  readonly destinationCountryCargoArrivalDate: Nullable<Date>;
  readonly cargoDeliveredDate: Nullable<Date>;
  readonly paymentDate: Nullable<Date>;
  readonly etdDate: Nullable<Date>;
  readonly fixingPrice: Nullable<number>;

  protected readonly _rootStore: IRootStore;
  private readonly _tradeDictionaries = new ValueModel<Nullable<TradeDictionaries>>(null);

  constructor({ data, rootStore }: BaseTradeModelParams<Trade>) {
    super();
    this.id = data.id;
    this.tradeStatus = data.tradeStatus;
    this.stageType = data.stageType;
    this.sellAmount = data.sellAmount;
    this.sellAmountTotal = data.sellAmountTotal;
    this.productTypeId = data.productTypeId;
    this.productId = data.productId;
    this.fixingDate = data.fixingDate;
    this.weight = data.weight;
    this.weightUnitId = data.weightUnitId;
    this.customerCompany = data.customerCompany;
    this.supplierCompany = data.supplierCompany;
    this.customerEmployee = data.customerEmployee;
    this.supplierEmployee = data.supplierEmployee;
    this.transitCountryCargoArrivalDate = data.transitCountryCargoArrivalDate;
    this.transitCountryCargoShipmentDate = data.transitCountryCargoShipmentDate;
    this.destinationCountryCargoArrivalDate = data.destinationCountryCargoArrivalDate;
    this.cargoDeliveredDate = data.cargoDeliveredDate;
    this.customerPayment = data.customerPayment;
    this.paymentDate = data.paymentDate;
    this.etdDate = data.etdDate;
    this.fixingPrice = data.fixingPrice;

    this._rootStore = rootStore;

    makeObservable(this, {
      tradeDictionaries: computed,
    });

    this.addReactions([
      autorun(() => {
        const productType = this._dictionaries.productTypes.list.entities.get(this.productTypeId);
        const weightUnit = productType?.unitList && productType.unitList.entities.get(this.weightUnitId);

        if (isNullable(productType) || isNullable(weightUnit)) {
          return;
        }

        const productList = this._dictionaries.products.list.entities.get(ProductType.metal);
        const product = this.productId && productList ? productList.entities.get(this.productId) ?? null : null;

        this._tradeDictionaries.change({
          product,
          weightUnit,
          productType,
        });
      }),
    ]);
  }

  private get _dictionaries(): IDictionariesStore {
    return this._rootStore.dictionariesStore;
  }

  get ligatureWeight(): number {
    return getLigatureWeight(this.weight);
  }

  get tradeDictionaries(): Nullable<TradeDictionaries> {
    return this._tradeDictionaries.value;
  }

  static dateFromJson(json: Nullable<string>): Nullable<Date> {
    return json ? new Date(json) : null;
  }

  static amountFromJson(price: Nullable<number>, unit: Nullable<UnitDictionaryServer>): Nullable<Amount> {
    const amount: Nullable<Amount> =
      price && unit
        ? {
            sum: price,
            currency: {
              code: unit.name,
              symbol: unit.slug,
            },
          }
        : null;

    return amount;
  }

  static dictionaryFromJson(json: Nullable<DictionaryServer>): Nullable<IDictionary> {
    return json ? DictionaryModel.fromJson(json) : null;
  }
}
