import * as React from 'react';

import { IconProps } from '../types';

const Building: React.FC<IconProps> = ({ size = 16, ...props }) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 22.5V6.74997C21 6.33576 20.6642 5.99997 20.25 5.99997H16.5V2.24997C16.5 1.83576 16.1642 1.49997 15.75 1.49997H3.75C3.33579 1.49997 3 1.83576 3 2.24997V22.5H0.75C0.335786 22.5 0 22.8358 0 23.25C0 23.6642 0.335786 24 0.75 24H10.5V19.5H13.5V24H23.25C23.6642 24 24 23.6642 24 23.25C24 22.8358 23.6642 22.5 23.25 22.5H21ZM9 16.5H6V13.5H9V16.5ZM9 12H6V8.99997H9V12ZM9 7.49997H6V4.49997H9V7.49997ZM13.5 16.5H10.5V13.5H13.5V16.5ZM13.5 12H10.5V8.99997H13.5V12ZM13.5 7.49997H10.5V4.49997H13.5V7.49997ZM19.5 16.5H16.5V13.5H19.5V16.5ZM19.5 12H16.5V8.99997H19.5V12Z"
        fill="currentColor"
        fillOpacity="0.88"
      />
    </svg>
  );
};

export default React.memo(Building);
