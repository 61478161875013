import * as React from 'react';

import { CustomTag } from '@/shared/ui';

import { mapEmployeeRoleToConfig } from '../../config';
import { CompanyEmployeeRole } from '../../types';

type EmployeeTagProps = {
  className?: string;
  role: CompanyEmployeeRole;
};

const EmployeeRoleTag = ({ className, role }: EmployeeTagProps): React.ReactElement<EmployeeTagProps> => {
  const { color, label } = mapEmployeeRoleToConfig[role];

  return <CustomTag className={className} color={color} label={label} />;
};

export default React.memo(EmployeeRoleTag);
