import { Flex } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { FormInput } from '@/shared/ui/form/FormInput';
import { FormPhoneInput } from '@/shared/ui/form/FormPhoneInput';

import { UserFieldsModel } from '../../model/UserFieldsModel';

import s from './UserFields.module.scss';

type Props = {
  className?: string;
  fields: UserFieldsModel;
  size?: SizeType;
  disabled?: boolean;
};

const UserFields: React.FC<Props> = ({ fields, className, size = 'large', disabled }) => {
  return (
    <Flex className={cn(className, s.content)} vertical gap={16}>
      <FormInput size={size} model={fields.surname} disabled={disabled} />
      <FormInput size={size} model={fields.name} disabled={disabled} />
      <FormInput size={size} model={fields.patronymic} disabled={disabled} />
      <FormInput size={size} model={fields.email} disabled={disabled} />
      <FormPhoneInput size={size} model={fields.phone} disabled={disabled} />
    </Flex>
  );
};

export default observer(UserFields);
