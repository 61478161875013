import { computed, makeObservable } from 'mobx';

import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { Nullable } from '@/shared/types/values';
import { formatDate } from '@/shared/utils';
import { emptyValueValidator } from '@/shared/utils/validators';

import { BaseFieldsModel, BaseFieldsModelParams } from '../BaseFieldsModel';

export type BaseDateFieldsModelParams<Step, Action> = BaseFieldsModelParams<Step, Action> & {
  date: DatePickerModel;
};

export type BaseDateFilePayload<Action> = {
  action: Action;
  date: string;
};

export class BaseDateFieldsModel<
  Step,
  Action,
  Payload extends BaseDateFilePayload<Action> = BaseDateFilePayload<Action>,
> extends BaseFieldsModel<Step, Action, Payload> {
  readonly date: DatePickerModel;

  constructor({ date, ...params }: BaseDateFieldsModelParams<Step, Action>) {
    super(params);
    this.date = date;

    makeObservable(this, {
      approveDisabled: computed,
    });
  }

  get approveDisabled(): boolean {
    return !this.date.value;
  }

  toApproveJson() {
    if (!this.date.value) return null;

    const date = formatDate(this.date.value);

    return {
      action: this.approveAction,
      date,
    } as Payload;
  }

  static fromJson<Step, Action>({
    data,
    ...params
  }: {
    data?: Nullable<Date>;
  } & BaseFieldsModelParams<Step, Action>): BaseDateFieldsModel<Step, Action> {
    return new BaseDateFieldsModel({
      date: new DatePickerModel({
        initialValue: data || null,
        required: true,
        placeholder: (t) => t('placeholders.date', { ns: 'shared' }),
        validators: [emptyValueValidator()],
        ignoreOnBlurValidation: true,
      }),
      ...params,
    });
  }
}
