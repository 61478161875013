import { ArrowDownOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';

import { NewMessageCounter } from '@/shared/ui';

import { BaseButtonProps } from '../types';

import s from './ToBottomButton.module.scss';

type Props = BaseButtonProps & {
  count?: number;
};

const ToBottomButton: React.FC<Props> = ({ className, count, ...props }) => {
  return (
    <div className={cn(s['button-container'], className)}>
      <Button className={s.button} icon={<ArrowDownOutlined />} size="large" {...props} />
      <AnimatePresence>
        {!!count && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <NewMessageCounter className={s.button__counter} small count={count} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ToBottomButton;
