import { SelectProps } from 'antd/es/select';

import { isStringOrNumber } from '@/shared/types/typesGuard';

export const filterOption: SelectProps['filterOption'] = (input, option) => {
  const checkedText = option?.title ?? option?.label;

  return isStringOrNumber(checkedText) && String(checkedText).toLowerCase().includes(input.toLowerCase());
};

export const filterSort: SelectProps['filterSort'] = (optionA, optionB) => {
  const checkedTextA = optionA.title ?? optionA.label;
  const checkedTextB = optionB.title ?? optionB.label;

  return isStringOrNumber(checkedTextA) && isStringOrNumber(checkedTextB)
    ? String(checkedTextA).toLowerCase().localeCompare(String(checkedTextB).toLowerCase())
    : 0;
};
