export const createPassword = {
  en: {
    title: 'You have been invited to the Tranomica CRM system, welcome!',
    form: {
      email: 'Email address',
      password: 'Create password',
      repeatPassword: 'Repeat password',
      placeholder: 'Password',
      submit: 'Continue',
    },
    errors: {
      mismatch: 'Passwords do not match',
    },
    messages: {
      success: 'Password successfully created',
      error: 'An error occurred while creating a password',
    },
  },
  ru: {
    title: 'Вас пригласили в СRM систему Tranomica, добро пожаловать!',
    form: {
      email: 'Адрес почты',
      password: 'Придумайте пароль',
      repeatPassword: 'Повторите пароль',
      placeholder: 'Пароль',
      submit: 'Продолжить',
    },
    errors: {
      mismatch: 'Пароли не совпадают',
    },
    messages: {
      success: 'Пароль успешно создан',
      error: 'Произошла ошибка при создании пароля',
    },
  },
  zh: {
    title: '您已被邀请使用ARVAR CRM系统，欢迎！',
    form: {
      email: '邮件地址',
      password: '创建密码',
      repeatPassword: '重复密码',
      placeholder: '密码',
      submit: '继续',
    },
    errors: {
      mismatch: '密码不匹配',
    },
    messages: {
      success: '密码修改成功',
      error: '创建密码时出错',
    },
  },
};
