import { computed, makeObservable } from 'mobx';

import { CompanyType } from '@/entities/company';
import {
  AsyncFilesUploadModel,
  DocumentCheckStatus,
  DocumentFileType,
  DocumentServer,
  DocumentsServer,
  DocumentsWithStatusServer,
  DocumentWithStatusServer,
} from '@/entities/file';
import { mapUserServerToCompanyType } from '@/entities/user';
import { UploadFileModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import { BaseFieldsModel, BaseFieldsModelParams } from '../BaseFieldsModel';

export type BaseUploadFilesFieldsModelParams<Step, Action, DocType extends DocumentFileType> = BaseFieldsModelParams<
  Step,
  Action
> & {
  filesModel: AsyncFilesUploadModel<DocType>;
  status: Nullable<DocumentCheckStatus>;
  error: Nullable<string>;
  uploadedBy: Nullable<CompanyType>;
};

type ServerPayload<Action> = {
  action: Action;
};

export class BaseUploadFilesFieldsModel<
  Step,
  Action,
  DocType extends DocumentFileType,
  Payload extends ServerPayload<Action> = ServerPayload<Action>,
> extends BaseFieldsModel<Step, Action, Payload> {
  readonly files: AsyncFilesUploadModel<DocType>;
  readonly status: Nullable<DocumentCheckStatus>;
  readonly error: Nullable<string>;
  readonly uploadedBy: Nullable<CompanyType>;

  constructor({
    filesModel,
    status,
    error,
    uploadedBy,
    ...params
  }: BaseUploadFilesFieldsModelParams<Step, Action, DocType>) {
    super(params);

    this.files = filesModel;
    this.status = status;
    this.error = error;
    this.uploadedBy = uploadedBy;

    makeObservable(this, {
      initializedByFiles: computed,
      approveDisabled: computed,
    });
  }

  get isNeedVerifying(): boolean {
    const { isOwner } = this.tradeWorkflowStore.rootStore.userStore;

    return this.uploadedBy === CompanyType.supplier && isOwner;
  }

  get isWaiting(): boolean {
    return this.status === DocumentCheckStatus.waiting;
  }

  get isApproved(): boolean {
    return this.status === DocumentCheckStatus.approved;
  }

  get isRejected(): boolean {
    return this.status === DocumentCheckStatus.rejected;
  }

  get initializedByFiles(): boolean {
    return this.files.isInitializedByValue;
  }

  get approveDisabled(): boolean {
    return !this.files.isUploaded || this.files.isError;
  }

  static fromJson<Step, Action, DocType extends DocumentFileType>({
    data,
    docType,
    maxCount,
    ...params
  }: {
    data: Nullable<DocumentServer | DocumentWithStatusServer | DocumentsServer | DocumentsWithStatusServer>;
    docType: DocType;
    maxCount?: number;
  } & BaseFieldsModelParams<Step, Action>): BaseUploadFilesFieldsModel<Step, Action, DocType> {
    const docs = data && 'document' in data ? data.document && [data.document] : data?.documents ?? null;
    const doc = (docs && docs.at(0)) ?? null;

    return new BaseUploadFilesFieldsModel({
      filesModel: new AsyncFilesUploadModel<DocType>({
        initialValue: UploadFileModel.fileListFromJson(docs),
        required: true,
        docType,
        maxCount,
        tradeWorkflowStore: params.tradeWorkflowStore,
      }),
      status: data && 'status' in data ? data.status : null,
      error: data && 'error_message' in data ? data.error_message : null,
      uploadedBy: doc && mapUserServerToCompanyType(doc.uploaded_by),
      ...params,
    });
  }
}
