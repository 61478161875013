import { DocumentFileType } from '@/entities/file';
import {
  StageModelJsonParams,
  StageType,
  SupplierPaymentAction,
  SupplierPaymentStageDataServer,
  SupplierPaymentStep,
} from '@/entities/stage/types';

import { BaseManagerStageModel } from '../../BaseManagerStageModel';
import { BaseStageModel, BaseStageModelParams } from '../../BaseStageModel';
import { BaseUploadFilesFieldsModel, WaitingFieldsWithFileModel } from '../../fieldsModel';

import { mapSupplierStepToNumber } from './config';

type Params = BaseStageModelParams<SupplierPaymentStep> & {
  uploadBillFields: BaseUploadFilesFieldsModel<
    SupplierPaymentStep,
    SupplierPaymentAction,
    DocumentFileType.paymentBill
  >;
  payBillFields: WaitingFieldsWithFileModel<SupplierPaymentStep>;
};

export class SupplierPaymentStageModel extends BaseStageModel<StageType.payment, SupplierPaymentStep> {
  readonly uploadBillFields: BaseUploadFilesFieldsModel<
    SupplierPaymentStep,
    SupplierPaymentAction,
    DocumentFileType.paymentBill
  >;
  readonly payBillFields: WaitingFieldsWithFileModel<SupplierPaymentStep>;

  constructor({ uploadBillFields, payBillFields, ...params }: Params) {
    super({
      type: StageType.payment,
      ...params,
    });

    this.uploadBillFields = uploadBillFields;
    this.payBillFields = payBillFields;
  }

  get isPaidBill(): boolean {
    return this.payBillFields.file.isUploaded;
  }

  get currentStep(): number {
    return mapSupplierStepToNumber(this.step);
  }

  get steps(): SupplierPaymentStep[] {
    return [SupplierPaymentStep.uploadBill, SupplierPaymentStep.payBill];
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<SupplierPaymentStageDataServer>): SupplierPaymentStageModel {
    return new SupplierPaymentStageModel({
      uploadBillFields: BaseUploadFilesFieldsModel.fromJson({
        data: dataServer.steps[SupplierPaymentStep.uploadBill],
        step: SupplierPaymentStep.uploadBill,
        approveAction: SupplierPaymentStep.uploadBill,
        docType: DocumentFileType.paymentBill,
        tradeWorkflowStore,
      }),
      payBillFields: WaitingFieldsWithFileModel.fromJson({
        data: dataServer.steps[SupplierPaymentStep.payBill],
        step: SupplierPaymentStep.payBill,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
