import { DatePicker, DatePickerProps } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { renderTranslationString } from '@/shared/utils';

import { CustomFormItem } from '../CustomFormItem';

type FormDatePickerProps = Pick<
  DatePickerProps,
  'className' | 'disabled' | 'picker' | 'showTime' | 'disabledDate' | 'suffixIcon'
> & {
  model: DatePickerModel;
  pickerRootClassName?: string;
};

const FormDatePicker: React.FC<FormDatePickerProps> = ({
  className,
  pickerRootClassName,
  model,
  disabled,
  disabledDate,
  ...props
}) => {
  const { t } = useTypedTranslation();
  const placeholder = renderTranslationString(model.placeholder, t);

  return (
    <CustomFormItem className={className} ref={model.ref} model={model}>
      <DatePicker
        rootClassName={pickerRootClassName}
        style={{ width: '100%' }}
        {...model.props}
        {...props}
        placeholder={placeholder}
        disabledDate={disabledDate ?? model.props.disabledDate}
        disabled={model.props.disabled || disabled}
      />
    </CustomFormItem>
  );
};

export default observer(FormDatePicker);
