import { action, computed, makeObservable, observable } from 'mobx';

import { Validator, ValidatorResult } from '@/shared/types/validator';

export class ValueModel<T = string> {
  readonly initialValue: T;

  protected _value: T;
  protected _touched = false;
  protected _validators: Validator<T>[];
  private _error: ValidatorResult = null;

  constructor(value: T, validators: Validator<T>[] = []) {
    this.initialValue = value;

    this._value = value;
    this._validators = validators;

    makeObservable<ValueModel<T>, '_value' | '_touched' | '_error' | 'changeError'>(this, {
      _value: observable,
      _touched: observable,
      _error: observable,

      value: computed,
      touched: computed,
      error: computed,
      isError: computed,

      change: action.bound,
      resetTouched: action.bound,
      changeError: action.bound,
      validate: action.bound,
    });
  }

  get isError(): boolean {
    return this._error !== null;
  }

  get value(): T {
    return this._value;
  }

  get touched(): boolean {
    return this._touched;
  }

  get error(): ValidatorResult {
    return this._error;
  }

  validate(): boolean {
    let error: ValidatorResult = null;

    for (let i = 0; i < this._validators.length; i++) {
      const validator = this._validators[i];

      error = validator(this._value);

      if (error) {
        break;
      }
    }

    this.changeError(error);

    return this.isError;
  }

  change(value: T): void {
    if (value === this._value) {
      return;
    }

    this._value = value;
    this.resetError();
    this._touched = true;
  }

  changeError(result: ValidatorResult): void {
    this._error = result;
  }

  resetValue(): void {
    this.change(this.initialValue);
  }

  resetError(): void {
    this.changeError(null);
  }

  resetTouched(): void {
    this._touched = false;
  }

  reset(): void {
    this.resetValue();
    this.resetError();
    this.resetTouched();
  }
}
