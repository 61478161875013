import { IDictionariesStore } from '@/entities/dictionary';
import { SelectModel } from '@/shared/model/form/SelectModel';
import { Nullable } from '@/shared/types/values';
import { emptyValueValidator } from '@/shared/utils/validators';

import { BaseFieldsModel, BaseFieldsModelParams } from '../../../fieldsModel';

type Params<Step, Action> = BaseFieldsModelParams<Step, Action> & {
  transitCountry: SelectModel<number>;
  dictionaries: IDictionariesStore;
};

type Payload<Action> = {
  action: Action;
  id: number;
};

export class TransitCountryFieldsModel<Step, Action> extends BaseFieldsModel<Step, Action, Payload<Action>> {
  readonly transitCountry: SelectModel<number>;
  readonly dictionaries: IDictionariesStore;

  constructor({ transitCountry, dictionaries, ...params }: Params<Step, Action>) {
    super(params);

    this.transitCountry = transitCountry;
    this.dictionaries = dictionaries;
  }

  toApproveJson() {
    if (!this.transitCountry.value) {
      return null;
    }

    return {
      action: this.approveAction,
      id: this.transitCountry.value,
    };
  }

  static fromJson<Step, Action>({
    data,
    ...params
  }: {
    data?: Nullable<number>;
  } & BaseFieldsModelParams<Step, Action>): TransitCountryFieldsModel<Step, Action> {
    return new TransitCountryFieldsModel<Step, Action>({
      transitCountry: new SelectModel<number>({
        initialValue: data ?? null,
        placeholder: (t) => t('manager.transitCountry.placeholder', { ns: 'shipmentStage' }),
        required: true,
        validators: [emptyValueValidator()],
      }),
      dictionaries: params.tradeWorkflowStore.dictionaries,
      ...params,
    });
  }
}
