import { CompanyType } from '@/entities/company';

import { mapCompanyUserRoleServerToUserRole } from '../../config';
import { ClientUserRole, ICustomerUser } from '../../types';
import { CustomerServer } from '../../types/server';
import { BaseUserModel, BaseUserModelParams } from '../BaseUserModel';

type Params = BaseUserModelParams & { role: ClientUserRole };

export class CustomerModel extends BaseUserModel implements ICustomerUser<ClientUserRole> {
  readonly type = CompanyType.customer;
  readonly role: ClientUserRole;

  constructor(data: Params) {
    super(data);
    this.role = data.role;
  }

  static fromJson(data: CustomerServer): CustomerModel {
    return new CustomerModel({
      ...BaseUserModel.baseUserParamsFromJson(data),
      role: mapCompanyUserRoleServerToUserRole[data.role],
    });
  }
}
