import { DocumentFileType } from '@/entities/file';
import { SupplierFinalizationStep } from '@/entities/stage/types';

export type TradeCloseSupplierDocs = DocumentFileType.supplierAcceptanceCertificate;

export const stepsOrder: SupplierFinalizationStep[] = [
  SupplierFinalizationStep.cargoArrival,
  SupplierFinalizationStep.supplierAcceptanceCertificate,
  SupplierFinalizationStep.supplierAcceptanceCertificateVerifying,
  SupplierFinalizationStep.supplierSignedAcceptanceCertificate,
  SupplierFinalizationStep.finish,
];
