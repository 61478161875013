import { DocumentFileType } from '../types';

export const file = {
  en: {
    docTypeLabel: {
      [DocumentFileType.supplierSpecification]: 'Supplier Specification',
      [DocumentFileType.customerSpecification]: 'Customer Specification',
      [DocumentFileType.deliveryConfirmation]: 'Delivery confirmation',
      [DocumentFileType.confirmationSigning]: 'Signed confirmation',
      [DocumentFileType.paymentBill]: 'An invoice for payment',
      [DocumentFileType.paidBill]: 'Paid invoice',
      [DocumentFileType.exportInvoice]: 'Invoice for export 4%',
      [DocumentFileType.customerCommercialInvoice]: "Customer's commercial invoice",
      [DocumentFileType.awbForDirectCountry]: 'AWB for destination country',
      [DocumentFileType.awbForTransitCountry]: 'AWB for transit country',
      [DocumentFileType.awbForDestinationCountry]: 'AWB for destination country',
      [DocumentFileType.customerAcceptanceCertificate]: 'Customer transfer and acceptance report',
      [DocumentFileType.customerAdditionalAgreement]: "Customer's Supplementary Agreement",
      [DocumentFileType.supplierAcceptanceCertificate]: 'Supplier transfer and acceptance report signed by one party',
      [DocumentFileType.signedSupplierAcceptanceCertificate]: "Signed supplier's transfer and acceptance report",
      [DocumentFileType.signedCustomerAcceptanceCertificate]: "Signed customer's transfer and acceptance report",
      [DocumentFileType.signedCustomerAdditionalAgreement]: 'Signed additional customer agreement',
      [DocumentFileType.arbitraryDocuments]: 'Any document',
      [DocumentFileType.warrantyLoa]: 'Power of Attorney LOA',
      [DocumentFileType.indirectTaxesPaymentCertificate]: 'Tax payment certificate',
      [DocumentFileType.authorizationLetter]: 'Authorization letter',
      [DocumentFileType.finalizationPhoto]: 'Photo',
    },
    messages: {
      uploadDocSuccess_one: 'Document uploaded successfully',
      uploadDocSuccess_other: 'Documents uploaded successfully',
      uploadDocError_one: 'Failed to upload document',
      uploadDocError_other: 'Failed to upload documents',

      uploadFileSuccess_one: 'File uploaded successfully',
      uploadFileSuccess_other: 'Files uploaded successfully',
      uploadFileError_one: 'Failed to upload file',
      uploadFileError_other: 'Failed to upload files',

      updateDocSuccess: 'Document updated successfully',
      updateDocError: 'Failed to update document',

      deleteDocSuccess: 'Document deleted successfully',
      deleteDocError: 'Failed to delete document',

      nonUniqueDocNameError: 'Document names must be unique',
      rejectedDocError: 'Re-upload the document',
    },
    documentForm: {
      docFile: {
        label: 'Document',
      },
    },
    arbitraryDocForm: {
      docCustomType: {
        label: 'Document name',
        placeholder: 'Name the document',
      },
      docComment: {
        label: 'Comment',
        placeholder: 'Enter comment',
      },
      docVisibility: {
        label: 'Document visibility',
      },
    },
    documentVerifyingForm: {
      label: 'Explain what needs to be fixed',
      placeholder: 'Enter what needs to be fixed',
      modal: {
        title: 'Confirm that the document is incorrect',
        approve: 'All correct',
        reject: 'Incorrect',
        okText: 'Approve',
      },
    },
    documentDeleteModal: {
      title: 'Confirm deletion of the file',
    },
  },
  ru: {
    docTypeLabel: {
      [DocumentFileType.supplierSpecification]: 'Спецификация поставщика',
      [DocumentFileType.customerSpecification]: 'Спецификация покупателя',
      [DocumentFileType.deliveryConfirmation]: 'Подтверждение доставки',
      [DocumentFileType.confirmationSigning]: 'Подписанное подтверждения',
      [DocumentFileType.paymentBill]: 'Счет на оплату',
      [DocumentFileType.paidBill]: 'Оплаченный счет',
      [DocumentFileType.exportInvoice]: 'Инвойс для экспорта 4%',
      [DocumentFileType.customerCommercialInvoice]: 'Коммерческий инвойс покупателя',
      [DocumentFileType.awbForDirectCountry]: 'AWB для страны назначения',
      [DocumentFileType.awbForTransitCountry]: 'AWB для страны транзита',
      [DocumentFileType.awbForDestinationCountry]: 'AWB для страны назначения',
      [DocumentFileType.customerAcceptanceCertificate]: 'Акт приема-передачи покупателя',
      [DocumentFileType.customerAdditionalAgreement]: 'Дополнительное соглашение покупателя',
      [DocumentFileType.supplierAcceptanceCertificate]: 'Акт приема-передачи поставщика, подписанный с одной стороны',
      [DocumentFileType.signedSupplierAcceptanceCertificate]: 'Подписанный акт приема-передачи поставщика',
      [DocumentFileType.signedCustomerAcceptanceCertificate]: 'Подписанный акт приема-передачи покупателя',
      [DocumentFileType.signedCustomerAdditionalAgreement]: 'Подписанное дополнительное соглашение покупателя',
      [DocumentFileType.arbitraryDocuments]: 'Произвольный документ',
      [DocumentFileType.warrantyLoa]: 'Доверенность LOA',
      [DocumentFileType.indirectTaxesPaymentCertificate]: 'Справка об уплате налогов',
      [DocumentFileType.authorizationLetter]: 'Авторизационное письмо',
      [DocumentFileType.finalizationPhoto]: 'Фото',
    },
    messages: {
      uploadDocSuccess_one: 'Документ успешно загружен',
      uploadDocSuccess_few: 'Документы успешно загружены',
      uploadDocSuccess_many: 'Документы успешно загружены',
      uploadDocError_one: 'Не удалось загрузить документ',
      uploadDocError_few: 'Не удалось загрузить документы',
      uploadDocError_many: 'Не удалось загрузить документы',

      uploadFileSuccess_one: 'Файл успешно загружен',
      uploadFileSuccess_few: 'Файлы успешно загружены',
      uploadFileSuccess_many: 'Файлы успешно загружены',
      uploadFileError_one: 'Не удалось загрузить файл',
      uploadFileError_few: 'Не удалось загрузить файлы',
      uploadFileError_many: 'Не удалось загрузить файлы',

      updateDocSuccess: 'Документ успешно обновлен',
      updateDocError: 'Не удалось обновить документ',

      deleteDocSuccess: 'Документ успешно удален',
      deleteDocError: 'Не удалось удалить документ',

      nonUniqueDocNameError: 'Названия документов должны быть уникальными',
      rejectedDocError: 'Загрузите повторно документ',
    },
    documentForm: {
      docFile: {
        label: 'Документ',
      },
    },
    arbitraryDocForm: {
      docCustomType: {
        label: 'Название документа',
        placeholder: 'Назовите документ',
      },
      docComment: {
        label: 'Комментарий',
        placeholder: 'Введите комментарий',
      },
      docVisibility: {
        label: 'Видимость документа',
      },
    },
    documentVerifyingForm: {
      label: 'Поясните, что нужно исправить',
      placeholder: 'Введите, что нужно исправить',
      modal: {
        title: 'Подтвердите, что документ не корректен',
        approve: 'Все правильно',
        reject: 'Неправильно',
        okText: 'Подтвердить',
      },
    },
    documentDeleteModal: {
      title: 'Подтвердите удаление файла',
    },
  },
  zh: {
    docTypeLabel: {
      [DocumentFileType.supplierSpecification]: '供应商规格',
      [DocumentFileType.customerSpecification]: '买家规格',
      [DocumentFileType.deliveryConfirmation]: '交货确认',
      [DocumentFileType.confirmationSigning]: '签署的确认书',
      [DocumentFileType.paymentBill]: '付款发票',
      [DocumentFileType.paidBill]: '已付发票',
      [DocumentFileType.exportInvoice]: '出口发票4%',
      [DocumentFileType.customerCommercialInvoice]: '买方的商业发票',
      [DocumentFileType.awbForDirectCountry]: '目的国AWB',
      [DocumentFileType.awbForTransitCountry]: '过境国AWB',
      [DocumentFileType.awbForDestinationCountry]: '目的国AWB',
      [DocumentFileType.customerAcceptanceCertificate]: '买家验收证明',
      [DocumentFileType.customerAdditionalAgreement]: '买方附加协议',
      // [DocumentFileType.supplierAcceptanceCertificate]: 'Акт приема-передачи поставщика, подписанный с одной стороны',
      // [DocumentFileType.signedSupplierAcceptanceCertificate]: 'Подписанный акт приема-передачи поставщика',
      [DocumentFileType.signedCustomerAcceptanceCertificate]: '签署的买家验收证明',
      [DocumentFileType.signedCustomerAdditionalAgreement]: '签署的买方附加协议',
      [DocumentFileType.arbitraryDocuments]: '随机文件',
      [DocumentFileType.warrantyLoa]: 'LOA委托书',
      [DocumentFileType.indirectTaxesPaymentCertificate]: '纳税证明书',
      [DocumentFileType.authorizationLetter]: '授权书',
      [DocumentFileType.finalizationPhoto]: '照片',
    },
    messages: {
      uploadDocSuccess_other: '文档上传成功',
      uploadDocError_other: '文档上传失败',

      uploadFileSuccess_other: '文件上传成功',
      uploadFileError_other: '文件上传失败',

      updateDocSuccess: '文档更新成功',
      updateDocError: '文档更新失败',

      nonUniqueDocNameError: '文档标题应独一无二',
      rejectedDocError: '重新上传文档',
    },
    documentForm: {
      docFile: {
        label: '文档',
      },
    },
    arbitraryDocForm: {
      docCustomType: {
        label: '文档名称',
        placeholder: '为文件命名',
      },
      docComment: {
        label: '备注',
        placeholder: '输入备注',
      },
      docVisibility: {
        label: '文档可见性',
      },
    },
    documentVerifyingForm: {
      label: '请说明需要更正的内容',
      placeholder: '请输入需要更正的内容',
      modal: {
        title: '请确认该文件是否正确',
        approve: '一切都正确',
        reject: '不正确',
        okText: '确认',
      },
    },
    documentDeleteModal: {
      title: '确认删除文件',
    },
  },
};
