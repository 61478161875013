import { TranslationString } from '@/shared/types/localization';
import { Nullable } from '@/shared/types/values';

import { CompanyType } from '../company';

import { CompanyUserRoleServer, OwnerUserRoleServer, UserRole, UserServer } from './types';

export const mapUserRoleToLabel = (role: UserRole): TranslationString => {
  return (t) => t(`userRoles.${role}`, { ns: 'user' });
};

export const mapOwnerUserRoleServerToUserRole = {
  [OwnerUserRoleServer.ownerAdmin]: UserRole.ownerAdmin,
  [OwnerUserRoleServer.ownerManager]: UserRole.ownerManager,
  [OwnerUserRoleServer.ownerObserver]: UserRole.ownerObserver,
} as const;

export const mapCompanyUserRoleServerToUserRole = {
  [CompanyUserRoleServer.clientAdmin]: UserRole.clientAdmin,
  [CompanyUserRoleServer.clientEmployee]: UserRole.clientEmployee,
  [CompanyUserRoleServer.clientObserver]: UserRole.clientObserver,
} as const;

export const mapUserServerToUserRole = <Server extends Pick<UserServer, 'company' | 'role'>>(server: Server) => {
  return server.company
    ? mapCompanyUserRoleServerToUserRole[server.role as CompanyUserRoleServer]
    : mapOwnerUserRoleServerToUserRole[server.role as OwnerUserRoleServer];
};

export const mapUserServerToCompanyType = <Server extends Pick<UserServer, 'company'>>(server: Nullable<Server>) => {
  return server?.company ? server.company.type : CompanyType.owner;
};
