import { LoadingOutlined } from '@ant-design/icons';
import { BaseResponse } from '@kts-front/types';
import cn from 'classnames';
import * as React from 'react';

import { useKeyDown } from '@/shared/hooks';
import { ValidatorResult } from '@/shared/types/validator';
import { CustomInput, CustomInputProps } from '@/shared/ui/form/CustomInput';
import { emailValidator, uniquenessValidator } from '@/shared/utils/validators';

import { ChangeEmailListParams, EmailListHandlingMode } from '../../types';

import EmailList from './EmailList';

import s from './EmailListInput.module.scss';

type EmailListInputProps = Omit<CustomInputProps, 'value' | 'onChange' | 'onError' | 'prefix'> & {
  emailList: Array<string>;
  error: boolean;
  loading: boolean;
  onChange: (params: ChangeEmailListParams) => Promise<BaseResponse>;
  onError: (error: ValidatorResult) => void;
};

const EmailListInput: React.FC<EmailListInputProps> = ({
  className,
  emailList,
  error,
  loading,
  disabled,
  onChange,
  onError,
  ...props
}) => {
  const [email, setEmail] = React.useState('');

  const handleChangeInput = React.useCallback<NonNullable<React.ChangeEventHandler<HTMLInputElement>>>(
    ({ target }) => {
      if (error) {
        onError(null);
      }

      setEmail(target.value);
    },
    [error, onError],
  );

  const handleRemove = React.useCallback(
    (email: string) => {
      onChange({
        email,
        mode: EmailListHandlingMode.delete,
      });
    },
    [onChange],
  );

  const handleChange = React.useCallback(async () => {
    if (!email) {
      return;
    }

    const validationError = emailValidator(email) ?? uniquenessValidator(email, emailList);

    if (validationError) {
      onError?.(validationError);
    } else {
      const { isError } = await onChange({
        email,
        mode: EmailListHandlingMode.add,
      });

      if (!isError) {
        setEmail('');
      }
    }
  }, [email, onChange]);

  useKeyDown({ func: handleChange });

  return (
    <CustomInput
      className={cn(s.email, className)}
      prefix={<EmailList emailList={emailList} disabled={disabled || loading} onRemove={handleRemove} />}
      value={email}
      onChange={handleChangeInput}
      disabled={disabled || loading}
      suffix={loading ? <LoadingOutlined style={{ fontSize: 12 }} /> : null}
      {...props}
    />
  );
};

export default EmailListInput;
