import { CompanyType } from '@/entities/company';

import { mapOwnerUserRoleServerToUserRole } from '../../config';
import { IOwnerUser, OwnerUserRole } from '../../types/client';
import { OwnerServer } from '../../types/server';
import { BaseUserModel, BaseUserModelParams } from '../BaseUserModel';

type Params = BaseUserModelParams & { role: OwnerUserRole };

export class OwnerModel extends BaseUserModel implements IOwnerUser<OwnerUserRole> {
  readonly type = CompanyType.owner;
  readonly role: OwnerUserRole;

  constructor(data: Params) {
    super(data);
    this.role = data.role;
  }

  static fromJson(data: OwnerServer): OwnerModel {
    return new OwnerModel({
      ...BaseUserModel.baseUserParamsFromJson(data),
      role: mapOwnerUserRoleServerToUserRole[data.role],
    });
  }
}
