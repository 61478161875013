import { BaseResponse } from '@kts-front/types';
import { action, computed, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { EmployeeOptionListResponse } from '@/entities/employee';
import { apiStore, apiUrls } from '@/shared/api';
import { ListModel, LoadingStageModel } from '@/shared/model';
import { SelectModel, SelectModelParams } from '@/shared/model/form/SelectModel';
import { Options } from '@/shared/types/values';
import { getFullName } from '@/shared/utils/getFullName';
import { emptyValueValidator } from '@/shared/utils/validators';

import { getLabelNode } from './config';

type EmployeeOption = Options<number>[number];

type FieldParams = SelectModelParams<number>;

type Params = {
  params: FieldParams;
  rootStore: IRootStore;
};

const REQUEST_PARAMS = {
  limit: 10_000,
  offset: 0,
};

export class CompanyEmployeeFieldModel extends SelectModel<number> {
  private readonly _employeeList = new ListModel<EmployeeOption, number>();

  readonly loadingStage = new LoadingStageModel();

  private readonly _employeeListRequest = apiStore.createRequest<EmployeeOptionListResponse>({
    params: REQUEST_PARAMS,
  });

  private readonly _rootStore: IRootStore;

  constructor({ params, rootStore }: Params) {
    super({
      ...params,
      validators: [emptyValueValidator(), ...(params.validators ?? [])],
      placeholder: params.placeholder ?? ((t) => t('placeholders.select', { ns: 'shared' })),
      required: params.required ?? true,
      ignoreOnBlurValidation: params.ignoreOnBlurValidation ?? true,
    });

    this._rootStore = rootStore;

    makeObservable(this, {
      options: computed,

      loadList: action.bound,
      reset: action.bound,
    });
  }

  get options(): Options<number> {
    return this._employeeList.items;
  }

  reset(): void {
    super.reset();
    this._employeeList.reset();
    this.loadingStage.reset();
  }

  async loadList(companyId: number): Promise<BaseResponse> {
    if (this.loadingStage.isLoading) {
      this._employeeListRequest.cancel();
    }

    this.loadingStage.loading();

    const response = await this._employeeListRequest.call({
      url: apiUrls.company.employeeList(companyId),
    });

    if (response.isError) {
      this.loadingStage.error();

      return { isError: true };
    }

    this._employeeList.fillByRawData(
      response.data.results,
      (raw) => ({
        key: raw.id,
        entity: {
          value: raw.id,
          title: getFullName(raw),
          label: getFullName(raw),
          labelNode: getLabelNode(raw, this._rootStore.localizationStore.t),
        },
      }),
      true,
    );

    this.loadingStage.success();

    return { isError: false };
  }
}
