import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { CustomAlert, StepDescription } from '@/shared/ui';
import { FormUpload } from '@/shared/ui/form/FormUpload';
import { ApproveButton } from '@/shared/ui/form/buttons';

import { BaseUploadFilesFieldsModel } from '../../model';
import { StageModel, StepComponentProps } from '../../types';

type BaseUploadFileStepProps<StageModel> = StepComponentProps<StageModel>;

const BaseUploadFileStep = <Model extends StageModel>({
  model,
  fieldKey,
  description,
  approveButtonText,
}: BaseUploadFileStepProps<Model>) => {
  const fields = fieldKey && model[fieldKey];

  if (!(fields instanceof BaseUploadFilesFieldsModel)) {
    return null;
  }

  return (
    <StepDescription description={description}>
      {fields.isRejected && (
        <CustomAlert
          type="error"
          message={(t) => t('messages.rejectedDocError', { ns: 'file' })}
          description={fields.error}
        />
      )}
      <FormUpload model={fields.files} disabled={fields.isDisabled || fields.isLoading} />
      <ApproveButton
        loading={fields.isLoading}
        disabled={fields.isDisabled || fields.approveDisabled}
        onClick={fields.approveStep}
        text={approveButtonText}
      />
    </StepDescription>
  );
};

export default observer(BaseUploadFileStep);
