import * as React from 'react';

import { EmployeeOptionServer } from '@/entities/employee';
import { CompanyUserRoleServer, mapCompanyUserRoleServerToUserRole, mapUserRoleToLabel } from '@/entities/user';
import { TFunctionType } from '@/shared/types/localization';
import { TypographyTextWithTooltip } from '@/shared/ui';
import { renderTranslationString } from '@/shared/utils';
import { getFullName } from '@/shared/utils/getFullName';

export const getLabelNode = (raw: EmployeeOptionServer, t: TFunctionType) => {
  const isAdmin = raw.role === CompanyUserRoleServer.clientAdmin;
  const role = mapCompanyUserRoleServerToUserRole[raw.role];
  const roleLabel = renderTranslationString(mapUserRoleToLabel(role), t);
  const labelNode = (
    <>
      {getFullName(raw)}
      {isAdmin && `\u00A0(${roleLabel.toLowerCase()})`}
    </>
  );

  return <TypographyTextWithTooltip style={{ color: 'inherit' }} text={labelNode} />;
};
