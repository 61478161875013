import Icon from '@ant-design/icons';
import { Flex } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { mapTradeInfoToLabel, TradeInfo, TradeInfoKeys } from '@/entities/trade';
import { Nullable } from '@/shared/types/values';
import { CustomButton } from '@/shared/ui/form/CustomButton';
import { DeliveryIcon } from '@/shared/ui/icons';

import TradeInfoRow from './TradeInfoRow';

import s from './TradeInfoList.module.scss';

type TradeInfoListProps = React.PropsWithChildren<{
  className?: string;
  infoKeyList: Array<TradeInfoKeys>;
  tradeInfo: TradeInfo;
  trackingLink?: Nullable<string>;
}>;

const TradeInfoList: React.FC<TradeInfoListProps> = observer(
  ({ className, infoKeyList, tradeInfo, trackingLink, children }) => {
    const { userStore } = useRootStore();

    const renderTrackingLink = React.useMemo(
      () =>
        trackingLink && (
          <CustomButton
            className={s.tracking_link}
            target="_blank"
            href={trackingLink}
            icon={<Icon component={DeliveryIcon} />}
          >
            {(t) => t('tabs.info.fields.trackingLink', { ns: 'trade' })}
          </CustomButton>
        ),
      [trackingLink],
    );

    return (
      <Flex className={className} vertical gap={10}>
        {infoKeyList.map((key) => {
          const value = tradeInfo[key];
          const label =
            key === 'product' && tradeInfo.productType
              ? tradeInfo.productType
              : mapTradeInfoToLabel(userStore.isOwnerMainRole)[key];

          return value && <TradeInfoRow key={key} label={label} value={value} />;
        })}
        <Flex className={s.extra_info} vertical gap={8}>
          {renderTrackingLink}
          {children}
        </Flex>
      </Flex>
    );
  },
);

export default React.memo(TradeInfoList);
