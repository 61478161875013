import { CompanyType } from '@/entities/company';
import { UnitDictionaryServer } from '@/entities/dictionary';
import { DocumentServer, DocumentWithStatusServer } from '@/entities/file';
import { Nullable } from '@/shared/types/values';

import { BaseStageDataServer, TradeCloseCustomerDocsServer } from './baseStage';

export enum ManagerFinalizationStep {
  cargoDelivered = 'cargo_delivery_confirmation',
  tradeCloseCustomerDocs = 'upload_documents_for_trade_close_customer',
  customerPayments = 'transactions',
}

export enum ManagerTransactionAction {
  addTransaction = 'add_transaction',
  deleteTransaction = 'delete_transaction',
  finish = 'finish',
}

export type ManagerFinalizationAction =
  | Exclude<ManagerFinalizationStep, ManagerFinalizationStep.customerPayments>
  | ManagerTransactionAction;

export enum SupplierFinalizationStep {
  cargoArrival = 'cargo_arrival_confirmation',
  supplierAcceptanceCertificate = 'upload_supplier_acceptance_certificate',
  supplierAcceptanceCertificateVerifying = 'supplier_acceptance_certificate_verifying',
  supplierSignedAcceptanceCertificate = 'upload_signed_supplier_acceptance_certificate',
  finish = 'finish',
}

export type SupplierFinalizationAction = Extract<
  SupplierFinalizationStep,
  SupplierFinalizationStep.supplierAcceptanceCertificate
>;

export enum CustomerFinalizationStep {
  cargoDelivery = 'cargo_delivery_confirmation',
  tradeCloseCustomerDocs = 'upload_documents_for_trade_close_customer',
  finish = 'finish',
}

export type CustomerPaymentServer = {
  id: number;
  identifier: string;
  created_at: string;
  amount: number;
  price_unit: UnitDictionaryServer;
  amount_usd: number;
  description: string;
};

export type MapUserTypeToFinalizationStageData = {
  [CompanyType.owner]: {
    [ManagerFinalizationStep.cargoDelivered]: Nullable<{ delivered: boolean }>;
    [ManagerFinalizationStep.tradeCloseCustomerDocs]: Nullable<TradeCloseCustomerDocsServer>;
    [ManagerFinalizationStep.customerPayments]: Nullable<CustomerPaymentServer[]>;
  };
  [CompanyType.supplier]: {
    [SupplierFinalizationStep.cargoArrival]: Nullable<{ date: Date }>;
    [SupplierFinalizationStep.supplierAcceptanceCertificate]: Nullable<DocumentWithStatusServer>;
    [SupplierFinalizationStep.supplierSignedAcceptanceCertificate]: Nullable<DocumentServer>;
  };
  [CompanyType.customer]: {
    [CustomerFinalizationStep.cargoDelivery]: Nullable<{ delivered: boolean }>;
    [CustomerFinalizationStep.tradeCloseCustomerDocs]: Nullable<TradeCloseCustomerDocsServer>;
  };
};

type BaseFinalizationStageDataServer<CT extends CompanyType, Step> = BaseStageDataServer<
  Step,
  MapUserTypeToFinalizationStageData[CT]
>;
export type ManagerFinalizationStageDataServer = BaseFinalizationStageDataServer<
  CompanyType.owner,
  ManagerFinalizationStep
>;
export type SupplierFinalizationStageDataServer = BaseFinalizationStageDataServer<
  CompanyType.supplier,
  SupplierFinalizationStep
>;
export type CustomerFinalizationStageDataServer = BaseFinalizationStageDataServer<
  CompanyType.customer,
  CustomerFinalizationStep
>;

export type FinalizationStageDataServer =
  | ManagerFinalizationStageDataServer
  | SupplierFinalizationStageDataServer
  | CustomerFinalizationStageDataServer;

export type FinalizationAction = ManagerFinalizationAction;
