import { Tag } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { TranslationString } from '@/shared/types/localization';

import { TransComp } from '../TransComp';

import s from './CustomTag.module.scss';

export type TagConfig = {
  color: string;
  label: TranslationString;
};

type CustomTagProps = TagConfig & {
  className?: string;
};

const CustomTag: React.FC<CustomTagProps> = ({ className, color, label }) => {
  return (
    <Tag className={cn(s.tag, className)} color={color}>
      <TransComp>{label}</TransComp>
    </Tag>
  );
};

export default React.memo(CustomTag);
