import { DocumentFileType } from '@/entities/file';
import {
  ManagerConfirmationAction,
  ManagerConfirmationStageDataServer,
  ManagerConfirmationStep,
  ManagerConfirmationVerifyingAction,
  StageModelJsonParams,
  StageType,
} from '@/entities/stage/types';

import { BaseManagerStageModel, BaseManagerStageModelParams } from '../../BaseManagerStageModel';
import { BaseUploadFilesFieldsModel, DocumentVerifyingFieldsModel } from '../../fieldsModel';

import { mapManagerStepToNumber } from './config';

type Params = BaseManagerStageModelParams<ManagerConfirmationStep> & {
  deliveryConfirmFields: BaseUploadFilesFieldsModel<
    ManagerConfirmationStep,
    ManagerConfirmationAction,
    DocumentFileType.deliveryConfirmation
  >;
  confirmVerifyingFields: DocumentVerifyingFieldsModel<ManagerConfirmationStep, ManagerConfirmationAction>;
  confirmSigningFields: BaseUploadFilesFieldsModel<
    ManagerConfirmationStep,
    ManagerConfirmationAction,
    DocumentFileType.confirmationSigning
  >;
};

export class ManagerConfirmationStageModel extends BaseManagerStageModel<
  StageType.confirmation,
  ManagerConfirmationStep
> {
  readonly deliveryConfirmFields: BaseUploadFilesFieldsModel<
    ManagerConfirmationStep,
    ManagerConfirmationAction,
    DocumentFileType.deliveryConfirmation
  >;
  readonly confirmVerifyingFields: DocumentVerifyingFieldsModel<ManagerConfirmationStep, ManagerConfirmationAction>;
  readonly confirmSigningFields: BaseUploadFilesFieldsModel<
    ManagerConfirmationStep,
    ManagerConfirmationAction,
    DocumentFileType.confirmationSigning
  >;

  constructor({ deliveryConfirmFields, confirmVerifyingFields, confirmSigningFields, ...params }: Params) {
    super({
      type: StageType.confirmation,
      ...params,
    });

    this.deliveryConfirmFields = deliveryConfirmFields;
    this.confirmVerifyingFields = confirmVerifyingFields;
    this.confirmSigningFields = confirmSigningFields;
  }

  get currentStep(): number {
    return mapManagerStepToNumber(this.step, this.confirmVerifyingFields.isNeedVerifying);
  }

  get steps(): ManagerConfirmationStep[] {
    let steps: ManagerConfirmationStep[] = [];

    if (this.confirmVerifyingFields.isNeedVerifying) {
      steps = [
        ManagerConfirmationStep.deliveryConfirmation,
        ManagerConfirmationStep.confirmationVerifying,
        ManagerConfirmationStep.confirmationSigning,
      ];
    } else {
      steps = [ManagerConfirmationStep.deliveryConfirmation, ManagerConfirmationStep.confirmationSigning];
    }

    if (this.step === ManagerConfirmationStep.transfer) {
      steps.push(ManagerConfirmationStep.transfer);
    }

    return steps;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<ManagerConfirmationStageDataServer>): ManagerConfirmationStageModel {
    return new ManagerConfirmationStageModel({
      deliveryConfirmFields: BaseUploadFilesFieldsModel.fromJson({
        data: dataServer.steps[ManagerConfirmationStep.deliveryConfirmation],
        step: ManagerConfirmationStep.deliveryConfirmation,
        approveAction: ManagerConfirmationStep.deliveryConfirmation,
        docType: DocumentFileType.deliveryConfirmation,
        tradeWorkflowStore,
      }),
      confirmVerifyingFields: DocumentVerifyingFieldsModel.fromJson({
        data: dataServer.steps[ManagerConfirmationStep.deliveryConfirmation],
        step: ManagerConfirmationStep.confirmationVerifying,
        approveAction: ManagerConfirmationVerifyingAction.approveConfirmationVerifying,
        rejectAction: ManagerConfirmationVerifyingAction.rejectConfirmationVerifying,
        tradeWorkflowStore,
      }),
      confirmSigningFields: BaseUploadFilesFieldsModel.fromJson({
        data: dataServer.steps[ManagerConfirmationStep.confirmationSigning],
        step: ManagerConfirmationStep.confirmationSigning,
        approveAction: ManagerConfirmationStep.confirmationSigning,
        docType: DocumentFileType.confirmationSigning,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
