import { autorun, computed, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import {
  BidClient,
  BidClientServer,
  BidCompany,
  BidCompanyServer,
  BidDifferenceType,
  BidFixingType,
  BidInfo,
  BidModelParams,
  BidPrices,
  BidServer,
  BidStatus,
  BidType,
  IBaseBid,
  IBidTableData,
} from '@/entities/bid/types';
import {
  IDictionariesStore,
  IDictionary,
  IProductDictionary,
  IProductPrice,
  IUnitDictionary,
  PriceProvider,
  ProductType,
} from '@/entities/dictionary';
import { LocalStore, ValueModel } from '@/shared/model';
import { isNullable } from '@/shared/types/typesGuard';
import { Nullable, WeightWithUnit } from '@/shared/types/values';
import {
  convertGramToTroyOunce,
  convertOuncePriceToGramPrice,
  fixNumberDecimals,
  formatDate,
  formatNumberWithUnit,
  getLigatureWeight,
} from '@/shared/utils';

import { mapBidStatusToLabel, mapBidTypeToLabel, mapFixingTypeToLabel } from '../config';

type JsonParams<Server extends BidServer> = {
  server: Server;
  rootStore: IRootStore;
};

type BidDictionaries = {
  product: IProductDictionary;
  spotPrice: Nullable<IProductPrice>;
  weightUnit: IUnitDictionary;
  shape: IUnitDictionary;
  currency: IDictionary;
  authorDiffCurrency: Nullable<IDictionary>;
  respondentDiffCurrency: Nullable<IDictionary>;
  managerDiffCurrency: Nullable<IDictionary>;
  buyerCountry: Nullable<IDictionary>;
};

export class BidModel extends LocalStore implements IBaseBid {
  readonly id: number;
  readonly status: BidStatus;
  readonly type: BidType;
  readonly tradeId: Nullable<number>;
  readonly productId: number;
  readonly shapeId: number;
  readonly fixingType: BidFixingType;
  readonly fixingDate: Nullable<Date>;
  readonly fixingPrice: Nullable<number>;
  readonly currencyId: number;
  readonly weight: number;
  readonly ligatureWeight: number;
  readonly weightUnitId: number;
  readonly priceProvider: PriceProvider;
  readonly history: {
    weight: Nullable<number>;
    ligatureWeight: Nullable<number>;
  };
  readonly replyCount: Nullable<number>;
  readonly buyer: Nullable<BidClient>;
  readonly seller: Nullable<BidClient>;
  readonly manager: Nullable<BidClient>;

  private readonly _rootStore: IRootStore;
  private readonly _bidDictionaries = new ValueModel<Nullable<BidDictionaries>>(null);

  constructor(params: BidModelParams) {
    super();

    this._rootStore = params.rootStore;

    this.id = params.id;
    this.status = params.status;
    this.type = params.type;
    this.tradeId = params.tradeId;
    this.productId = params.productId;
    this.shapeId = params.shapeId;
    this.fixingType = params.fixingType;
    this.fixingDate = params.fixingDate;
    this.fixingPrice = params.fixingPrice;
    this.currencyId = params.currencyId;
    this.weight = params.weight;
    this.weightUnitId = params.weightUnitId;
    this.ligatureWeight = params.ligatureWeight;
    this.priceProvider = params.priceProvider;
    this.history = params.history;
    this.replyCount = params.replyCount;
    this.buyer = params.buyer;
    this.seller = params.seller;
    this.manager = params.manager;

    makeObservable(this, {
      author: computed,
      respondent: computed,
      companyName: computed,
      bidDictionaries: computed,
      bidInfo: computed,
      bidPrices: computed,
      weightWithUnit: computed,
      ligatureWeightWithUnit: computed,
      baseTableData: computed,
      isOwnBid: computed,
    });

    this.addReactions([
      autorun(() => {
        const productList = this._dictionaries.products.list.entities.get(ProductType.metal);
        const product = productList && productList.entities.get(this.productId);
        const weightUnit = product?.unitList.entities.get(this.weightUnitId);
        const shape = product?.shapeList.entities.get(this.shapeId);
        const currency = this._dictionaries.currencies.list.entities.get(this.currencyId);

        if (isNullable(product) || isNullable(weightUnit) || isNullable(shape) || isNullable(currency)) {
          return;
        }

        const spotPrice = product && product[this.priceProvider];
        const buyerCountry =
          this.buyer && this.buyer.company && this._dictionaries.countries.list.getEntity(this.buyer.company.countryId);
        const authorDiffCurrency = this.author?.diffCurrencyId
          ? this._dictionaries.currencies.list.getEntity(this.author.diffCurrencyId)
          : null;
        const respondentDiffCurrency = this.respondent?.diffCurrencyId
          ? this._dictionaries.currencies.list.getEntity(this.respondent.diffCurrencyId)
          : null;
        const managerDiffCurrency = this.manager?.diffCurrencyId
          ? this._dictionaries.currencies.list.getEntity(this.manager.diffCurrencyId)
          : null;

        this._bidDictionaries.change({
          product,
          spotPrice,
          weightUnit,
          shape,
          currency,
          authorDiffCurrency,
          respondentDiffCurrency,
          managerDiffCurrency,
          buyerCountry,
        });
      }),
    ]);
  }

  private get _dictionaries(): IDictionariesStore {
    return this._rootStore.dictionariesStore;
  }

  get author(): Nullable<BidClient> {
    return this.type === BidType.buy ? this.buyer : this.seller;
  }

  get respondent(): Nullable<BidClient> {
    return this.type === BidType.buy ? this.seller : this.buyer;
  }

  get companyName(): Nullable<string> {
    /** Скрываем инфу для наблюдателя владельца */
    return this._rootStore.userStore.isOwnerObserver
      ? null
      : this.author && this.author.company && this.author.company.name;
  }

  get bidDictionaries(): Nullable<BidDictionaries> {
    return this._bidDictionaries.value;
  }

  get bidInfo(): Nullable<BidInfo> {
    if (
      isNullable(this.bidDictionaries) ||
      isNullable(this.weightWithUnit) ||
      isNullable(this.ligatureWeightWithUnit)
    ) {
      return null;
    }

    const { product, spotPrice, shape, currency, buyerCountry } = this.bidDictionaries;

    const spotPriceCurrency = spotPrice?.currency ?? null;

    return {
      status: mapBidStatusToLabel(this.status),
      type: mapBidTypeToLabel(this.type),
      metal: product.label,
      shape: shape.label,
      fixingType: mapFixingTypeToLabel(this.fixingType),
      fixingDate: this.fixingDate && formatDate(this.fixingDate),
      currency: currency.title,
      weightWithUnit: this.weightWithUnit.gram,
      ligatureWeightWithUnit: this.ligatureWeightWithUnit.gram,
      fixingPrice:
        this.fixingPrice && spotPriceCurrency ? formatNumberWithUnit(this.fixingPrice, spotPriceCurrency.title) : null,

      /** Скрываем инфу для наблюдателя владельца */
      sellerName: this._rootStore.userStore.isOwnerObserver
        ? null
        : this.seller && this.seller.company && this.seller.company.name,
      buyerName: this._rootStore.userStore.isOwnerObserver
        ? null
        : this.buyer && this.buyer.company && this.buyer.company.name,
      /** */

      buyerCountry: buyerCountry && buyerCountry.label,
      authorDeliveryBasis: this.author?.deliveryBasis ? this.author.deliveryBasis.toUpperCase() : null,
      managerDeliveryBasis: this.manager?.deliveryBasis ? this.manager.deliveryBasis.toUpperCase() : null,
      respondentDeliveryBasis: this.respondent?.deliveryBasis ? this.respondent.deliveryBasis.toUpperCase() : null,
      authorDifference: this._getDifference(this.author, spotPriceCurrency),
      managerDifference: this._getDifference(this.manager, spotPriceCurrency),
      respondentDifference: this._getDifference(this.respondent, spotPriceCurrency),
      replyCount: this.replyCount,
    };
  }

  get weightWithUnit(): Nullable<WeightWithUnit> {
    if (isNullable(this.bidDictionaries)) {
      return null;
    }

    const { t } = this._rootStore.localizationStore;

    return {
      gram: formatNumberWithUnit(this.weight, this.bidDictionaries.weightUnit.label, 3),
      ounce: formatNumberWithUnit(
        convertGramToTroyOunce(this.weight),
        t('weightUnits.oz', { ns: 'shared', count: this.weight }),
        3,
      ),
    };
  }

  get ligatureWeightWithUnit(): Nullable<WeightWithUnit> {
    if (isNullable(this.bidDictionaries)) {
      return null;
    }

    const { t } = this._rootStore.localizationStore;

    return {
      gram: formatNumberWithUnit(this.ligatureWeight, this.bidDictionaries.weightUnit.label, 3),
      ounce: formatNumberWithUnit(
        convertGramToTroyOunce(this.ligatureWeight),
        t('weightUnits.oz', { ns: 'shared', count: this.ligatureWeight }),
        3,
      ),
    };
  }

  get bidPrices(): Nullable<BidPrices> {
    const bidDictionaries = this.bidDictionaries;

    const spotPrice = bidDictionaries?.spotPrice;
    const { diffType, diffValue } = this.manager ?? this.author ?? { diffType: null, diffValue: null };

    if (isNullable(bidDictionaries) || isNullable(spotPrice) || isNullable(diffType) || isNullable(diffValue)) {
      return null;
    }

    const fixingPrice = this.fixingPrice;

    if (fixingPrice) {
      return this._getPrices({
        prevOuncePrice: null,
        ouncePrice: fixingPrice,
        gramWeight: this.weight,
        currency: spotPrice.currency,
        diffType,
        diffValue,
      });
    }

    return this._getPrices({
      prevOuncePrice: spotPrice.previous,
      ouncePrice: spotPrice.current,
      gramWeight: this.weight,
      currency: spotPrice.currency,
      diffType,
      diffValue,
    });
  }

  get baseTableData(): Nullable<IBidTableData<BidModel>> {
    if (
      isNullable(this.bidInfo) ||
      isNullable(this.bidDictionaries) ||
      isNullable(this.weightWithUnit) ||
      isNullable(this.ligatureWeightWithUnit)
    ) {
      return null;
    }

    const { weightUnit } = this.bidDictionaries;

    return {
      model: this,
      status: this.status,
      type: this.type,
      metal: this.bidInfo.metal,
      shape: this.bidInfo.shape,
      fixingType: this.fixingType,
      fixingDate: this.fixingDate,
      weightWithUnit: this.weightWithUnit,
      ligatureWeightWithUnit: this.ligatureWeightWithUnit,
      deliveryBasis: this.bidInfo.managerDeliveryBasis ?? this.bidInfo.authorDeliveryBasis,
      difference: this.bidInfo.managerDifference ?? this.bidInfo.authorDifference,
      bidPrices: this.bidPrices,
      currency: this.bidInfo.currency,
      companyName: this.companyName,
      history: {
        weightWithUnit: this.history.weight ? formatNumberWithUnit(this.history.weight, weightUnit.label, 3) : null,
        ligatureWeightWithUnit: this.history.ligatureWeight
          ? formatNumberWithUnit(this.history.ligatureWeight, weightUnit.label, 3)
          : null,
      },
      replyCount: this.replyCount,
    };
  }

  get isOwnBid(): boolean {
    return Boolean(this.author && this.author.userId === this._rootStore.userStore.userModel?.id);
  }

  private _getPrices = ({
    prevOuncePrice,
    ouncePrice,
    gramWeight,
    currency,
    diffValue,
    diffType,
  }: {
    prevOuncePrice: Nullable<number>;
    ouncePrice: number;
    gramWeight: number;
    currency: IUnitDictionary;
    diffValue: number;
    diffType: BidDifferenceType;
  }): BidPrices => {
    const ounceWeight = convertGramToTroyOunce(gramWeight);
    const totalOuncePrice = ounceWeight * ouncePrice;

    let totalOuncePriceWithDiff: number;

    if (diffType === BidDifferenceType.price) {
      totalOuncePriceWithDiff = totalOuncePrice + diffValue;
    } else {
      totalOuncePriceWithDiff = totalOuncePrice + totalOuncePrice * (diffValue / 100);
    }

    return {
      ouncePrice: fixNumberDecimals(ouncePrice),
      gramPrice: convertOuncePriceToGramPrice(ouncePrice),
      totalOuncePrice: fixNumberDecimals(totalOuncePrice),
      totalOuncePriceWithDiff: fixNumberDecimals(totalOuncePriceWithDiff),
      priceUnit: currency,
      isPositive: prevOuncePrice !== null && ouncePrice > prevOuncePrice,
      isNegative: prevOuncePrice !== null && ouncePrice < prevOuncePrice,
    };
  };

  private _getDifference = <C extends BidClient>(client: Nullable<C>, currency: Nullable<IDictionary>) => {
    if (isNullable(client?.diffValue) || isNullable(client?.diffType)) {
      return null;
    }

    return formatNumberWithUnit(
      client.diffValue,
      client.diffType === BidDifferenceType.price && currency ? currency.title : '%',
    );
  };

  static mapCompanyServerToClient(server: BidCompanyServer): BidCompany {
    return {
      id: server.id,
      name: server.name,
      countryId: server.country_id,
    };
  }

  static mapClientServerToClient(server: Omit<BidClientServer, 'company'>): Omit<BidClient, 'company'> {
    return {
      userId: server.user_id,
      deliveryBasis: server.delivery_basis,
      diffCurrencyId: server.diff_currency_id,
      diffType: server.diff_type,
      diffValue: server.diff_value,
    };
  }

  static mapServerToClient<Server extends BidServer>({ server, rootStore }: JsonParams<Server>): BidModelParams {
    return {
      id: server.id,
      status: server.status,
      type: server.type,
      tradeId: server.trade_id,
      productId: server.product_id,
      shapeId: server.shape_id,
      fixingType: server.fixing_type,
      fixingDate: server.fixing_date ? new Date(server.fixing_date) : null,
      fixingPrice: server.fixing_price,
      weight: server.weight,
      ligatureWeight: getLigatureWeight(server.weight),
      weightUnitId: server.weight_unit_id,
      currencyId: server.currency_id,
      priceProvider: server.price_provider,
      history: {
        weight: server.history.weight,
        ligatureWeight: server.history.weight && getLigatureWeight(server.history.weight),
      },
      replyCount: server.reply_count,
      buyer: server.buyer && {
        ...BidModel.mapClientServerToClient(server.buyer),
        company: server.buyer.company && BidModel.mapCompanyServerToClient(server.buyer.company),
      },
      seller: server.seller && {
        ...BidModel.mapClientServerToClient(server.seller),
        company: server.seller.company && BidModel.mapCompanyServerToClient(server.seller.company),
      },
      manager: server.manager && { ...BidModel.mapClientServerToClient(server.manager), company: null },

      rootStore,
    };
  }
}
