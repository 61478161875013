import { ApiRequest } from '@kts-front/call-api';
import { ButtonProps } from 'antd';
import { action, computed, makeObservable, observable, reaction, toJS } from 'mobx';

import { INotificationsStore, MessageType } from '@/entities/message';
import { ITradeWorkflowStore } from '@/entities/trade';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, ToggleModel } from '@/shared/model';
import { UploadFilesModel } from '@/shared/model/form/UploadFilesModel';
import { ApiFieldsErrorData } from '@/shared/types/api';
import { ValidatorResult } from '@/shared/types/validator';

import { UploadDocumentsPayload } from '../types';

import { ArbitraryDocsFormModel, ArbitraryDocsFormModelParams } from './ArbitraryDocsFormModel';

export class UploadArbitraryDocsModel extends ArbitraryDocsFormModel {
  readonly modalState = new ToggleModel();
  readonly loadingStage = new LoadingStageModel();

  private _uploadError: ValidatorResult = null;
  private readonly _uploadRequest: ApiRequest<unknown>;

  constructor(params: ArbitraryDocsFormModelParams) {
    super(params);

    this._uploadRequest = apiStore.createRequest({
      method: 'POST',
      url: apiUrls.trade.upload(params.tradeWorkflowStore.tradeId),
      multipartFormData: true,
    });

    makeObservable<this, '_uploadError'>(this, {
      _uploadError: observable,

      uploadError: computed,
      okButtonProps: computed,

      closeModal: action.bound,
      onSubmit: action.bound,
    });

    this.addReactions([
      reaction(
        () => toJS(this._arbitraryDocsOptions),
        () => {
          this._uploadError = null;
        },
      ),
    ]);
  }

  get notificationsStore(): INotificationsStore {
    return this.tradeWorkflowStore.rootStore.notificationsStore;
  }

  get uploadError(): ValidatorResult {
    return this._uploadError;
  }

  get okButtonProps(): ButtonProps {
    return {
      disabled: !this.arbitraryDocs.isUploaded || this.isError,
      loading: this.loadingStage.isLoading,
      onClick: this.onSubmit,
    };
  }

  closeModal() {
    this._destroy;
    this.reset();
    this.loadingStage.reset();
    this.modalState.close();
  }

  private _destroy = (): void => {
    this._uploadError = null;
    this.destroyReactions();
  };

  async onSubmit(): Promise<void> {
    const isError = this.validate();

    if (isError) {
      return;
    }

    const payload = this.toUploadJson();

    if (!payload) {
      return;
    }

    this.loadingStage.loading();

    const response = await this._uploadRequest.call({
      data: payload,
    });

    if (response.isError) {
      this.loadingStage.error();

      this.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('messages.uploadDocError', { ns: 'file', count: this.arbitraryDocs.value.length }),
      });

      const errorData: ApiFieldsErrorData<keyof UploadDocumentsPayload> | undefined = response.data?.data;

      if (errorData && errorData.field_problems && errorData.field_problems.doc_types) {
        this._uploadError = (t) => t('messages.nonUniqueDocNameError', { ns: 'file' });
      } else {
        this._uploadError = (t) =>
          t('messages.uploadFileError', { ns: 'file', count: this.arbitraryDocs.value.length });
      }

      return;
    }

    this.notificationsStore.addNotification({
      type: MessageType.success,
      message: (t) => t('messages.uploadDocSuccess', { ns: 'file', count: this.arbitraryDocs.value.length }),
    });

    this.loadingStage.success();
    this.closeModal();

    this.tradeWorkflowStore.reloadStage();
  }

  static fromStore(tradeWorkflowStore: ITradeWorkflowStore, disabled: boolean): UploadArbitraryDocsModel {
    return new UploadArbitraryDocsModel({
      arbitraryDocs: new UploadFilesModel({
        initialValue: [],
        maxCount: 10,
        disabled,
      }),
      disabled,
      tradeWorkflowStore,
    });
  }
}
