import { TROY_OUNCE_CONVERSION_COEFFICIENT } from '@/shared/utils';

import { ProductWeightType } from './types';

export const mapWeightUnitToGram: Record<ProductWeightType, number> = {
  [ProductWeightType.gram]: 1,
  [ProductWeightType.kilogram]: 1_000,
  [ProductWeightType.ton]: 1_000_000,
  [ProductWeightType.troyOunce]: TROY_OUNCE_CONVERSION_COEFFICIENT,
};

export const convertWeightToGram = ({
  weight,
  weightUnit,
}: {
  weight: number;
  weightUnit: ProductWeightType;
}): number => {
  return mapWeightUnitToGram[weightUnit] * weight;
};
