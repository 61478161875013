import { CompanyAdminUser, UserModel } from '@/entities/user';

import { ICompanyWithAdmin } from '../types/client';
import { CompanyWithAdminServer } from '../types/server';

import { CompanyModel, CompanyModelParams } from './CompanyModel';

type Params = CompanyModelParams & {
  admin: CompanyAdminUser;
};

export class CompanyWithAdminModel extends CompanyModel implements ICompanyWithAdmin {
  readonly admin: CompanyAdminUser;

  constructor(params: Params) {
    super(params);

    this.admin = params.admin;
  }

  static fromJson(server: CompanyWithAdminServer): CompanyWithAdminModel {
    return new CompanyWithAdminModel({
      id: server.id,
      type: server.type,
      name: server.name,
      email: server.email,
      phone: server.phone,
      address: server.address,
      admin: UserModel.fromServer(server.admin) as CompanyAdminUser,
    });
  }
}
