import { Flex, Modal, ModalProps } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { TranslationString } from '@/shared/types/localization';

import { TransComp } from '../TransComp';
import { CustomButton } from '../form/CustomButton';

import s from './CustomModal.module.scss';

type CloseButtonProps = {
  disabled?: boolean;
};

type CustomModalProps = Omit<ModalProps, 'centered' | 'classNames' | 'okType' | 'okText' | 'cancelText' | 'title'> &
  Partial<{
    danger: boolean;
    onClose: VoidFunction;
    closeButtonProps: CloseButtonProps;
    okText: TranslationString;
    cancelText: TranslationString;
    title: TranslationString;
    footerProps: ModalProps['footer'];
  }>;

const CustomModal: React.FC<CustomModalProps> = ({
  className,
  title,
  danger,
  okText,
  cancelText = (t) => t('buttons.cancel', { ns: 'shared' }),
  cancelButtonProps,
  closeButtonProps,
  okButtonProps,
  onCancel,
  onOk,
  onClose,
  footerProps,
  ...props
}) => {
  const handleCancel = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!cancelButtonProps?.disabled && !cancelButtonProps?.loading && onCancel) {
        onCancel(event);
      }
    },
    [onCancel, cancelButtonProps?.disabled, cancelButtonProps?.loading],
  );

  const handleClose = React.useCallback(() => {
    if (!closeButtonProps?.disabled && onClose) {
      onClose();
    }
  }, [onClose, closeButtonProps?.disabled]);

  return (
    <Modal
      rootClassName={cn(s['custom-modal'], className)}
      title={<TransComp>{title}</TransComp>}
      onCancel={onClose ? handleClose : handleCancel}
      centered
      footer={
        footerProps || (
          <Flex className="ant-modal-footer-content" gap={16}>
            <CustomButton size="large" onClick={handleCancel} {...cancelButtonProps}>
              {cancelText}
            </CustomButton>
            <CustomButton danger={danger} type="primary" size="large" onClick={onOk} {...okButtonProps}>
              {okText}
            </CustomButton>
          </Flex>
        )
      }
      {...props}
    />
  );
};

export default CustomModal;
