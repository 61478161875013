import { computed, makeObservable } from 'mobx';

import { AsyncFilesUploadModel, DocumentFileType } from '@/entities/file';
import { TradeCloseCustomerDocsServer } from '@/entities/stage/types';
import { UploadFileModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import { BaseFieldsModel, BaseFieldsModelParams } from '../../../fieldsModel';

type Params<Step, Action> = BaseFieldsModelParams<Step, Action> & {
  signedCertificate: AsyncFilesUploadModel<DocumentFileType.signedCustomerAcceptanceCertificate>;
  additionalAgreement: AsyncFilesUploadModel<DocumentFileType.signedCustomerAdditionalAgreement>;
};

export class TradeCloseCustomerDocsFieldsModel<Step, Action> extends BaseFieldsModel<Step, Action> {
  readonly signedCertificate: AsyncFilesUploadModel<DocumentFileType.signedCustomerAcceptanceCertificate>;
  readonly additionalAgreement: AsyncFilesUploadModel<DocumentFileType.signedCustomerAdditionalAgreement>;

  constructor({ signedCertificate, additionalAgreement, ...params }: Params<Step, Action>) {
    super(params);

    this.signedCertificate = signedCertificate;
    this.additionalAgreement = additionalAgreement;

    makeObservable(this, {
      approveDisabled: computed,
    });
  }

  get approveDisabled(): boolean {
    return (
      this.additionalAgreement.isError ||
      !this.additionalAgreement.isUploaded ||
      this.signedCertificate.isError ||
      !this.signedCertificate.isUploaded
    );
  }

  static fromJson<Step, Action>({
    data,
    ...params
  }: {
    data?: Nullable<TradeCloseCustomerDocsServer>;
  } & BaseFieldsModelParams<Step, Action>): TradeCloseCustomerDocsFieldsModel<Step, Action> {
    return new TradeCloseCustomerDocsFieldsModel({
      signedCertificate: new AsyncFilesUploadModel({
        initialValue: UploadFileModel.fileListFromJson(data?.[DocumentFileType.signedCustomerAcceptanceCertificate]),
        required: true,
        docType: DocumentFileType.signedCustomerAcceptanceCertificate,
        tradeWorkflowStore: params.tradeWorkflowStore,
        description: (t) =>
          t('manager.tradeCloseCustomerDocs.descriptions.signedCertificate', { ns: 'finalizationStage' }),
      }),
      additionalAgreement: new AsyncFilesUploadModel({
        initialValue: UploadFileModel.fileListFromJson(data?.[DocumentFileType.signedCustomerAdditionalAgreement]),
        required: true,
        docType: DocumentFileType.signedCustomerAdditionalAgreement,
        tradeWorkflowStore: params.tradeWorkflowStore,
        description: (t) =>
          t('manager.tradeCloseCustomerDocs.descriptions.additionalAgreement', { ns: 'finalizationStage' }),
      }),
      ...params,
    });
  }
}
