import { ITradeWorkflowStore } from '@/entities/trade';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { Nullable } from '@/shared/types/values';

import { WaitingFieldsModelParams } from '../WaitingFieldsModel/WaitingFieldsModel';

export type WaitingFieldsModelDateParams<Step> = WaitingFieldsModelParams<Step> & {
  date: DatePickerModel;
};

export class WaitingFieldsWithDateModel<Step> {
  readonly step: Step;
  readonly tradeWorkflowStore: ITradeWorkflowStore;
  readonly date: DatePickerModel;

  constructor({ step, tradeWorkflowStore, date }: WaitingFieldsModelDateParams<Step>) {
    this.step = step;
    this.tradeWorkflowStore = tradeWorkflowStore;
    this.date = date;
  }

  static fromJson<Step>({
    data,
    ...params
  }: { data?: Nullable<Date> } & WaitingFieldsModelParams<Step>): WaitingFieldsWithDateModel<Step> {
    return new WaitingFieldsWithDateModel({
      date: new DatePickerModel({
        initialValue: data || null,
      }),
      ...params,
    });
  }
}
