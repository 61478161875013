import { BaseFileServer, DocumentFileType, FileModel } from '@/entities/file';
import { UploadFilesModel, UploadFilesModelParams } from '@/shared/model/form/UploadFilesModel';
import { Nullable } from '@/shared/types/values';

import { WaitingFieldsModel, WaitingFieldsModelParams } from '../WaitingFieldsModel';

type FilesMapServer<DocType extends DocumentFileType> = Record<DocType, Nullable<BaseFileServer>>;

type FileList<DocType extends DocumentFileType> = Array<{
  docType: DocType;
  file: UploadFilesModel;
}>;

export type DocTypesOptions<DocType extends DocumentFileType> = Array<
  { docType: DocType } & Partial<UploadFilesModelParams>
>;

export type WaitingFieldsWithFilesModelParams<
  Step,
  DocType extends DocumentFileType,
> = WaitingFieldsModelParams<Step> & {
  files: Nullable<FileList<DocType>>;
};

export class WaitingFieldsWithFilesModel<Step, DocType extends DocumentFileType> extends WaitingFieldsModel<Step> {
  readonly files: Nullable<FileList<DocType>>;

  constructor({ files, ...params }: WaitingFieldsWithFilesModelParams<Step, DocType>) {
    super(params);

    this.files = files;
  }

  static fromJson<Step, DocType extends DocumentFileType>({
    data,
    options,
    ...params
  }: WaitingFieldsModelParams<Step> & {
    data: Nullable<FilesMapServer<DocType>>;
    options: DocTypesOptions<DocType>;
  }) {
    return new WaitingFieldsWithFilesModel({
      files:
        data &&
        options.reduce<FileList<DocType>>((list, { docType, ...option }) => {
          const file = data[docType];

          if (!file) {
            return list;
          }

          list.push({
            docType,
            file: new UploadFilesModel({
              ...option,
              initialValue: FileModel.fileListFromJson(file),
              maxCount: 1,
              required: true,
            }),
          });

          return list;
        }, []),

      ...params,
    });
  }
}
