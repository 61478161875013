import { computed, makeObservable } from 'mobx';

import { CompanyType } from '@/entities/company';
import { DocumentFileType } from '@/entities/file';
import {
  AwbDirectAction,
  AwbTransitAction,
  ManagerAwbVerifyingAction,
  ManagerShipmentAction,
  ManagerShipmentStageDataServer,
  ManagerShipmentStep,
  ManagerSpecificationVerifyingAction,
  OptionFieldsAction,
  ShipmentType,
  StageModelJsonParams,
  StageType,
} from '@/entities/stage/types';
import { ToggleModel } from '@/shared/model';
import { mapStepToNumber } from '@/shared/utils';

import { BaseManagerStageModel, BaseManagerStageModelParams } from '../../BaseManagerStageModel';
import {
  BaseUploadFilesFieldsModel,
  ConfirmationFieldsModel,
  DestinationCountryDocsFieldsModel,
  DocumentVerifyingFieldsModel,
  UploadAwbOrFlightNumberModel,
} from '../../fieldsModel';
import { ShipmentTypeFieldsModel } from '../fieldsModel/ShipmentTypeFieldsModel';
import { TransitCountryFieldsModel } from '../fieldsModel/TransitCountryFieldsModel';

import { stepsOrder } from './config';

type Params = BaseManagerStageModelParams<ManagerShipmentStep> & {
  uploadSpecFields: BaseUploadFilesFieldsModel<
    ManagerShipmentStep,
    ManagerShipmentAction,
    DocumentFileType.supplierSpecification
  >;
  specVerifyingFields: DocumentVerifyingFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  shipmentTypeFields: ShipmentTypeFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  transitCountryFields: TransitCountryFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  transitCountryAwbFields: UploadAwbOrFlightNumberModel<
    ManagerShipmentStep,
    AwbTransitAction.uploadFile,
    AwbTransitAction.flightNumber,
    DocumentFileType.awbForTransitCountry
  >;
  transitCountryAwbVerifyingFields: DocumentVerifyingFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  destinationCountryDocsFields: DestinationCountryDocsFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  destinationCountryAwbFields: UploadAwbOrFlightNumberModel<
    ManagerShipmentStep,
    AwbDirectAction.uploadFile,
    AwbDirectAction.flightNumber,
    DocumentFileType.awbForDirectCountry
  >;
  cargoShipmentFields: ConfirmationFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
};

export class ManagerShipmentStageModel extends BaseManagerStageModel<StageType.shipment, ManagerShipmentStep> {
  readonly uploadSpecFields: BaseUploadFilesFieldsModel<
    ManagerShipmentStep,
    ManagerShipmentAction,
    DocumentFileType.supplierSpecification
  >;
  readonly specVerifyingFields: DocumentVerifyingFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  readonly shipmentTypeFields: ShipmentTypeFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  readonly cargoShipmentFields: ConfirmationFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  /** Поля для StageType.transit */
  readonly transitCountryFields: TransitCountryFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  readonly transitCountryAwbFields: UploadAwbOrFlightNumberModel<
    ManagerShipmentStep,
    AwbTransitAction.uploadFile,
    AwbTransitAction.flightNumber,
    DocumentFileType.awbForTransitCountry
  >;
  readonly transitCountryAwbVerifyingFields: DocumentVerifyingFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  /** Поля для StageType.direct */
  readonly destinationCountryDocsFields: DestinationCountryDocsFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  readonly destinationCountryAwbFields: UploadAwbOrFlightNumberModel<
    ManagerShipmentStep,
    AwbDirectAction.uploadFile,
    AwbDirectAction.flightNumber,
    DocumentFileType.awbForDirectCountry
  >;

  readonly deleteSupplierSpecModalState = new ToggleModel();

  constructor(params: Params) {
    super({
      type: StageType.shipment,
      ...params,
    });

    this.uploadSpecFields = params.uploadSpecFields;
    this.specVerifyingFields = params.specVerifyingFields;
    this.shipmentTypeFields = params.shipmentTypeFields;
    this.cargoShipmentFields = params.cargoShipmentFields;

    this.transitCountryFields = params.transitCountryFields;
    this.transitCountryAwbFields = params.transitCountryAwbFields;
    this.transitCountryAwbVerifyingFields = params.transitCountryAwbVerifyingFields;

    this.destinationCountryDocsFields = params.destinationCountryDocsFields;
    this.destinationCountryAwbFields = params.destinationCountryAwbFields;

    makeObservable(this, {
      shipmentTypeChoiceDisabled: computed,
      shipmentTypeChosen: computed,
    });
  }

  get currentStep(): number {
    return mapStepToNumber({ step: this.step, steps: this.steps, stepsOrder });
  }

  get steps(): ManagerShipmentStep[] {
    const steps: ManagerShipmentStep[] = [
      ManagerShipmentStep.uploadSpec,
      /** Если нужна верификация спецификации, то добавляем шаг верификации */
      ...(this.specVerifyingFields.isNeedVerifying ? [ManagerShipmentStep.specVerifying] : []),
      ManagerShipmentStep.shipmentType,
    ];

    const shipmentType = this.shipmentTypeFields.shipmentType;

    /** Если тип отгрузки = transit, то добавляем шаги для transit */
    if (shipmentType === ShipmentType.transit) {
      steps.push(
        ManagerShipmentStep.transitCountry,
        ManagerShipmentStep.transitCountryAwb,
        /** Если нужна верификация Awb, то добавляем шаг верификации Awb */
        ...(this.transitCountryAwbVerifyingFields.isNeedVerifying
          ? [ManagerShipmentStep.transitCountryAwbVerifying]
          : []),
      );
    }

    /** Если тип отгрузки = direct, то добавляем шаги для direct */
    if (shipmentType === ShipmentType.direct) {
      steps.push(ManagerShipmentStep.destinationCountryDocs, ManagerShipmentStep.destinationCountryAwb);
    }

    /** Если выбран тип отгрузки, то добавляем шаг подтверждения отгрузки */
    if (shipmentType) {
      steps.push(ManagerShipmentStep.cargoShipment);
    }

    /** Если шаг = transfer, то добавляем шаг transfer */
    if (this.step === ManagerShipmentStep.transfer) {
      steps.push(ManagerShipmentStep.transfer);
    }

    return steps;
  }

  get shipmentTypeChoiceDisabled(): boolean {
    return this.step === ManagerShipmentStep.uploadSpec || this.step === ManagerShipmentStep.specVerifying;
  }

  get shipmentTypeChosen(): boolean {
    return this.shipmentTypeFields.shipmentType !== null && this.step !== ManagerShipmentStep.shipmentType;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<ManagerShipmentStageDataServer>): ManagerShipmentStageModel {
    const { current_step, steps, supplier_specification } = dataServer;
    const isSpecVerifyingStep =
      current_step === ManagerShipmentStep.uploadSpec &&
      steps.upload_shipment_specification?.document?.uploaded_by?.company?.type === CompanyType.supplier;

    return new ManagerShipmentStageModel({
      uploadSpecFields: BaseUploadFilesFieldsModel.fromJson<
        ManagerShipmentStep,
        ManagerShipmentAction,
        DocumentFileType.supplierSpecification
      >({
        data:
          steps[ManagerShipmentStep.uploadSpec] ??
          (supplier_specification ? { document: supplier_specification ?? null } : null),
        step: ManagerShipmentStep.uploadSpec,
        approveAction: ManagerShipmentStep.uploadSpec,
        rejectAction: OptionFieldsAction.deleteSupplierSpec,
        docType: DocumentFileType.supplierSpecification,
        tradeWorkflowStore,
      }),
      specVerifyingFields: DocumentVerifyingFieldsModel.fromJson({
        data: steps[ManagerShipmentStep.uploadSpec],
        step: ManagerShipmentStep.specVerifying,
        approveAction: ManagerSpecificationVerifyingAction.approveSpecification,
        rejectAction: ManagerSpecificationVerifyingAction.rejectSpecification,
        tradeWorkflowStore,
      }),
      shipmentTypeFields: ShipmentTypeFieldsModel.fromJson({
        data: steps[ManagerShipmentStep.shipmentType]?.type,
        step: ManagerShipmentStep.shipmentType,
        approveAction: ManagerShipmentStep.shipmentType,
        tradeWorkflowStore,
      }),
      cargoShipmentFields: ConfirmationFieldsModel.fromJson({
        data: steps[ManagerShipmentStep.cargoShipment]?.shipped,
        step: ManagerShipmentStep.cargoShipment,
        approveAction: ManagerShipmentStep.cargoShipment,
        tradeWorkflowStore,
      }),
      transitCountryFields: TransitCountryFieldsModel.fromJson({
        data: steps.select_transit_country?.id,
        step: ManagerShipmentStep.transitCountry,
        approveAction: ManagerShipmentStep.transitCountry,
        tradeWorkflowStore,
      }),
      transitCountryAwbFields: UploadAwbOrFlightNumberModel.fromServer({
        data: steps[ManagerShipmentStep.transitCountryAwb],
        step: ManagerShipmentStep.transitCountryAwb,
        approveFileAction: AwbTransitAction.uploadFile,
        approveNumberAction: AwbTransitAction.flightNumber,
        docType: DocumentFileType.awbForTransitCountry,
        tradeWorkflowStore,
      }),
      transitCountryAwbVerifyingFields: DocumentVerifyingFieldsModel.fromJson({
        data: steps[ManagerShipmentStep.transitCountryAwb],
        step: ManagerShipmentStep.transitCountryAwbVerifying,
        approveAction: ManagerAwbVerifyingAction.approveAwb,
        rejectAction: ManagerAwbVerifyingAction.rejectAwb,
        tradeWorkflowStore,
      }),
      destinationCountryDocsFields: DestinationCountryDocsFieldsModel.fromJson({
        shipmentType: ShipmentType.direct,
        data: steps[ManagerShipmentStep.destinationCountryDocs],
        step: ManagerShipmentStep.destinationCountryDocs,
        approveAction: ManagerShipmentStep.destinationCountryDocs,
        tradeWorkflowStore,
      }),
      destinationCountryAwbFields: UploadAwbOrFlightNumberModel.fromServer({
        data: steps[ManagerShipmentStep.destinationCountryAwb],
        step: ManagerShipmentStep.destinationCountryAwb,
        approveFileAction: AwbDirectAction.uploadFile,
        approveNumberAction: AwbDirectAction.flightNumber,
        docType: DocumentFileType.awbForDirectCountry,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
      step: isSpecVerifyingStep ? ManagerShipmentStep.specVerifying : current_step,
    });
  }
}
