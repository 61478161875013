import { CompanyEmployeeStatus } from '@/entities/employee';

export const profile = {
  en: {
    title: 'Profile',
    menu: {
      me: 'My profile',
      company: 'Company',
      employees: 'Employees',
      notifications: 'Notifications',
    },
    profileEditTitle: 'Data',
    passwordFields: {
      title: 'Change password',
      currentPassword: 'Current password',
      newPassword: 'New password',
      repeatPassword: 'Repeat password',
      currentPasswordPlaceholder: 'Enter your current password',
      newPasswordPlaceholder: 'Enter new password',
      repeatPasswordPlaceholder: 'Please re-enter your new password',
    },
    errors: {
      invalidCurrentPassword: 'Invalid current password',
    },
    messages: {
      errorUnknown: 'An unknown error has occurred',
      editProfileSuccess: 'Profile updated successfully',
      editProfileError: 'An error occurred while updating the profile',
      editCompanySuccess: 'Company details successfully updated',
      editCompanyError: 'An error occurred while updating the company',
      passwordUpdated: 'Password successfully updated',
      errorUpdatingPassword: 'There was an error updating your password',
      employeeInviteSuccess: 'The employee has been successfully invited',
      employeeInviteError: 'There was an error inviting the employee',
      employeeBlocked: 'The employee has been blocked',
      employeeUnblocked: 'The employee has been unblocked',
      employeeErrorBlocked: 'Occurred due to an error blocking the employee',
      employeeErrorUnblocked: 'There was an error unblocking the employee',
      employeeEditSuccess: 'Employee details have been updated successfully',
      employeeEditError: 'An error occurred while updating employee data',
    },
    button: {
      buttonSave: 'Save changes',
      buttonReset: 'Cancel changes',
      buttonChange: 'Change password',
    },
    employee: {
      title: 'Information about employees',
      emptyListDescription: 'The list of employees is empty for now.',
      buttons: {
        addEmployee: 'Invite an employee',
        add: 'Invite',
        save: 'Save changes',
        block: 'Block',
        unBlock: 'Unblock',
      },
      fields: {
        email: 'Email',
        name: 'Name',
        surname: 'Surname',
        patronymic: 'Patronymic',
        fio: 'Full name',
        phone: 'Phone number',
        role: 'Role',
        status: 'Status',
      },
      placeholders: {
        role: 'Select a role',
      },
      statuses: {
        authorized: 'Authorized',
        invited: 'Invitation sent',
        blocked: 'Blocked',
      },
      handlingModal: {
        createTitle: 'Employee invitation',
        editTitle: 'Editing employee data',
      },
      blockingModal: {
        titleBlock: 'Confirm user blocking',
        description: 'Are you sure you want to block the user? The user will not be able to log in',
      },
    },
  },
  ru: {
    title: 'Профиль',
    menu: {
      me: 'Мой профиль',
      company: 'Компания',
      employees: 'Сотрудники',
      notifications: 'Уведомления',
    },
    profileEditTitle: 'Данные',
    passwordFields: {
      title: 'Изменение пароля',
      currentPassword: 'Текущий пароль',
      newPassword: 'Новый пароль',
      repeatPassword: 'Повторите пароль',
      currentPasswordPlaceholder: 'Введите текущий пароль',
      newPasswordPlaceholder: 'Введите новый пароль',
      repeatPasswordPlaceholder: 'Введите новый пароль повторно',
    },
    errors: {
      invalidCurrentPassword: 'Неверный текущий пароль',
    },
    messages: {
      errorUnknown: 'Произошла неизвестная ошибка',
      editProfileSuccess: 'Профиль успешно обновлен',
      editProfileError: 'Произошла ошибка при обновлении профиля',
      editCompanySuccess: 'Данные о компании успешно обновлены',
      editCompanyError: 'Произошла ошибка при обновлении компании',
      passwordUpdated: 'Пароль успешно обновлен',
      errorUpdatingPassword: 'Произошла ошибка при обновлении пароля',
      employeeInviteSuccess: 'Сотрудник успешно приглашён',
      employeeInviteError: 'Произошла ошибка при приглашении сотрудника',
      employeeEditSuccess: 'Данные о сотруднике успешно обновлены',
      employeeEditError: 'Произошла ошибка при обновлении данных о сотруднике',
      employeeBlocked: 'Сотрудник заблокирован',
      employeeUnblocked: 'Сотрудник разблокирован',
      employeeErrorBlocked: 'Произошла ошибка при блокировке сотрудника',
      employeeErrorUnblocked: 'Произошла ошибка при разблокировке сотрудника',
    },
    button: {
      buttonSave: 'Сохранить изменения',
      buttonReset: 'Отменить изменения',
      buttonChange: 'Изменить пароль',
    },
    employee: {
      title: 'Информация о сотрудниках',
      emptyListDescription: 'Пока список сотрудников пуст',
      buttons: {
        addEmployee: 'Пригласить сотрудника',
        add: 'Пригласить',
        save: 'Сохранить изменения',
        block: 'Заблокировать',
        unBlock: 'Разблокировать',
      },
      fields: {
        email: 'Email',
        name: 'Имя',
        surname: 'Фамилия',
        patronymic: 'Отчество',
        fio: 'ФИО',
        phone: 'Номер телефона',
        role: 'Роль',
        status: 'Статус',
      },
      placeholders: {
        role: 'Выберите роль',
      },
      statuses: {
        // authorized: 'Авторизован',
        // invited: 'Приглашение отправлено',
        // blocked: 'Заблокирован',
        [CompanyEmployeeStatus.authorized]: 'Авторизован',
        [CompanyEmployeeStatus.invited]: 'Приглашение отправлено',
        [CompanyEmployeeStatus.blocked]: 'Заблокирован',
      },
      handlingModal: {
        createTitle: 'Приглашение сотрудника',
        editTitle: 'Редактирование данных о сотруднике',
      },
      blockingModal: {
        titleBlock: 'Подтвердите блокировку пользователя',
        descriptionBlock: 'Вы точно хотите заблокировать пользователя? Пользователь не сможет войти в систему',
      },
    },
  },
  zh: {
    title: '个人资料',
    menu: {
      me: '我的个人资料',
      company: '公司',
      notifications: '通知',
    },
    // profileEditTitle: 'Данные',
    passwordFields: {
      title: '修改密码',
      currentPassword: '当前密码',
      newPassword: '新密码',
      repeatPassword: '重复密码',
      // currentPasswordPlaceholder: 'Введите текущий пароль',
      // newPasswordPlaceholder: 'Введите новый пароль',
      // repeatPasswordPlaceholder: 'Введите новый пароль повторно',
    },
    errors: {
      invalidCurrentPassword: '当前密码不正确',
    },
    messages: {
      errorUnknown: '发生未知错误',
      editProfileSuccess: '个人资料已成功更新',
      editProfileError: '更新您的个人资料时出错',
      editCompanySuccess: '公司信息更新成功',
      editCompanyError: '更新公司时出错',
      passwordUpdated: '密码更新成功',
      errorUpdatingPassword: '更新密码时出错',
      // employeeInviteSuccess: 'Сотрудник успешно приглашён',
      // emploInviteyeeError: 'Произошла ошибка при приглашении сотрудника',
      // employeeBlocked: 'Сотрудник заблокирован',
      // employeeUnblocked: 'Сотрудник разблокирован',
      // employeeErrorBlocked: 'Произошла ошибка при блокировке сотрудника',
      // employeeErrorUnblocked: 'Произошла ошибка при разблокировке сотрудника',
      // employeeEditSuccess: 'Данные о сотруднике успешно обновлены',
      // employeeEditError: 'Произошла ошибка при обновлении данных о сотруднике',
    },
    button: {
      buttonSave: '保存更改',
      buttonReset: '取消更改',
      buttonChange: '取消更改',
    },
    employee: {
      // title: 'Информация о сотрудниках',
      // emptyListDescription: 'Пока список сотрудников пуст',
      buttons: {
        // addEmployee: 'Пригласить сотрудника',
        // add: 'Пригласить',
        save: '保存更改',
        // block: 'Заблокировать',
        // unBlock: 'Разблокировать',
      },
      fields: {
        email: '电子邮箱',
        // name: 'Имя',
        // surname: 'Фамилия',
        // patronymic: 'Отчество',
        fio: '姓名',
        phone: '电话号码',
        // role: 'Роль',
        status: '状态',
      },
      placeholders: {
        // role: 'Выберите роль',
      },
      statuses: {
        // authorized: 'Авторизован',
        // invited: 'Приглашение отправлено',
        // blocked: 'Заблокирован',
        [CompanyEmployeeStatus.authorized]: 'Авторизован',
        [CompanyEmployeeStatus.invited]: 'Приглашение отправлено',
        [CompanyEmployeeStatus.blocked]: 'Заблокирован',
      },
      handlingModal: {
        // createTitle: 'Приглашение сотрудника',
        // editTitle: 'Редактирование данных о сотруднике',
      },
      blockingModal: {
        // titleBlock: 'Подтвердите блокировку пользователя',
        // descriptionBlock: 'Вы точно хотите заблокировать пользователя? Пользователь не сможет войти в систему',
      },
    },
  },
};
