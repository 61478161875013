import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { routerUrls } from '@/shared/config/routes';
import { Loader } from '@/shared/ui';
import { getPathname } from '@/shared/utils';

import { defaultRoleRoute, roleRouteMap } from '../routes';
import { useRootStore } from '../store';

type WithAuthProps<P> = {
  Component: React.ComponentType<P>;
  needAuth?: boolean;
};

export const withAuthCheck = <P extends Record<string, unknown>>({ Component, needAuth = true }: WithAuthProps<P>) =>
  observer((props: P) => {
    const { userStore, routerStore, centrifugeStore } = useRootStore();
    const userRole = userStore.userRole;
    const location = useLocation();
    const rootPathname = getPathname(location);

    React.useEffect(() => {
      if (!userStore.authorized) {
        centrifugeStore.disconnect();
      }
    }, [userStore.authorized]);

    const redirectUrl = useMemo(() => {
      // если мы в процессе загрузки, то не надо редиректить
      if (userStore.userLoadingStage.isLoading || userStore.userLoadingStage.isNotStarted) {
        return null;
      }

      if (userStore.authorized && userRole) {
        const defaultAuthorizedPage = defaultRoleRoute[userRole];

        if (needAuth) {
          // если пользователь с этой ролью не может видеть эту страницу, то редиректим на дефолтную страницу для его роли
          if (!roleRouteMap[userRole].includes(rootPathname)) {
            return defaultAuthorizedPage;
          }

          return null;
        }

        // если авторизованы и страница отображается только для неавторизованных, то редиректим на дефолтную страницу для его роли
        return defaultAuthorizedPage;
      }

      // если не авторизованы, но странице нужна авторизация, то кидаем на логин
      if (needAuth) {
        return routerUrls.login.mask;
      }

      return null;
    }, [userStore.userLoadingStage.isLoading, userRole, userStore.authorized, userStore.userLoadingStage.isNotStarted]);

    React.useEffect(() => {
      if (redirectUrl) {
        routerStore.navigate(redirectUrl);
      }
    }, [redirectUrl]);

    if (userStore.userLoadingStage.isLoading || redirectUrl) {
      return <Loader />;
    }

    return <Component {...props} />;
  });
