import { UserRole } from '@/entities/user';

import { DictionaryField, DictionaryFielsdArgs, DictionaryPage, ProductType } from '../../types';

export const defaultParams = {
  limit: 10000,
  offset: 0,
};

type PageDictionariesMap = {
  fields: Record<UserRole, Array<DictionaryField>>;
  args?: Record<UserRole, DictionaryFielsdArgs>;
};

const tradesPageDictionaries: PageDictionariesMap = {
  fields: {
    [UserRole.ownerAdmin]: [
      DictionaryField.customers,
      DictionaryField.suppliers,
      DictionaryField.productTypes,
      DictionaryField.products,
    ],
    [UserRole.ownerManager]: [
      DictionaryField.customers,
      DictionaryField.suppliers,
      DictionaryField.productTypes,
      DictionaryField.products,
    ],
    [UserRole.ownerObserver]: [
      DictionaryField.customers,
      DictionaryField.suppliers,
      DictionaryField.productTypes,
      DictionaryField.products,
    ],
    [UserRole.clientAdmin]: [DictionaryField.currencies, DictionaryField.productTypes, DictionaryField.products],
    [UserRole.clientEmployee]: [DictionaryField.currencies, DictionaryField.productTypes, DictionaryField.products],
    [UserRole.clientObserver]: [DictionaryField.currencies, DictionaryField.productTypes, DictionaryField.products],
  },
  args: {
    [UserRole.ownerAdmin]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.ownerManager]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.ownerObserver]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.clientAdmin]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.clientEmployee]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.clientObserver]: { [DictionaryField.products]: [ProductType.metal] },
  },
};

const tradePageDictionaries: PageDictionariesMap = {
  fields: {
    [UserRole.ownerAdmin]: [
      DictionaryField.customers,
      DictionaryField.suppliers,
      DictionaryField.countries,
      DictionaryField.currencies,
      DictionaryField.legalCompanies,
      DictionaryField.logisticCompanies,
      DictionaryField.productTypes,
      DictionaryField.products,
      DictionaryField.signatories,
    ],
    [UserRole.ownerManager]: [
      DictionaryField.customers,
      DictionaryField.suppliers,
      DictionaryField.countries,
      DictionaryField.currencies,
      DictionaryField.legalCompanies,
      DictionaryField.logisticCompanies,
      DictionaryField.productTypes,
      DictionaryField.products,
      DictionaryField.signatories,
    ],
    [UserRole.ownerObserver]: [
      DictionaryField.customers,
      DictionaryField.suppliers,
      DictionaryField.countries,
      DictionaryField.currencies,
      DictionaryField.legalCompanies,
      DictionaryField.logisticCompanies,
      DictionaryField.productTypes,
      DictionaryField.products,
      DictionaryField.signatories,
    ],
    [UserRole.clientAdmin]: [
      DictionaryField.countries,
      DictionaryField.currencies,
      DictionaryField.productTypes,
      DictionaryField.products,
    ],
    [UserRole.clientEmployee]: [
      DictionaryField.countries,
      DictionaryField.currencies,
      DictionaryField.productTypes,
      DictionaryField.products,
    ],
    [UserRole.clientObserver]: [
      DictionaryField.countries,
      DictionaryField.currencies,
      DictionaryField.productTypes,
      DictionaryField.products,
    ],
  },
  args: {
    [UserRole.ownerAdmin]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.ownerManager]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.ownerObserver]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.clientAdmin]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.clientEmployee]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.clientObserver]: { [DictionaryField.products]: [ProductType.metal] },
  },
};

const profilePageDictionaries: PageDictionariesMap = {
  fields: {
    [UserRole.ownerAdmin]: [],
    [UserRole.ownerManager]: [],
    [UserRole.ownerObserver]: [],
    [UserRole.clientAdmin]: [DictionaryField.countries],
    [UserRole.clientEmployee]: [],
    [UserRole.clientObserver]: [],
  },
};

const bidsPageDictionaries: PageDictionariesMap = {
  fields: {
    [UserRole.ownerAdmin]: [
      DictionaryField.productTypes,
      DictionaryField.countries,
      DictionaryField.currencies,
      DictionaryField.products,
      DictionaryField.customers,
      DictionaryField.suppliers,
    ],
    [UserRole.ownerManager]: [
      DictionaryField.productTypes,
      DictionaryField.countries,
      DictionaryField.currencies,
      DictionaryField.products,
      DictionaryField.customers,
      DictionaryField.suppliers,
    ],
    [UserRole.ownerObserver]: [
      DictionaryField.productTypes,
      DictionaryField.countries,
      DictionaryField.currencies,
      DictionaryField.products,
    ],
    [UserRole.clientAdmin]: [DictionaryField.productTypes, DictionaryField.currencies, DictionaryField.products],
    [UserRole.clientEmployee]: [DictionaryField.productTypes, DictionaryField.currencies, DictionaryField.products],
    [UserRole.clientObserver]: [DictionaryField.productTypes, DictionaryField.currencies, DictionaryField.products],
  },
  args: {
    [UserRole.ownerAdmin]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.ownerManager]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.ownerObserver]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.clientAdmin]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.clientEmployee]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.clientObserver]: { [DictionaryField.products]: [ProductType.metal] },
  },
};

const documentsPageDictionaries: PageDictionariesMap = {
  fields: {
    [UserRole.ownerAdmin]: [DictionaryField.productTypes],
    [UserRole.ownerManager]: [DictionaryField.productTypes],
    [UserRole.ownerObserver]: [DictionaryField.productTypes],
    [UserRole.clientAdmin]: [DictionaryField.productTypes],
    [UserRole.clientEmployee]: [DictionaryField.productTypes],
    [UserRole.clientObserver]: [DictionaryField.productTypes],
  },
};

const chatsPageDictionaries: PageDictionariesMap = {
  fields: {
    [UserRole.ownerAdmin]: [
      DictionaryField.countries,
      DictionaryField.currencies,
      DictionaryField.logisticCompanies,
      DictionaryField.productTypes,
      DictionaryField.signatories,
      DictionaryField.products,
    ],
    [UserRole.ownerManager]: [
      DictionaryField.countries,
      DictionaryField.currencies,
      DictionaryField.logisticCompanies,
      DictionaryField.productTypes,
      DictionaryField.signatories,
      DictionaryField.products,
    ],
    [UserRole.ownerObserver]: [],
    [UserRole.clientAdmin]: [
      DictionaryField.countries,
      DictionaryField.currencies,
      DictionaryField.logisticCompanies,
      DictionaryField.productTypes,
      DictionaryField.signatories,
      DictionaryField.products,
    ],
    [UserRole.clientEmployee]: [
      DictionaryField.countries,
      DictionaryField.currencies,
      DictionaryField.logisticCompanies,
      DictionaryField.productTypes,
      DictionaryField.signatories,
      DictionaryField.products,
    ],
    [UserRole.clientObserver]: [],
  },
  args: {
    [UserRole.ownerAdmin]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.ownerManager]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.ownerObserver]: {},
    [UserRole.clientAdmin]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.clientEmployee]: { [DictionaryField.products]: [ProductType.metal] },
    [UserRole.clientObserver]: {},
  },
};

export const mapPageToDictionaries: Record<DictionaryPage, PageDictionariesMap> = {
  [DictionaryPage.trade]: tradePageDictionaries,
  [DictionaryPage.trades]: tradesPageDictionaries,
  [DictionaryPage.profile]: profilePageDictionaries,
  [DictionaryPage.bids]: bidsPageDictionaries,
  [DictionaryPage.documents]: documentsPageDictionaries,
  [DictionaryPage.chats]: chatsPageDictionaries,
};
