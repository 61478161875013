import { CheckCircleFilled } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { StepDescription } from '@/shared/ui';
import { ApproveButton } from '@/shared/ui/form/buttons';

import { BaseDateFieldsModel } from '../../model/fieldsModel/BaseDateFieldsModel';
import { StageModel, StepComponentProps } from '../../types';

const BaseDateStep = <Model extends StageModel>({ model, fieldKey, description }: StepComponentProps<Model>) => {
  const fields = fieldKey && model[fieldKey];

  if (!(fields instanceof BaseDateFieldsModel)) {
    return null;
  }

  return (
    <StepDescription description={description}>
      <DatePicker
        style={{ width: '50%' }}
        {...fields.date.props}
        suffixIcon={!fields.approveDisabled && <CheckCircleFilled />}
        disabled={fields.isDisabled || fields.isLoading}
      />
      <ApproveButton
        loading={fields.isLoading}
        disabled={fields.isDisabled || fields.approveDisabled}
        onClick={fields.approveStep}
      />
    </StepDescription>
  );
};

export default observer(BaseDateStep);
