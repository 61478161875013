import { Select, SelectProps } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks';
import { TranslationNode, TranslationString } from '@/shared/types/localization';
import { ID } from '@/shared/types/meta';
import { isNullable, isTranslationString } from '@/shared/types/typesGuard';
import { Options } from '@/shared/types/values';
import { renderTranslationString } from '@/shared/utils';

import { TransComp } from '../../TransComp';

import { filterOption, filterSort } from './utils';

import s from './CustomSelect.module.scss';

export type CustomSelectProps<Value> = Omit<
  SelectProps<Value>,
  'placeholder' | 'options' | 'filterOption' | 'filterSort'
> & {
  placeholder?: TranslationString;
  options?: Options<Value, TranslationNode>;
};

const CustomSelect = <Value extends ID>({
  className,
  placeholder = '',
  options = [],
  value,
  ...props
}: CustomSelectProps<Value>) => {
  const { t } = useTypedTranslation();

  const optionsList = React.useMemo(() => {
    return options.map((option) => {
      const label =
        isNullable(option.labelNode) && isTranslationString(option.label) ? (
          renderTranslationString(option.label, t)
        ) : (
          <TransComp>{option.labelNode ?? option.label}</TransComp>
        );

      return {
        ...option,
        label,
      };
    });
  }, [options, t]);

  return (
    <Select
      {...props}
      className={cn(s.select, className)}
      value={options.length ? value : null}
      placeholder={<TransComp>{placeholder}</TransComp>}
      options={optionsList}
      filterOption={filterOption}
      filterSort={filterSort}
    />
  );
};

export default React.memo(CustomSelect) as typeof CustomSelect;
