import cn from 'classnames';
import * as React from 'react';

import { TypographyTextWithTooltip } from '@/shared/ui';

import { mapEmployeeStatusToLabel } from '../../config';
import { CompanyEmployeeStatus } from '../../types';

import s from './EmployeeStatusView.module.scss';

type EmployeeStatusProps = {
  status: CompanyEmployeeStatus;
  className?: string;
};

const EmployeeStatusView = ({ status, className }: EmployeeStatusProps): React.ReactElement<EmployeeStatusProps> => {
  return (
    <TypographyTextWithTooltip
      className={cn(s['client-status'], s[`client-status_${status}`], className)}
      text={mapEmployeeStatusToLabel(status)}
    />
  );
};

export default EmployeeStatusView;
