import { BaseResponse } from '@kts-front/types';

import { CompanyListResponse, CompanyType } from '@/entities/company';
import { UserRole } from '@/entities/user';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, ValueModel } from '@/shared/model';

import {
  DictionaryField,
  DictionaryFielsdArgs,
  DictionaryPage,
  IDictionariesStore,
  isDictionaryFieldNeedArgs,
} from '../../types';
import { CompanyDictionaryModel } from '../CompanyDictionaryModel';

import { CompaniesStore } from './CompaniesStore';
import { CountriesStore } from './CountriesStore';
import { CurrenciesStore } from './CurrenciesStore';
import { LegalCompaniesStore } from './LegalCompaniesStore';
import { LogisticCompaniesStore } from './LogisticCompaniesStore';
import { ProductTypesStore } from './ProductTypesStore';
import { ProductsStore } from './ProductsStore';
import { SignatoriesStore } from './SignatoriesStore';
import { defaultParams, mapPageToDictionaries } from './config';

export class DictionariesStore implements IDictionariesStore {
  readonly legalCompanies = new LegalCompaniesStore();
  readonly logisticCompanies = new LogisticCompaniesStore();
  readonly currencies = new CurrenciesStore();
  readonly productTypes = new ProductTypesStore();
  readonly products = new ProductsStore();
  readonly countries = new CountriesStore();
  readonly signatories = new SignatoriesStore();
  readonly customers: CompaniesStore;
  readonly suppliers: CompaniesStore;

  readonly loadingStage = new LoadingStageModel();

  private readonly _loadingClientsStage = new LoadingStageModel();

  private readonly _initialized = new ValueModel<boolean>(false);

  private readonly _companyListRequest = apiStore.createRequest<CompanyListResponse>({
    url: apiUrls.company.list,
  });

  constructor() {
    this.customers = new CompaniesStore(this);
    this.suppliers = new CompaniesStore(this);
  }

  get isInitialLoading(): boolean {
    return !this._initialized.value && this.loadingStage.isLoading;
  }

  loadCompanies = async (): Promise<BaseResponse> => {
    if (this._loadingClientsStage.isLoading) {
      return { isError: false };
    }

    this._loadingClientsStage.loading();

    const response = await this._companyListRequest.call({
      params: defaultParams,
    });

    if (response.isError) {
      this._loadingClientsStage.error();

      return { isError: true };
    }

    this.customers.list.reset();
    this.suppliers.list.reset();

    response.data.results.forEach((company) => {
      if (company.type === CompanyType.customer) {
        this.customers.list.addEntity({ entity: CompanyDictionaryModel.fromJson(company), key: company.id });
      } else if (company.type === CompanyType.supplier) {
        this.suppliers.list.addEntity({ entity: CompanyDictionaryModel.fromJson(company), key: company.id });
      }
    });

    this._loadingClientsStage.success();

    return { isError: false };
  };

  async loadDictionaries(fields: DictionaryField[], fieldsArgs: DictionaryFielsdArgs = {}): Promise<BaseResponse> {
    if (this.loadingStage.isLoading) {
      return { isError: true };
    }

    this.loadingStage.loading();

    const responses = await Promise.all(
      fields.map((field) => {
        if (isDictionaryFieldNeedArgs(field)) {
          const args = fieldsArgs[field];

          if (!args) {
            return Promise.resolve({ isError: true });
          }

          return this[field].load(...args);
        }

        return this[field].load();
      }),
    );

    if (responses.some((response) => response.isError)) {
      this.loadingStage.error();

      return { isError: true };
    }

    this._initialized.change(true);

    this.loadingStage.success();

    return { isError: false };
  }

  async loadDictionariesByPage(page: DictionaryPage, userRole: UserRole): Promise<BaseResponse> {
    const pageDictionaries = mapPageToDictionaries[page];
    const fields = pageDictionaries.fields[userRole];
    const fieldsArgs = pageDictionaries.args?.[userRole];

    return this.loadDictionaries(fields, fieldsArgs);
  }
}
