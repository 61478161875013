import { UserFieldsModel, UserFieldsModelHandlingType } from '@/entities/user';
import { SelectModel } from '@/shared/model/form/SelectModel';
import { isNullable } from '@/shared/types/typesGuard';
import { Nullable } from '@/shared/types/values';
import { emptyValueValidator } from '@/shared/utils/validators';

import { mapCompanyEmployeeRoleToServer } from '../config';
import { CompanyEmployeeStatus, CreateEmployeePayload, EditEmployeePayload } from '../types';
import { CompanyEmployeeRole, IEmployee } from '../types/client';

type Params = {
  handlingType: UserFieldsModelHandlingType;
  data: Nullable<IEmployee>;
};

const T_OPTIONS = { ns: 'profile' } as const;

export class EmployeeFieldsModel extends UserFieldsModel {
  readonly role: SelectModel<CompanyEmployeeRole>;
  readonly status: Nullable<CompanyEmployeeStatus>;

  constructor(params: Params) {
    super(params);

    this.role = new SelectModel<CompanyEmployeeRole>({
      initialValue: params.data?.role ?? null,
      label: (t) => t('employee.fields.role', T_OPTIONS),
      placeholder: (t) => t('employee.placeholders.role', T_OPTIONS),
      validators: [emptyValueValidator()],
      required: true,
    });

    this.status = params.data?.status ?? null;

    this.fields = [this.email, this.phone, this.name, this.surname, this.patronymic, this.role];
  }

  protected _toCreateJson(): Nullable<CreateEmployeePayload> {
    const createJson = super._toCreateJson();
    const role = this.role.value && mapCompanyEmployeeRoleToServer[this.role.value];

    if (isNullable(createJson) || isNullable(role)) {
      return null;
    }

    return {
      ...createJson,
      role,
    };
  }

  protected _toEditJson(): Nullable<EditEmployeePayload> {
    const editPayload = super._toEditJson();
    const role = this.role.value && mapCompanyEmployeeRoleToServer[this.role.value];

    if (isNullable(editPayload) || isNullable(role)) {
      return null;
    }

    return {
      ...editPayload,
      role,
    };
  }

  static fromDefaultParams(): EmployeeFieldsModel {
    return new EmployeeFieldsModel({
      handlingType: UserFieldsModelHandlingType.create,
      data: null,
    });
  }

  static fromData(params: Omit<Params, 'handlingType'>): EmployeeFieldsModel {
    return new EmployeeFieldsModel({ ...params, handlingType: UserFieldsModelHandlingType.edit });
  }
}
