import { ApiErrorCode } from '@/shared/types/api';

export const login = {
  en: {
    title: 'Login',
    form: {
      email: 'Email',
      password: 'Password',
      rememberMe: 'Remember me',
      forgotPassword: 'Forgot password?',
      submit: 'Submit',
    },
    errors: {
      [ApiErrorCode.authenticationFailed]: 'Wrong login or password',
      [ApiErrorCode.userBlocked]: 'Authorization error',
      [ApiErrorCode.tokenNotValid]: 'Error of authorization data. Please, update the page and log in again',
      [ApiErrorCode.emailAlreadyExists]: 'Email already exists',
    },
  },
  ru: {
    title: 'Вход',
    form: {
      email: 'Email',
      password: 'Пароль',
      rememberMe: 'Запомнить меня',
      forgotPassword: 'Забыли пароль?',
      submit: 'Войти',
    },
    errors: {
      [ApiErrorCode.authenticationFailed]: 'Неверный логин или пароль',
      [ApiErrorCode.userBlocked]: 'Ошибка авторизации',
      [ApiErrorCode.tokenNotValid]: 'Ошибка авторизационных данных. Пожалуйста, обновите страницу и войдите снова',
      [ApiErrorCode.emailAlreadyExists]: 'Электронная почта уже существует',
    },
  },
  zh: {
    title: '登录',
    form: {
      email: '电子邮箱',
      password: '密码',
      rememberMe: '记住我',
      forgotPassword: '忘了密码？',
      submit: '登录',
    },
    errors: {
      [ApiErrorCode.authenticationFailed]: '登录名或密码不正确',
      // [ApiErrorCode.userBlocked]: 'Ошибка авторизации',
      [ApiErrorCode.tokenNotValid]: '登录数据错误。请刷新页面并重新登录',
      [ApiErrorCode.emailAlreadyExists]: '电子邮件已存在',
    },
  },
};
