import { autorun, computed, makeObservable, reaction } from 'mobx';

import { IRootStore } from '@/app/store';
import {
  IDictionariesStore,
  IDictionary,
  IUnitDictionary,
  ProductMetalType,
  ProductType,
  ProductTypeDictionaryModel,
} from '@/entities/dictionary';
import { ICreationStage } from '@/entities/stage';
import { CreateTradePayload, ITradeWorkflowStore } from '@/entities/trade';
import { LoadingStageModel, LocalStore } from '@/shared/model';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { SelectModel } from '@/shared/model/form/SelectModel';
import { UploadFilesModel } from '@/shared/model/form/UploadFilesModel';
import { isNullable, stringIsNumber } from '@/shared/types/typesGuard';
import { Nullable } from '@/shared/types/values';
import { formatDate, getChemicalWeight, getLigatureWeight, numberParser } from '@/shared/utils';
import { FieldError, emptyValueValidator, numberValidator, stringLengthValidator } from '@/shared/utils/validators';

import { CompanyEmployeeFieldModel } from './CompanyEmployeeFieldModel';

type Params = {
  data: ICreationStage;
  rootStore: IRootStore;
  tradeWorkflowStore?: Nullable<ITradeWorkflowStore>;
};

const T_OPTIONS = { ns: 'trade' } as const;

export class CreateTradeModel extends LocalStore {
  readonly supplierCompany: SelectModel<number>;
  readonly customerCompany: SelectModel<number>;
  readonly supplierEmployee: CompanyEmployeeFieldModel;
  readonly customerEmployee: CompanyEmployeeFieldModel;
  readonly legalCompanyFrom: SelectModel<number>;
  readonly legalCompanyTo: SelectModel<number>;
  readonly productTypeId: SelectModel<number>;
  readonly productId: SelectModel<number>;
  readonly chemicalWeight: InputModel;
  readonly ligatureWeight: InputModel;
  readonly weightUnit: SelectModel<number>;
  readonly fixingDate: DatePickerModel;
  readonly sellPrice: InputModel;
  readonly sellPriceUnit: SelectModel<number>;
  readonly sellPriceTotal: InputModel;
  readonly buyPrice: InputModel;
  readonly buyPriceUnit: SelectModel<number>;
  readonly buyPriceTotal: InputModel;
  readonly supplierSpec: UploadFilesModel;

  readonly requestStage = new LoadingStageModel();

  protected readonly _tradeWorkflowStore: Nullable<ITradeWorkflowStore> = null;

  protected readonly _rootStore: IRootStore;

  protected readonly formFields: (keyof ICreationStage)[] = [
    'supplierCompany',
    'customerCompany',
    'supplierEmployee',
    'customerEmployee',
    'legalCompanyFrom',
    'legalCompanyTo',
    'productTypeId',
    'productId',
    'chemicalWeight',
    'weightUnit',
    'fixingDate',
    'buyPrice',
    'buyPriceUnit',
    'sellPrice',
    'sellPriceUnit',
    'buyPriceTotal',
    'sellPriceTotal',
    'supplierSpec',
  ];

  constructor({ data, rootStore, tradeWorkflowStore = null }: Params) {
    super();

    this._rootStore = rootStore;

    this.supplierCompany = new SelectModel<number>({
      initialValue: data.supplierCompany,
      label: (t) => t('createTrade.fields.supplier', T_OPTIONS),
      placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
      required: true,
      validators: [emptyValueValidator()],
      ignoreOnBlurValidation: true,
    });
    this.customerCompany = new SelectModel<number>({
      initialValue: data.customerCompany,
      label: (t) => t('createTrade.fields.customer', T_OPTIONS),
      placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
      required: true,
      validators: [emptyValueValidator()],
      ignoreOnBlurValidation: true,
    });
    this.supplierEmployee = new CompanyEmployeeFieldModel({
      params: {
        initialValue: data.supplierEmployee,
        label: (t) => t('createTrade.fields.supplierEmployee', T_OPTIONS),
      },
      rootStore,
    });
    this.customerEmployee = new CompanyEmployeeFieldModel({
      params: {
        initialValue: data.customerEmployee,
        label: (t) => t('createTrade.fields.customerEmployee', T_OPTIONS),
      },
      rootStore,
    });
    this.legalCompanyFrom = new SelectModel<number>({
      initialValue: data.legalCompanyFrom,
      label: (t) => t('createTrade.fields.legalCompanyFrom', T_OPTIONS),
      placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
      required: true,
      validators: [emptyValueValidator()],
      ignoreOnBlurValidation: true,
    });
    this.legalCompanyTo = new SelectModel<number>({
      initialValue: data.legalCompanyTo,
      label: (t) => t('createTrade.fields.legalCompanyTo', T_OPTIONS),
      placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
      validators: [emptyValueValidator()],
      ignoreOnBlurValidation: true,
      required: true,
    });
    this.productTypeId = new SelectModel<number>({
      initialValue: data.productTypeId,
      label: (t) => t('createTrade.fields.productType', T_OPTIONS),
      placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
      validators: [emptyValueValidator()],
      required: true,
    });
    this.productId = new SelectModel<number>({
      initialValue: data.productId,
      placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
      validators: [emptyValueValidator()],
      required: true,
    });
    this.chemicalWeight = new InputModel({
      initialValue: data.chemicalWeight,
      label: (t) => t('createTrade.fields.chemicalWeight', T_OPTIONS),
      placeholder: (t) => t('createTrade.fields.chemicalWeight', T_OPTIONS),
      parser: numberParser({ maxFractionDigits: 3 }),
      validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
      required: true,
    });
    this.ligatureWeight = new InputModel({
      initialValue: data.ligatureWeight,
      label: (t) => t('createTrade.fields.ligatureWeight', T_OPTIONS),
      placeholder: (t) => t('createTrade.fields.ligatureWeight', T_OPTIONS),
      parser: numberParser({ maxFractionDigits: 3 }),
      validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
      required: true,
    });
    this.weightUnit = new SelectModel<number>({
      initialValue: data.weightUnit,
      validators: [emptyValueValidator(FieldError.weightUnit)],
      ignoreOnBlurValidation: true,
      required: true,
    });
    this.fixingDate = new DatePickerModel({
      initialValue: data.fixingDate,
      label: (t) => t('createTrade.fields.fixingDate', T_OPTIONS),
      placeholder: (t) => t('placeholders.date', { ns: 'shared' }),
      validators: [emptyValueValidator()],
      required: true,
    });
    this.sellPrice = new InputModel({
      initialValue: data.sellPrice,
      label: (t) => t('createTrade.fields.sellPrice', T_OPTIONS),
      placeholder: (t) => t('placeholders.price', { ns: 'shared' }),
      parser: numberParser(),
      validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
      ignoreOnBlurValidation: true,
      required: true,
    });
    this.sellPriceUnit = new SelectModel<number>({
      initialValue: data.sellPriceUnit,
      validators: [emptyValueValidator(FieldError.sellPriceUnit)],
      ignoreOnBlurValidation: true,
      required: true,
    });
    this.sellPriceTotal = new InputModel({
      initialValue: data.sellPriceTotal,
      label: (t) => t('createTrade.fields.sellPriceTotal', T_OPTIONS),
      placeholder: (t) => t('placeholders.price', { ns: 'shared' }),
      parser: numberParser(),
      validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
      ignoreOnBlurValidation: true,
      required: true,
    });
    this.buyPrice = new InputModel({
      initialValue: data.buyPrice,
      label: (t) => t('createTrade.fields.buyPrice', T_OPTIONS),
      placeholder: (t) => t('placeholders.price', { ns: 'shared' }),
      parser: numberParser(),
      validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
      ignoreOnBlurValidation: true,
      required: true,
    });
    this.buyPriceUnit = new SelectModel<number>({
      initialValue: data.buyPriceUnit,
      validators: [emptyValueValidator(FieldError.buyPriceUnit)],
      ignoreOnBlurValidation: true,
      required: true,
    });
    this.buyPriceTotal = new InputModel({
      initialValue: data.buyPriceTotal,
      label: (t) => t('createTrade.fields.buyPriceTotal', T_OPTIONS),
      placeholder: (t) => t('placeholders.price', { ns: 'shared' }),
      parser: numberParser(),
      validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
      ignoreOnBlurValidation: true,
      required: true,
    });
    this.supplierSpec = new UploadFilesModel({
      initialValue: data.supplierSpec,
      label: (t) => t('createTrade.fields.supplierSpec', T_OPTIONS),
    });

    this._tradeWorkflowStore = tradeWorkflowStore;

    makeObservable(this, {
      isError: computed,
      isNeedLigatureWeight: computed,
      formDisabled: computed,
      weightUnitOptions: computed,
      productOptions: computed,
    });

    this.addReactions([
      /**
       * Если значений для Валют не было при инициализации,
       * то устанавливаем значения, после получения словарей
       * */
      autorun(() => {
        if (this.dictionaries.loadingStage.isSuccess) {
          const currencyList = this.dictionaries.currencies.list.items;
          const defaultCurrency = currencyList.find((currency) => currency.label === 'RUB') ?? currencyList[0];

          !this.buyPriceUnit.value && this.buyPriceUnit.change(defaultCurrency.value);
          !this.sellPriceUnit.value && this.sellPriceUnit.change(defaultCurrency.value);
        }
      }),
      /**
       * Проверяем ошибку "Валюты покупки" и выставляем ее для "Цены покупки",
       * чтобы отобразить в форме, даже при отсутсвии ошибки в "Цене покупки"
       * */
      reaction(
        () => ({ priceValue: this.buyPrice.value, unitError: this.buyPriceUnit.error }),
        ({ unitError }) => (unitError ? this.buyPrice.changeError(unitError) : this.buyPrice.resetError()),
      ),
      /**
       * Проверяем ошибку "Валюты продажи" и выставляем ее для "Цены продажи",
       * чтобы отобразить в форме, даже при отсутсвии ошибки в "Цене продажи"
       * */
      reaction(
        () => ({ priceValue: this.sellPrice.value, unitError: this.sellPriceUnit.error }),
        ({ unitError }) => (unitError ? this.sellPrice.changeError(unitError) : this.sellPrice.resetError()),
      ),
      /**
       * При изменении "Вид товара" сбрасываем "Единицу измерения" и выставляем ее по умолчанию,
       * также сбрасываем product и меняем его label
       * */
      reaction(
        () => this.productTypeId.value,
        () => {
          const defaultUnit = this.weightUnitOptions.length ? this.weightUnitOptions[0].value : null;
          this.weightUnit.change(defaultUnit);
          this.productId.change(null);
          this.productId.changeLabel(this._productTypeModel?.label ?? '');
        },
      ),
      /**
       * При изменении компании поставщика сбрасываем ответственное лицо поставщика
       * */
      reaction(
        () => this.supplierCompany.value,
        (supplierCompanyId) => {
          const { loadingStage, loadList, change } = this.supplierEmployee;

          if (loadingStage.isNotStarted === false) {
            change(null);
          }

          if (supplierCompanyId) {
            loadList(supplierCompanyId);
          }
        },
        { fireImmediately: true },
      ),
      /**
       * При изменении компании покупателя сбрасываем ответственное лицо покупателя
       * */
      reaction(
        () => this.customerCompany.value,
        (customerCompanyId) => {
          const { loadingStage, loadList, change } = this.customerEmployee;

          if (loadingStage.isNotStarted === false) {
            change(null);
          }

          if (customerCompanyId) {
            loadList(customerCompanyId);
          }
        },
        { fireImmediately: true },
      ),
      /**
       * Обновляем заголовок для продукта, если его нет
       * */
      autorun(() => {
        if (!this.productId.label && this._productTypeModel) {
          this.productId.changeLabel(this._productTypeModel.label);
        }
      }),
      /**
       * Если изменяется химический вес и нужно отобразить лигатурный (this.productOptions !== null),
       * то выставляем лигатурный вес
       * */
      autorun(() => {
        if (this.productOptions && !this.isNeedLigatureWeight) {
          this.ligatureWeight.change(String(getLigatureWeight(this.chemicalWeight.value) || ''));
        }
      }),
      /**
       * Если изменяется лигатурный вес и он обязателен (this.isNeedLigatureWeight === true),
       * то выставляем химический вес
       * */
      autorun(() => {
        if (this.isNeedLigatureWeight) {
          this.chemicalWeight.change(String(getChemicalWeight(this.ligatureWeight.value) || ''));
        }
      }),
    ]);
  }

  private get _productTypeModel(): Nullable<ProductTypeDictionaryModel> {
    return !isNullable(this.productTypeId.value)
      ? this.dictionaries.productTypes.list.entities.get(this.productTypeId.value) ?? null
      : null;
  }

  get dictionaries(): IDictionariesStore {
    return this._rootStore.dictionariesStore;
  }

  get isError(): boolean {
    return this.formFields.some((field) => {
      return this[field].isError;
    });
  }

  get formDisabled(): boolean {
    return this.requestStage.isLoading;
  }

  get isNeedLigatureWeight(): boolean {
    const productType = this._productTypeModel;
    const productId = this.productId.value;

    if (!productType || productType.type !== ProductType.metal || !productId) {
      return false;
    }

    const product = productType.productList.entities.get(productId);

    return !product || product.slug !== ProductMetalType.gold;
  }

  get productOptions(): Nullable<Array<IUnitDictionary>> {
    return this._productTypeModel?.productList.length ? this._productTypeModel.productList.items : null;
  }

  get weightUnitOptions(): Array<IDictionary> {
    return this._productTypeModel?.unitList.items ?? [];
  }

  protected readonly scrollToErrorField = () => {
    const errorField = this.formFields.find((field) => this[field].isError);

    if (errorField) {
      this[errorField].scrollToField();
    }
  };

  protected readonly toJson = (): CreateTradePayload => {
    const files = this.supplierSpec.value;
    const file = files.length > 0 && this.supplierSpec.value[0].originFileObj;
    const chemicalWeight = this.chemicalWeight.value;
    const ligatureWeight = this.ligatureWeight.value;

    return {
      ...(file ? { file } : {}),
      ...(this.customerCompany.value ? { customer_company_id: this.customerCompany.value } : {}),
      ...(this.supplierCompany.value ? { supplier_company_id: this.supplierCompany.value } : {}),
      ...(this.supplierEmployee.value ? { supplier_id: this.supplierEmployee.value } : {}),
      ...(this.customerEmployee.value ? { customer_id: this.customerEmployee.value } : {}),
      ...(this.legalCompanyFrom.value ? { legal_company_from_id: this.legalCompanyFrom.value } : {}),
      ...(this.legalCompanyTo.value ? { legal_company_to_id: this.legalCompanyTo.value } : {}),
      ...(this.productTypeId.value ? { product_type_id: this.productTypeId.value } : {}),
      ...(this.productId.value ? { product_id: this.productId.value } : {}),
      ...(!this.isNeedLigatureWeight && chemicalWeight && stringIsNumber(chemicalWeight)
        ? { weight: Number(chemicalWeight) }
        : {}),
      ...(this.isNeedLigatureWeight && ligatureWeight && stringIsNumber(ligatureWeight)
        ? { ligature_weight: Number(ligatureWeight) }
        : {}),
      ...(this.weightUnit.value ? { weight_unit_id: this.weightUnit.value } : {}),
      ...(this.fixingDate.value ? { fixing_date: formatDate(this.fixingDate.value) } : {}),
      ...(this.sellPriceUnit.value ? { sell_price_unit_id: this.sellPriceUnit.value } : {}),
      ...(this.buyPriceUnit.value ? { buy_price_unit_id: this.buyPriceUnit.value } : {}),
      ...(this.sellPrice.value && stringIsNumber(this.sellPrice.value)
        ? { sell_price: Number(this.sellPrice.value) }
        : {}),
      ...(this.buyPrice.value && stringIsNumber(this.buyPrice.value) ? { buy_price: Number(this.buyPrice.value) } : {}),
      ...(this.sellPriceTotal.value && stringIsNumber(this.sellPriceTotal.value)
        ? { sell_price_total: Number(this.sellPriceTotal.value) }
        : {}),
      ...(this.buyPriceTotal.value && stringIsNumber(this.buyPriceTotal.value)
        ? { buy_price_total: Number(this.buyPriceTotal.value) }
        : {}),
    };
  };
}
