import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks';
import { Nullable } from '@/shared/types/values';
import { CustomTag } from '@/shared/ui';

import { BidStatus } from '../../types';

import { mapStatusToConfig } from './config';

type BidStatusTagProps = {
  className?: string;
  status: BidStatus;
  replyCount?: Nullable<number>;
};

const BidStatusTag = ({ className, status, replyCount }: BidStatusTagProps): React.ReactElement<BidStatusTagProps> => {
  const { t } = useTypedTranslation('bids');
  const { color, label } = mapStatusToConfig[status];

  return (
    <CustomTag
      className={className}
      color={color}
      label={replyCount && status === BidStatus.inProgress ? t('replyCount.reply', { count: replyCount }) : label}
    />
  );
};

export default React.memo(BidStatusTag);
