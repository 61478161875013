import { StepProps } from 'antd';
import * as React from 'react';

import { CompanyType } from '@/entities/company';
import { mapCompanyTypeToStageTypes, mapStageTypeToLabel, StageType } from '@/entities/stage';
import { TransComp } from '@/shared/ui';

type Params = {
  companyType: CompanyType;
  currentType: StageType;
  isTradeFinished: boolean;
};

export const getStepItems = ({ currentType, companyType, isTradeFinished }: Params): StepProps[] => {
  const stageTypes = mapCompanyTypeToStageTypes[companyType];
  const currentTypeIndex = stageTypes.findIndex((type) => type === currentType);

  return stageTypes.map<StepProps>((type, typeIndex) => ({
    title: <TransComp>{mapStageTypeToLabel[type]}</TransComp>,
    disabled: typeIndex > currentTypeIndex,
    status: isTradeFinished || typeIndex < currentTypeIndex ? 'finish' : type === currentType ? 'process' : 'wait',
  }));
};
