import { SizeType } from 'antd/lib/config-provider/SizeContext';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import PhoneInput from 'react-phone-number-input';

import { useTypedTranslation } from '@/shared/hooks';
import { InputModel } from '@/shared/model/form/InputModel';
import { renderTranslationString } from '@/shared/utils';

import { CustomFormItem } from '../CustomFormItem';

import s from './FormPhoneInput.module.scss';

type PhoneInputProps = {
  className?: string;
  model: InputModel;
  size?: SizeType;
  disabled?: boolean;
};

const FormPhoneInput: React.FC<PhoneInputProps> = ({ className, model, size = 'small', disabled }) => {
  const { t } = useTypedTranslation();

  return (
    <CustomFormItem className={cn(s.input, className)} ref={model.ref} model={model}>
      <PhoneInput
        className={cn(s.input__input, s[`input_${size}`], model.isError && s.input_error)}
        {...model.props}
        onChange={model.change}
        disabled={model.props.disabled || disabled}
        placeholder={renderTranslationString(model.placeholder, t)}
      />
    </CustomFormItem>
  );
};

export default observer(FormPhoneInput);
