import { PriceProvider } from '@/entities/dictionary';
import { Incoterms } from '@/entities/stage';
import { ApiListResponse } from '@/shared/types/api';
import { Nullable } from '@/shared/types/values';

/**
 * @field new - Новая
 * @field review - На рассмотрении
 * @field published - Опубликована
 * @field in_progress - В работе
 * @field approved - Утверждена
 * @field rejected - Отклонена
 * @field cancelled - Отменена
 * @field trade_created - Сделка создана
 */
export enum BidStatus {
  new = 'new',
  review = 'review',
  published = 'published',
  inProgress = 'in_progress',
  approved = 'approved',
  rejected = 'rejected',
  cancelled = 'cancelled',
  tradeCreated = 'trade_created',
}

export enum BidFixingType {
  am = 'am',
  pm = 'pm',
  spot = 'spot',
}

export enum BidDifferenceType {
  price = 'price',
  percent = 'percent',
}

export enum BidType {
  buy = 'buy',
  sell = 'sell',
}

export type BidCompanyServer = {
  id: number;
  name: string;
  country_id: number;
};

/** *
 * @field delivery_basis - базис поставки (Incoterms)
 * @field diff_currency_id - ID валюты
 * @field diff_type - Тип дисконта/премии
 * @field diff_value - Значение дисконта/премии
 * @field company - Компания
 */
export type BidClientServer = {
  user_id: number;
  delivery_basis: Nullable<Incoterms>;
  diff_currency_id: Nullable<number>;
  diff_type: Nullable<BidDifferenceType>;
  diff_value: Nullable<number>;
  company: Nullable<BidCompanyServer>;
};

/**
 * @field status - Статус сделки
 * @field type - Тип сделки
 * @field trade_id - ID сделки
 * @field product_id - ID продукта металла
 * @field shape_id - ID товарной формы
 * @field fixing_type - Тип фиксации
 * @field fixing_date - Дата фиксации
 * @field fixing_price - Цена фиксации
 * @field currency_id - ID валюты
 * @field weight - Вес
 * @field weight_unit_id - ID единицы веса
 * @field history - История изменений
 * @field price_provider - Провайдер цены
 * @field reply_count - Количество откликов
 * @field buyer - Покупатель
 * @field seller - Продавец
 * @field manager - Менеджер
 */
export type BidServer = {
  id: number;
  type: BidType;
  status: BidStatus;
  trade_id: Nullable<number>;
  product_id: number;
  price_provider: PriceProvider;
  shape_id: number;
  fixing_type: BidFixingType;
  fixing_date: Nullable<string>;
  fixing_price: Nullable<number>;
  currency_id: number;
  weight: number;
  weight_unit_id: number;
  history: {
    weight: Nullable<number>;
  };
  reply_count: Nullable<number>;
  buyer: Nullable<BidClientServer>;
  seller: Nullable<BidClientServer>;
  manager: Nullable<BidClientServer>;
};

export type BuyBidServer = BidServer;
export type SellBidServer = BidServer;
export type MyBidServer = BidServer;
export type ReplyBidServer = BidServer;

export type BuyBidListResponse = ApiListResponse<BuyBidServer>;
export type SellBidListResponse = ApiListResponse<SellBidServer>;
export type MyBidListResponse = ApiListResponse<MyBidServer>;
export type ReplyBidListResponse = ApiListResponse<ReplyBidServer>;

export type BidReplyServer = {
  id: number;
  status: BidStatus;
  delivery_basis: Incoterms;
  diff_type: BidDifferenceType;
  diff_value: number;
  diff_currency_id: number;
  company: BidCompanyServer;
};

export type BidReplyListResponse = Array<BidReplyServer>;

export type BidHandlingPayload = {
  type: BidType;
  product_id: number;
  price_provider: PriceProvider;
  shape_id: number;
  fixing_type: BidFixingType;
  fixing_date?: string;
  weight?: number;
  ligature_weight?: number;
  weight_unit_id: number;
  currency_id: number;
  delivery_basis: Incoterms;
  diff_type: BidDifferenceType;
  diff_value: number;
};

export type CompanyBidInfoPayload = {
  client_id: number;
  delivery_basis: Incoterms;
  diff_type: BidDifferenceType;
  diff_value: number;
};

export type BidHandlingManagerPayload = Omit<BidHandlingPayload, 'delivery_basis' | 'diff_type' | 'diff_value'> & {
  manager_delivery_basis?: Incoterms;
  manager_diff_type: BidDifferenceType;
  manager_diff_value: number;
  client_company_id?: number;
} & Partial<CompanyBidInfoPayload>;

export type BidEditPayload = {
  weight?: number;
  ligature_weight?: number;
};

export type BidDiscountEditingManagerPayload = {
  delivery_basis?: Incoterms;
  diff_type: BidDifferenceType;
  diff_value: number;
};

export type BidDiscountEditingPayload = Required<BidDiscountEditingManagerPayload>;

export type BidListPayload = {
  limit: number;
  offset: number;
} & Partial<{
  type: Nullable<BidType>;
  fixing_type: Nullable<BidFixingType>;
  status: Nullable<BidStatus>;
  product_id: Nullable<number>;
}>;
