import { InputProps } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { InputModel } from '@/shared/model/form/InputModel';

import { CustomFormItem } from '../CustomFormItem';
import { CustomInput } from '../CustomInput';

type FormInputProps = Pick<
  InputProps,
  'className' | 'size' | 'addonAfter' | 'addonBefore' | 'suffix' | 'prefix' | 'readOnly' | 'disabled'
> & {
  model: InputModel;
};

const FormInput = ({ className, model, disabled, ...props }: FormInputProps): React.ReactElement<FormInputProps> => {
  return (
    <CustomFormItem className={className} ref={model.ref} model={model}>
      <CustomInput {...model.props} {...props} disabled={model.disabled || disabled} />
    </CustomFormItem>
  );
};

export default observer(FormInput);
