import { ApiRequest } from '@kts-front/call-api';
import { UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload';
import { action, makeObservable } from 'mobx';

import { ITradeWorkflowStore, TradeResponse } from '@/entities/trade';
import { apiStore, apiUrls } from '@/shared/api';
import { UploadFileModel } from '@/shared/model';
import { UploadFilesModel, UploadFilesModelParams, UploadFilesModelProps } from '@/shared/model/form/UploadFilesModel';
import { ApiErrorData } from '@/shared/types/api';
import { Nullable } from '@/shared/types/values';

import { DocumentFileType } from '../types';

export enum AsyncAction {
  uploadDocument = 'upload_document',
  deleteDocument = 'delete_document',
  deleteGeneration = 'delete_generation',
}

export type AsyncFilesUploadModelParams<DocType extends DocumentFileType> = UploadFilesModelParams & {
  docType: DocType;
  tradeWorkflowStore: ITradeWorkflowStore;
};

type AsyncFilesUploadModelProps = UploadFilesModelProps & {
  onRemove?: UploadProps['onRemove'];
};

export class AsyncFilesUploadModel<DocType extends DocumentFileType> extends UploadFilesModel {
  protected readonly _docType: DocType;
  protected readonly _tradeWorkflowStore: ITradeWorkflowStore;

  private readonly _uploadRequest: ApiRequest<TradeResponse>;

  constructor({ docType, tradeWorkflowStore, ...params }: AsyncFilesUploadModelParams<DocType>) {
    super(params);
    this._docType = docType;
    this._tradeWorkflowStore = tradeWorkflowStore;

    this._uploadRequest = apiStore.createRequest({
      method: 'POST',
      url: apiUrls.trade.action(tradeWorkflowStore.tradeId),
      multipartFormData: true,
    });

    makeObservable(this, {
      onRemove: action.bound,
    });
  }

  get isUploaded() {
    return this._value.length > 0 && this._value.every((file) => file.status === 'done');
  }

  get props(): AsyncFilesUploadModelProps {
    return {
      fileList: this._value,
      onChange: this.onChange,
      onRemove: this.onRemove,
      required: this.required,
      maxCount: this._maxCount,
      accept: this._accept,
      disabled: this.disabled,
      openFileDialogOnClick: this._value.length < this._maxCount,
    };
  }

  async onChange({ file }: UploadChangeParam): Promise<void> {
    const fileModel = UploadFileModel.fromJson(file);

    /** Добавляем файл в список */
    this.change([...this._value, fileModel]);

    /** Выполняем валидацию */
    for (const validator of this._validators) {
      const error = validator([file]);

      /** Если валидация не прошла, показываем ошибку и ничего не делаем */
      if (error) {
        fileModel.changeError(error);
        fileModel.setErrorStatus();

        return;
      }
    }

    /** Устанавливаем статус загрузки */
    fileModel.setUploadingStatus();

    /** Создаем клон ApiRequest для возможности совершения нескольких параллельных запросов */
    const response = await this._uploadRequest.clone().call({
      data: {
        action: AsyncAction.uploadDocument,
        file,
        doc_type: this._docType,
      },
    });

    if (response.isError) {
      const data: Nullable<ApiErrorData> = response.data?.data ?? null;

      fileModel.changeError(data?.message ?? ((t) => t('messages.uploadDocError', { ns: 'file', count: 1 })));
      fileModel.setErrorStatus();

      return;
    }

    fileModel.setDoneStatus();

    this._tradeWorkflowStore.updateTradeWorkflow(response.data);
  }

  async onRemove(
    fileToDelete: UploadFile,
    action: AsyncAction.deleteDocument | AsyncAction.deleteGeneration = AsyncAction.deleteDocument,
  ): Promise<false> {
    const fileId = fileToDelete.uid;

    if (fileToDelete.status !== 'done') {
      this.change(this._value.filter((file) => file.uid !== fileId));

      return false;
    }

    const fileModel = fileToDelete instanceof UploadFileModel ? fileToDelete : UploadFileModel.fromJson(fileToDelete);

    /** Заменяем файл нашей моделью для возможности отображения статуса */
    this.change(this._value.map((file) => (file.uid === fileId ? fileModel : file)));

    fileModel.setUploadingStatus();

    /** Создаем клон ApiRequest для возможности совершения нескольких параллельных запросов */
    const response = await this._uploadRequest.clone().call({
      data: {
        action,
        id: fileToDelete.uid,
      },
    });

    /** Если произошла ошибка при удалении файла, устанавливаем статус ошибки */
    if (response.isError) {
      const data: Nullable<ApiErrorData> = response.data?.data ?? null;

      fileModel.changeError(data?.message ?? ((t) => t('messages.deleteDocError', { ns: 'file' })));
      /** Возвращаем статус Done, чтобы при следующей попытке удаления процесс пошел через бэкенд */
      fileModel.setDoneStatus();
      /** Без этого ошибка удаления в списке не отображается, зависает на загрузке */
      this.change([...this._value]);
    } else {
      fileModel.setRemoveStatus();
      this.change(this._value.filter((file) => file.uid !== fileId));
    }

    return false;
  }
}
