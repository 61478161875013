import { computed, makeObservable } from 'mobx';

import { DocumentFileType, mapDocTypeToLabel, UploadOrGenerationDocumentModel } from '@/entities/file';
import { DestinationCountryDocsServer, ShipmentType } from '@/entities/stage/types';
import { Nullable } from '@/shared/types/values';

import { AcceptanceCertificateModel } from '../AcceptanceCertificateModel';
import { AdditionalAgreementModel } from '../AdditionalAgreementModel';
import { BaseFieldsModel, BaseFieldsModelParams } from '../BaseFieldsModel';
import { CustomerSpecificationModel } from '../CustomerSpecificationModel';
import { InvoiceModel } from '../InvoiceModel';

import { mapShipmentTypeToRequiredFields } from './config';

type Params<Step, Action> = BaseFieldsModelParams<Step, Action> & {
  shipmentType: ShipmentType;
  exportInvoice: InvoiceModel<DocumentFileType.exportInvoice>;
  customerCommercialInvoice: InvoiceModel<DocumentFileType.customerCommercialInvoice>;
  customerSpecification: CustomerSpecificationModel;
  customerAcceptanceCertificate: AcceptanceCertificateModel;
  customerAdditionalAgreement: AdditionalAgreementModel;
};

export class DestinationCountryDocsFieldsModel<Step, Action> extends BaseFieldsModel<Step, Action> {
  readonly shipmentType: ShipmentType;

  readonly exportInvoice: InvoiceModel<DocumentFileType.exportInvoice>;
  readonly customerCommercialInvoice: InvoiceModel<DocumentFileType.customerCommercialInvoice>;
  readonly customerSpecification: CustomerSpecificationModel;
  readonly customerAcceptanceCertificate: AcceptanceCertificateModel;
  readonly customerAdditionalAgreement: AdditionalAgreementModel;

  constructor({
    shipmentType,
    exportInvoice,
    customerCommercialInvoice,
    customerSpecification,
    customerAcceptanceCertificate,
    customerAdditionalAgreement,
    ...params
  }: Params<Step, Action>) {
    super(params);

    this.shipmentType = shipmentType;
    this.exportInvoice = exportInvoice;
    this.customerCommercialInvoice = customerCommercialInvoice;
    this.customerSpecification = customerSpecification;
    this.customerAcceptanceCertificate = customerAcceptanceCertificate;
    this.customerAdditionalAgreement = customerAdditionalAgreement;

    makeObservable(this, {
      approveDisabled: computed,
    });
  }

  get approveDisabled(): boolean {
    return mapShipmentTypeToRequiredFields[this.shipmentType].some(
      (field) => this[field].isError || !this[field].isUploaded,
    );
  }

  static fromJson<Step, Action>({
    data,
    shipmentType,
    ...params
  }: {
    data: Nullable<DestinationCountryDocsServer>;
    shipmentType: ShipmentType;
  } & BaseFieldsModelParams<Step, Action>): DestinationCountryDocsFieldsModel<Step, Action> {
    return new DestinationCountryDocsFieldsModel<Step, Action>({
      shipmentType,
      exportInvoice: new InvoiceModel(
        UploadOrGenerationDocumentModel.paramsFromJson({
          data: data?.[DocumentFileType.exportInvoice],
          docType: DocumentFileType.exportInvoice,
          tradeWorkflowStore: params.tradeWorkflowStore,
          required: true,
          fileName: mapDocTypeToLabel[DocumentFileType.exportInvoice],
          description: (t) =>
            t('manager.destinationCountryDocs.descriptions.exportInvoice', { ns: 'transportationStage' }),
        }),
      ),
      customerCommercialInvoice: new InvoiceModel(
        UploadOrGenerationDocumentModel.paramsFromJson({
          data: data?.[DocumentFileType.customerCommercialInvoice],
          docType: DocumentFileType.customerCommercialInvoice,
          tradeWorkflowStore: params.tradeWorkflowStore,
          required: true,
          fileName: mapDocTypeToLabel[DocumentFileType.customerCommercialInvoice],
          description: (t) =>
            t('manager.destinationCountryDocs.descriptions.customerCommercialInvoice', { ns: 'transportationStage' }),
        }),
      ),
      customerSpecification: new CustomerSpecificationModel(
        UploadOrGenerationDocumentModel.paramsFromJson({
          data: data?.[DocumentFileType.customerSpecification],
          docType: DocumentFileType.customerSpecification,
          tradeWorkflowStore: params.tradeWorkflowStore,
          required: true,
          fileName: (t) => t('customerSpecification.title', { ns: 'stage' }),
          description: (t) =>
            t('manager.destinationCountryDocs.descriptions.customerSpecification', { ns: 'transportationStage' }),
        }),
      ),
      customerAcceptanceCertificate: new AcceptanceCertificateModel(
        UploadOrGenerationDocumentModel.paramsFromJson({
          data: data?.[DocumentFileType.customerAcceptanceCertificate],
          docType: DocumentFileType.customerAcceptanceCertificate,
          tradeWorkflowStore: params.tradeWorkflowStore,
          required: true,
          fileName: mapDocTypeToLabel[DocumentFileType.customerAcceptanceCertificate],
          description: (t) =>
            t('manager.destinationCountryDocs.descriptions.customerAcceptanceCertificate', {
              ns: 'transportationStage',
            }),
        }),
      ),
      customerAdditionalAgreement: new AdditionalAgreementModel(
        UploadOrGenerationDocumentModel.paramsFromJson({
          data: data?.[DocumentFileType.customerAdditionalAgreement],
          docType: DocumentFileType.customerAdditionalAgreement,
          tradeWorkflowStore: params.tradeWorkflowStore,
          required: true,
          fileName: mapDocTypeToLabel[DocumentFileType.customerAdditionalAgreement],
          description: (t) =>
            t('manager.destinationCountryDocs.descriptions.customerAdditionalAgreement', { ns: 'transportationStage' }),
        }),
      ),
      ...params,
    });
  }
}
