import { CompanyType } from '@/entities/company';
import {
  AwbDirectAction,
  AwbTransitAction,
  ManagerAwbDestinationAction,
  ManagerAwbVerifyingAction,
  ManagerConfirmationStep,
  ManagerConfirmationVerifyingAction,
  ManagerCreationStep,
  ManagerFinalizationStep,
  ManagerPaymentStep,
  ManagerShipmentStep,
  ManagerSpecificationVerifyingAction,
  ManagerSupplierAcceptanceCertificateVerifyingAction,
  ManagerTransactionAction,
  ManagerTransportationStep,
  OptionFieldsAction,
} from '@/entities/stage';
import { TranslationString } from '@/shared/types/localization';
import { Nullable } from '@/shared/types/values';

import { HistoryAction, HistoryActions } from '../types';

const T_OPTIONS = { ns: 'tradeHistory' } as const;

type MapHistoryActionToLabel = Record<HistoryActions, TranslationString>;

const mapOwnerActionToLabel = (tradeId: number): MapHistoryActionToLabel => ({
  [ManagerCreationStep.createTrade]: (t) => t(`manager.${ManagerCreationStep.createTrade}`, { ...T_OPTIONS, tradeId }),

  [ManagerConfirmationStep.deliveryConfirmation]: (t) =>
    t(`manager.${ManagerConfirmationStep.deliveryConfirmation}`, T_OPTIONS),
  [ManagerConfirmationStep.confirmationSigning]: (t) =>
    t(`manager.${ManagerConfirmationStep.confirmationSigning}`, T_OPTIONS),

  [ManagerConfirmationVerifyingAction.approveConfirmationVerifying]: (t) =>
    t(`manager.${ManagerConfirmationVerifyingAction.approveConfirmationVerifying}`, T_OPTIONS),
  [ManagerConfirmationVerifyingAction.rejectConfirmationVerifying]: (t) =>
    t(`manager.${ManagerConfirmationVerifyingAction.rejectConfirmationVerifying}`, T_OPTIONS),

  [ManagerPaymentStep.uploadBill]: (t) => t(`manager.${ManagerPaymentStep.uploadBill}`, T_OPTIONS),
  [ManagerPaymentStep.payBill]: (t) => t(`manager.${ManagerPaymentStep.payBill}`, T_OPTIONS),

  [ManagerShipmentStep.uploadSpec]: (t) => t(`manager.${ManagerShipmentStep.uploadSpec}`, T_OPTIONS),
  [ManagerShipmentStep.shipmentType]: (t) => t(`manager.${ManagerShipmentStep.shipmentType}`, T_OPTIONS),
  [ManagerShipmentStep.cargoShipment]: (t) => t(`manager.${ManagerShipmentStep.cargoShipment}`, T_OPTIONS),
  [ManagerShipmentStep.transitCountry]: (t) => t(`manager.${ManagerShipmentStep.transitCountry}`, T_OPTIONS),
  [AwbTransitAction.uploadFile]: (t) => t(`manager.${AwbTransitAction.uploadFile}`, T_OPTIONS),
  [AwbTransitAction.flightNumber]: (t) => t(`manager.${AwbTransitAction.flightNumber}`, T_OPTIONS),

  [ManagerShipmentStep.destinationCountryDocs]: (t) =>
    t(`manager.${ManagerShipmentStep.destinationCountryDocs}`, T_OPTIONS),
  [AwbDirectAction.uploadFile]: (t) => t(`manager.${AwbDirectAction.uploadFile}`, T_OPTIONS),
  [AwbDirectAction.flightNumber]: (t) => t(`manager.${AwbDirectAction.flightNumber}`, T_OPTIONS),

  [ManagerSpecificationVerifyingAction.approveSpecification]: (t) =>
    t(`manager.${ManagerSpecificationVerifyingAction.approveSpecification}`, T_OPTIONS),
  [ManagerSpecificationVerifyingAction.rejectSpecification]: (t) =>
    t(`manager.${ManagerSpecificationVerifyingAction.rejectSpecification}`, T_OPTIONS),
  [ManagerAwbVerifyingAction.approveAwb]: (t) => t(`manager.${ManagerAwbVerifyingAction.approveAwb}`, T_OPTIONS),
  [ManagerAwbVerifyingAction.rejectAwb]: (t) => t(`manager.${ManagerAwbVerifyingAction.rejectAwb}`, T_OPTIONS),

  [ManagerTransportationStep.transitCountryCargoArrival]: (t) =>
    t(`manager.${ManagerTransportationStep.transitCountryCargoArrival}`, T_OPTIONS),
  [ManagerTransportationStep.cargoArrival]: (t) => t(`manager.${ManagerTransportationStep.cargoArrival}`, T_OPTIONS),
  [ManagerTransportationStep.supplierAcceptanceCertificate]: (t) =>
    t(`manager.${ManagerTransportationStep.supplierAcceptanceCertificate}`, T_OPTIONS),
  [ManagerTransportationStep.supplierSignedAcceptanceCertificate]: (t) =>
    t(`manager.${ManagerTransportationStep.supplierSignedAcceptanceCertificate}`, T_OPTIONS),
  [ManagerTransportationStep.destinationCountryDocs]: (t) =>
    t(`manager.${ManagerTransportationStep.destinationCountryDocs}`, T_OPTIONS),
  [ManagerTransportationStep.transitCountryCargoShipment]: (t) =>
    t(`manager.${ManagerTransportationStep.transitCountryCargoShipment}`, T_OPTIONS),
  [ManagerTransportationStep.destinationCountryCargoArrival]: (t) =>
    t(`manager.${ManagerTransportationStep.destinationCountryCargoArrival}`, T_OPTIONS),

  [ManagerSupplierAcceptanceCertificateVerifyingAction.approveSupplierAcceptanceCertificate]: (t) =>
    t(`manager.${ManagerSupplierAcceptanceCertificateVerifyingAction.approveSupplierAcceptanceCertificate}`, T_OPTIONS),
  [ManagerSupplierAcceptanceCertificateVerifyingAction.rejectSupplierAcceptanceCertificate]: (t) =>
    t(`manager.${ManagerSupplierAcceptanceCertificateVerifyingAction.rejectSupplierAcceptanceCertificate}`, T_OPTIONS),

  [ManagerAwbDestinationAction.uploadFile]: (t) => t(`manager.${ManagerAwbDestinationAction.uploadFile}`, T_OPTIONS),
  [ManagerAwbDestinationAction.flightNumber]: (t) =>
    t(`manager.${ManagerAwbDestinationAction.flightNumber}`, T_OPTIONS),

  [ManagerFinalizationStep.cargoDelivered]: (t) => t(`manager.${ManagerFinalizationStep.cargoDelivered}`, T_OPTIONS),
  [ManagerFinalizationStep.tradeCloseCustomerDocs]: (t) =>
    t(`manager.${ManagerFinalizationStep.tradeCloseCustomerDocs}`, T_OPTIONS),
  [ManagerTransactionAction.addTransaction]: (t) => t(`manager.${ManagerTransactionAction.addTransaction}`, T_OPTIONS),
  [ManagerTransactionAction.deleteTransaction]: (t) =>
    t(`manager.${ManagerTransactionAction.deleteTransaction}`, T_OPTIONS),
  [ManagerTransactionAction.finish]: (t) => t(`manager.${ManagerTransactionAction.finish}`, T_OPTIONS),

  [OptionFieldsAction.deleteSupplierSpec]: (t) => t(`manager.${OptionFieldsAction.deleteSupplierSpec}`, T_OPTIONS),
  [OptionFieldsAction.setDestinationLogisticCompany]: (t) =>
    t(`manager.${OptionFieldsAction.setDestinationLogisticCompany}`, T_OPTIONS),
  [OptionFieldsAction.setTransitLogisticCompany]: (t) =>
    t(`manager.${OptionFieldsAction.setTransitLogisticCompany}`, T_OPTIONS),
  [OptionFieldsAction.setTransitTrackingLink]: (t) =>
    t(`manager.${OptionFieldsAction.setTransitTrackingLink}`, T_OPTIONS),
  [OptionFieldsAction.setDestinationTrackingLink]: (t) =>
    t(`manager.${OptionFieldsAction.setDestinationTrackingLink}`, T_OPTIONS),
  [OptionFieldsAction.uploadFinalizationPhoto]: (t) =>
    t(`manager.${OptionFieldsAction.uploadFinalizationPhoto}`, T_OPTIONS),
  [OptionFieldsAction.uploadAuthorizationLetter]: (t) =>
    t(`manager.${OptionFieldsAction.uploadAuthorizationLetter}`, T_OPTIONS),
  [OptionFieldsAction.uploadIndirectTaxesPaymentCertificate]: (t) =>
    t(`manager.${OptionFieldsAction.uploadIndirectTaxesPaymentCertificate}`, T_OPTIONS),
  [OptionFieldsAction.uploadWarrantyLoa]: (t) => t(`manager.${OptionFieldsAction.uploadWarrantyLoa}`, T_OPTIONS),
  [OptionFieldsAction.setDeliveryTime]: (t) => t(`manager.${OptionFieldsAction.setDeliveryTime}`, T_OPTIONS),

  [HistoryAction.transferToPayment]: (t) => t(`manager.${HistoryAction.transferToPayment}`, T_OPTIONS),
  [HistoryAction.transferToShipment]: (t) => t(`manager.${HistoryAction.transferToShipment}`, T_OPTIONS),
  [HistoryAction.transferToTransportation]: (t) => t(`manager.${HistoryAction.transferToTransportation}`, T_OPTIONS),
  [HistoryAction.transferToFinalization]: (t) => t(`manager.${HistoryAction.transferToFinalization}`, T_OPTIONS),

  [HistoryAction.cargoDirectShipmentConfirm]: (t) =>
    t(`manager.${HistoryAction.cargoDirectShipmentConfirm}`, T_OPTIONS),
  [HistoryAction.cargoTransitShipmentConfirm]: (t) =>
    t(`manager.${HistoryAction.cargoTransitShipmentConfirm}`, T_OPTIONS),
});

const mapSupplierActionToLabel = (tradeId: number): MapHistoryActionToLabel => ({
  ...mapOwnerActionToLabel(tradeId),

  [AwbTransitAction.uploadFile]: (t) => t(`supplier.${AwbTransitAction.uploadFile}`, T_OPTIONS),
  [AwbTransitAction.flightNumber]: (t) => t(`supplier.${AwbTransitAction.flightNumber}`, T_OPTIONS),
  [ManagerTransportationStep.supplierSignedAcceptanceCertificate]: (t) =>
    t(`supplier.${ManagerTransportationStep.supplierSignedAcceptanceCertificate}`, T_OPTIONS),
  [ManagerTransportationStep.transitCountryCargoArrival]: (t) =>
    t(`supplier.${ManagerTransportationStep.transitCountryCargoArrival}`, T_OPTIONS),
  [HistoryAction.cargoTransitShipmentConfirm]: (t) =>
    t(`supplier.${HistoryAction.cargoTransitShipmentConfirm}`, T_OPTIONS),
  [HistoryAction.transferToTransportation]: (t) => t(`supplier.${HistoryAction.transferToTransportation}`, T_OPTIONS),
  [HistoryAction.transferToFinalization]: (t) => t(`supplier.${HistoryAction.transferToFinalization}`, T_OPTIONS),
});

export const mapHistoryActionToLabel = (
  tradeId: number,
  companyType: Nullable<CompanyType>,
): MapHistoryActionToLabel => {
  switch (companyType) {
    case CompanyType.supplier:
      return mapSupplierActionToLabel(tradeId);

    default:
      return mapOwnerActionToLabel(tradeId);
  }
};
