import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { UploadOrGenerationDocument } from '@/entities/file';

import { CustomerSpecificationModel } from '../../model/fieldsModel';

import CustomerSpecificationForm from './CustomerSpecificationForm';

type CustomerSpecificationProps = {
  model: CustomerSpecificationModel;
  disabled?: boolean;
};

const CustomerSpecification: React.FC<CustomerSpecificationProps> = ({ model, disabled }) => {
  const { t } = useTranslation('stage');

  return (
    <UploadOrGenerationDocument
      model={model}
      disabled={disabled}
      tooltip={t('customerSpecification.tooltip')}
      FieldsComponent={CustomerSpecificationForm}
      gridContent={false}
    />
  );
};

export default observer(CustomerSpecification);
