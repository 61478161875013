import { BaseResponse } from '@kts-front/types';
import { action, computed, makeObservable, runInAction } from 'mobx';

import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel } from '@/shared/model';
import { BaseFieldModel, BaseFieldModelParams } from '@/shared/model/form/BaseFieldModel';
import { ApiErrorCode, ApiErrorData } from '@/shared/types/api';
import { TranslationString } from '@/shared/types/localization';
import { ValidatorResult } from '@/shared/types/validator';
import { Nullable } from '@/shared/types/values';
import { emailValidator, uniquenessValidator } from '@/shared/utils/validators';

import {
  ChangeEmailListParams,
  ChangeEmailListResponse,
  ChangeNotificationsSettingsResponse,
  EmailListHandlingMode,
} from '../types';

export type EmailListFieldModelParams = BaseFieldModelParams<Array<string>>;

type EmailListModelProps = {
  emailList: Array<string>;
  placeholder: TranslationString;
  onError: (error: ValidatorResult) => void;
  error: boolean;
  disabled: boolean;
  loading: boolean;
};

const T_OPTIONS = { ns: 'notifications' } as const;

export class EmailListModel extends BaseFieldModel<Array<string>> {
  private readonly _loadingStage = new LoadingStageModel();

  private readonly _emailListHandlingRequest = apiStore.createRequest<ChangeEmailListResponse>();

  constructor(params: EmailListFieldModelParams) {
    super({
      description: (t) => t('settings.emailList.description', T_OPTIONS),
      tooltip: (t) => t('settings.emailList.tooltip', T_OPTIONS),
      required: true,
      ...params,
    });

    makeObservable(this, {
      props: computed,

      changeEmailList: action.bound,
    });
  }

  get props(): EmailListModelProps {
    return {
      emailList: this._value,
      placeholder: this.placeholder,
      onError: this.changeError,
      disabled: this.disabled,
      loading: this._loadingStage.isLoading,
      error: Boolean(this.error),
    };
  }

  async changeEmailList({ email, mode }: ChangeEmailListParams): Promise<ChangeNotificationsSettingsResponse> {
    if (this._loadingStage.isLoading) {
      return { isError: true };
    }

    this._loadingStage.loading();

    const response = mode === EmailListHandlingMode.add ? await this._addEmail(email) : await this._deleteEmail(email);

    if (response.isError) {
      this._loadingStage.error();

      return { isError: true };
    }

    runInAction(() => {
      this.change(response.data.email_backups);
      this._loadingStage.success();
    });

    return {
      isError: false,
      data: { showSuccessMessage: false },
    };
  }

  private readonly _addEmail = async (email: string): Promise<BaseResponse<ChangeEmailListResponse>> => {
    if (this._validate(email)) {
      return { isError: true };
    }

    const response = await this._emailListHandlingRequest.call({
      method: 'POST',
      url: apiUrls.settings.addEmail,
      data: { email },
    });

    if (response.isError) {
      const data: Nullable<ApiErrorData> = response.data?.data ?? null;

      if (data && data.code === ApiErrorCode.emailAlreadyExists) {
        this.changeError((t) => t(`settings.emailList.${ApiErrorCode.emailAlreadyExists}`, T_OPTIONS));
      }

      return { isError: true };
    }

    return response;
  };

  private readonly _deleteEmail = async (email: string): Promise<BaseResponse<ChangeEmailListResponse>> => {
    return await this._emailListHandlingRequest.call({
      method: 'PUT',
      url: apiUrls.settings.deleteEmail,
      data: { email },
    });
  };

  private readonly _validate = (email: string) => {
    const validationError = emailValidator(email) ?? uniquenessValidator(email, this._value);

    if (validationError) {
      this.changeError(validationError);
    }

    return this.isError;
  };
}
