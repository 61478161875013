import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { StepDescription } from '@/shared/ui';
import { FormUpload } from '@/shared/ui/form/FormUpload';
import { ApproveButton } from '@/shared/ui/form/buttons';

import { BaseUploadFilesFieldsModel } from '../../model';
import { StageModel, StepComponentProps } from '../../types';

const VerifiableDocumentStep = <Model extends StageModel>({
  model,
  fieldKey,
  description,
  approveButtonText,
}: StepComponentProps<Model>) => {
  const fields = fieldKey && model[fieldKey];

  if (!(fields instanceof BaseUploadFilesFieldsModel)) {
    return null;
  }

  return (
    <StepDescription description={description}>
      <FormUpload model={fields.files} disabled={fields.isDisabled || fields.isLoading} />
      {!fields.isNeedVerifying && (
        <ApproveButton
          loading={fields.isLoading}
          disabled={fields.isDisabled || fields.approveDisabled || !fields.files.isUploaded}
          onClick={fields.approveStep}
          text={approveButtonText}
        />
      )}
    </StepDescription>
  );
};

export default observer(VerifiableDocumentStep);
