import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { CreateTradeFieldsModel } from '@/entities/stage';
import { CustomSelect } from '@/shared/ui/form/CustomSelect';
import { FormDatePicker } from '@/shared/ui/form/FormDatePicker';
import { FormInput } from '@/shared/ui/form/FormInput';
import { FormSelect } from '@/shared/ui/form/FormSelect';
import { FormUpload } from '@/shared/ui/form/FormUpload';

import { CreateTradeModel } from '../../model';

import SupplierSpecificationModal from './SupplierSpecificationModal';

import s from './CreateTradeForm.module.scss';

type CreateTradeFormProps = {
  className?: string;
  model: CreateTradeFieldsModel | CreateTradeModel;
  disabled?: boolean;
};

const CreateTradeForm: React.FC<CreateTradeFormProps> = ({ className, model, disabled = false }) => {
  const {
    supplierCompany,
    customerCompany,
    supplierEmployee,
    customerEmployee,
    legalCompanyFrom,
    legalCompanyTo,
    productTypeId,
    productId,
    chemicalWeight,
    ligatureWeight,
    weightUnit,
    fixingDate,
    sellPrice,
    sellPriceUnit,
    buyPrice,
    buyPriceUnit,
    supplierSpec,
    sellPriceTotal,
    buyPriceTotal,
    dictionaries,
    productOptions,
    weightUnitOptions,
    isNeedLigatureWeight,
  } = model;

  const { legalCompanies, productTypes, currencies, suppliers, customers } = dictionaries;

  const formDisabled = disabled || model.formDisabled;

  return (
    <div className={cn(s.form, className)}>
      <FormSelect
        model={supplierCompany}
        options={suppliers.list.items}
        disabled={formDisabled || supplierEmployee.loadingStage.isLoading}
      />
      <FormSelect
        model={customerCompany}
        options={customers.list.items}
        disabled={formDisabled || customerEmployee.loadingStage.isLoading}
      />
      <FormSelect
        model={supplierEmployee}
        options={supplierEmployee.options}
        loading={supplierEmployee.loadingStage.isLoading}
        disabled={formDisabled || supplierCompany.value === null}
      />
      <FormSelect
        model={customerEmployee}
        options={customerEmployee.options}
        loading={customerEmployee.loadingStage.isLoading}
        disabled={formDisabled || customerCompany.value === null}
      />
      <FormSelect model={legalCompanyFrom} options={legalCompanies.list.items} disabled={formDisabled} />
      <FormSelect model={legalCompanyTo} options={legalCompanies.list.items} disabled={formDisabled} />
      <FormSelect model={productTypeId} options={productTypes.list.items} disabled={formDisabled} />
      {Boolean(productTypeId.value) && (
        <FormInput
          model={chemicalWeight}
          disabled={formDisabled || isNeedLigatureWeight}
          addonAfter={
            <CustomSelect
              {...weightUnit.props}
              disabled={formDisabled || isNeedLigatureWeight}
              options={weightUnitOptions}
            />
          }
        />
      )}
      {productOptions && (
        <>
          <FormSelect model={productId} options={productOptions} disabled={formDisabled} />
          <FormInput
            model={ligatureWeight}
            disabled={formDisabled || !isNeedLigatureWeight}
            addonAfter={
              <CustomSelect
                {...weightUnit.props}
                disabled={formDisabled || !isNeedLigatureWeight}
                options={weightUnitOptions}
              />
            }
          />
        </>
      )}
      {Boolean(productTypeId.value) && (
        <>
          <FormDatePicker model={fixingDate} disabled={formDisabled} />
          <div />
          <FormInput
            model={buyPrice}
            disabled={formDisabled}
            addonAfter={
              <CustomSelect {...buyPriceUnit.props} disabled={formDisabled} options={currencies.list.items} />
            }
          />
          <FormInput
            model={sellPrice}
            disabled={formDisabled}
            addonAfter={
              <CustomSelect {...sellPriceUnit.props} disabled={formDisabled} options={currencies.list.items} />
            }
          />
          <FormInput model={buyPriceTotal} disabled={formDisabled} />
          <FormInput model={sellPriceTotal} disabled={formDisabled} />
        </>
      )}
      {supplierSpec.isInitializedByValue && model instanceof CreateTradeFieldsModel ? (
        <SupplierSpecificationModal className={s.form_upload} model={model} disabled={formDisabled} />
      ) : (
        <FormUpload
          className={s.form_upload}
          model={supplierSpec}
          variant={supplierSpec.isUploaded ? 'default' : 'dragger'}
          disabled={formDisabled}
        />
      )}
    </div>
  );
};

export default observer(CreateTradeForm);
