import { CompanyType } from '@/entities/company';
import { DocumentFileType } from '@/entities/file';
import {
  AwbTransitAction,
  ManagerShipmentStep,
  StageModelJsonParams,
  StageType,
  SupplierShipmentAction,
  SupplierShipmentStageDataServer,
  SupplierShipmentStep,
} from '@/entities/stage/types';
import { mapStepToNumber } from '@/shared/utils';

import { BaseManagerStageModel } from '../../BaseManagerStageModel';
import { BaseStageModel, BaseStageModelParams } from '../../BaseStageModel';
import { BaseUploadFilesFieldsModel, UploadAwbOrFlightNumberModel, WaitingFieldsModel } from '../../fieldsModel';

import { stepsOrder } from './config';

type Params = BaseStageModelParams<SupplierShipmentStep> & {
  uploadSpecFields: BaseUploadFilesFieldsModel<
    SupplierShipmentStep,
    SupplierShipmentAction,
    DocumentFileType.supplierSpecification
  >;
  specVerifyingFields: WaitingFieldsModel<SupplierShipmentStep>;
  transitCountry: WaitingFieldsModel<SupplierShipmentStep>;
  transitCountryAwb: UploadAwbOrFlightNumberModel<
    SupplierShipmentStep,
    AwbTransitAction.uploadFile,
    AwbTransitAction.flightNumber,
    DocumentFileType.awbForTransitCountry
  >;
  transitCountryAwbVerifying: WaitingFieldsModel<SupplierShipmentStep>;
  isCargoShipped: boolean;
};

export class SupplierShipmentStageModel extends BaseStageModel<StageType.shipment, SupplierShipmentStep> {
  readonly uploadSpecFields: BaseUploadFilesFieldsModel<
    SupplierShipmentStep,
    SupplierShipmentAction,
    DocumentFileType.supplierSpecification
  >;
  readonly specVerifyingFields: WaitingFieldsModel<SupplierShipmentStep>;
  /** Поля для StageType.transit */
  readonly transitCountry: WaitingFieldsModel<SupplierShipmentStep>;
  readonly transitCountryAwb: UploadAwbOrFlightNumberModel<
    SupplierShipmentStep,
    AwbTransitAction.uploadFile,
    AwbTransitAction.flightNumber,
    DocumentFileType.awbForTransitCountry
  >;
  readonly transitCountryAwbVerifying: WaitingFieldsModel<SupplierShipmentStep>;

  readonly isCargoShipped: boolean;

  constructor(params: Params) {
    super({
      type: StageType.shipment,
      ...params,
    });

    this.uploadSpecFields = params.uploadSpecFields;
    this.specVerifyingFields = params.specVerifyingFields;
    this.transitCountry = params.transitCountry;
    this.transitCountryAwb = params.transitCountryAwb;
    this.transitCountryAwbVerifying = params.transitCountryAwbVerifying;
    this.isCargoShipped = params.isCargoShipped;
  }

  private get _isSpecVerifying(): boolean {
    return this.uploadSpecFields.uploadedBy === CompanyType.supplier;
  }

  private get _isAwbVerifying(): boolean {
    return this.transitCountryAwb.uploadedBy === CompanyType.supplier;
  }

  get isApprovedSpec(): boolean {
    return this.uploadSpecFields.isApproved;
  }

  get isApprovedAwb(): boolean {
    return this.transitCountryAwb.isApproved;
  }

  get currentStep(): number {
    return mapStepToNumber({ step: this.step, steps: this.steps, stepsOrder });
  }

  get steps(): SupplierShipmentStep[] {
    const steps: SupplierShipmentStep[] = [
      SupplierShipmentStep.uploadSpec,
      /** Если нужна верификация спецификации, то добавляем шаг верификации */
      ...(this._isSpecVerifying ? [SupplierShipmentStep.specVerifying] : []),
    ];

    /** Если тип отгрузки = transit, то добавляем шаги для transit */
    if (this.isShipmentTypeTransit) {
      steps.push(
        SupplierShipmentStep.transitCountry,
        SupplierShipmentStep.transitCountryAwb,
        /** Если нужна верификация Awb, то добавляем шаг верификации Awb */
        ...(this._isAwbVerifying ? [SupplierShipmentStep.transitCountryAwbVerifying] : []),
      );
    }

    steps.push(SupplierShipmentStep.cargoShipment, SupplierShipmentStep.transfer);

    return steps;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<SupplierShipmentStageDataServer>): SupplierShipmentStageModel {
    const { current_step, steps, supplier_specification } = dataServer;

    const specDoc =
      steps[ManagerShipmentStep.uploadSpec] ??
      (supplier_specification ? { document: supplier_specification ?? null } : null);

    return new SupplierShipmentStageModel({
      uploadSpecFields: BaseUploadFilesFieldsModel.fromJson<
        SupplierShipmentStep,
        SupplierShipmentAction,
        DocumentFileType.supplierSpecification
      >({
        data: specDoc,
        step: SupplierShipmentStep.uploadSpec,
        approveAction: SupplierShipmentStep.uploadSpec,
        docType: DocumentFileType.supplierSpecification,
        tradeWorkflowStore,
      }),
      specVerifyingFields: WaitingFieldsModel.fromJson({
        step: SupplierShipmentStep.specVerifying,
        tradeWorkflowStore,
      }),
      transitCountry: WaitingFieldsModel.fromJson({
        step: SupplierShipmentStep.transitCountry,
        tradeWorkflowStore,
      }),
      transitCountryAwb: UploadAwbOrFlightNumberModel.fromServer({
        data: steps[SupplierShipmentStep.transitCountryAwb],
        step: SupplierShipmentStep.transitCountryAwb,
        approveFileAction: AwbTransitAction.uploadFile,
        approveNumberAction: AwbTransitAction.flightNumber,
        docType: DocumentFileType.awbForTransitCountry,
        tradeWorkflowStore,
      }),
      transitCountryAwbVerifying: WaitingFieldsModel.fromJson({
        step: SupplierShipmentStep.transitCountryAwbVerifying,
        tradeWorkflowStore,
      }),
      isCargoShipped:
        dataServer.steps[SupplierShipmentStep.cargoShipment]?.shipped || current_step === SupplierShipmentStep.transfer,

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
      step: current_step,
    });
  }
}
