import { ClientCompanyType, CompanyWithAdminServer } from '@/entities/company';

import { ICompanyDictionary } from '../types';

import { DictionaryModel } from './DictionaryModel';

type CompanyDictionaryModelParams = ICompanyDictionary;

export class CompanyDictionaryModel implements ICompanyDictionary {
  readonly value: number;
  readonly label: string;
  readonly title: string;
  readonly type: ClientCompanyType;
  readonly admin: DictionaryModel;

  constructor(params: CompanyDictionaryModelParams) {
    this.value = params.value;
    this.label = params.label;
    this.title = params.title;
    this.type = params.type;
    this.admin = params.admin;
  }

  static fromJson(server: CompanyWithAdminServer): CompanyDictionaryModel {
    return new CompanyDictionaryModel({
      value: server.id,
      label: server.name,
      title: server.name,
      type: server.type,
      admin: DictionaryModel.fromJson(server.admin),
    });
  }
}
