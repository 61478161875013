import { BaseResponse } from '@kts-front/types';

import { DocumentFileType, GenerationFieldsModel } from '@/entities/file';
import { AcceptanceCertificateDocPayload, AcceptanceCertificateDocResponse } from '@/entities/stage/types';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { SelectModel } from '@/shared/model/form/SelectModel';
import { Nullable } from '@/shared/types/values';
import { formatDate } from '@/shared/utils';
import { emptyValueValidator } from '@/shared/utils/validators';

export class AcceptanceCertificateFiedsModel extends GenerationFieldsModel<
  DocumentFileType.customerAcceptanceCertificate,
  AcceptanceCertificateDocPayload
> {
  readonly date = new DatePickerModel({
    initialValue: null,
    label: (t) => t('acceptanceCertificate.date', { ns: 'stage' }),
    placeholder: (t) => t('placeholders.date', { ns: 'shared' }),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly signatureId = new SelectModel<number>({
    initialValue: null,
    label: (t) => t('acceptanceCertificate.signature', { ns: 'stage' }),
    placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly actingUnder = new InputModel({
    initialValue: '',
    label: (t) => t('acceptanceCertificate.actingUnder', { ns: 'stage' }),
    placeholder: (t) => t('acceptanceCertificate.actingUnderPlaceholder', { ns: 'stage' }),
    tooltip: (t) => t('acceptanceCertificate.actingUnderTooltip', { ns: 'stage' }),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly customerPersonalId = new InputModel({
    initialValue: '',
    label: (t) => t('acceptanceCertificate.customerPersonalId', { ns: 'stage' }),
    placeholder: (t) => t('acceptanceCertificate.personalIdPlaceholder', { ns: 'stage' }),
  });
  readonly supplierPersonalId = new InputModel({
    initialValue: '',
    label: (t) => t('acceptanceCertificate.supplierPersonalId', { ns: 'stage' }),
    placeholder: (t) => t('acceptanceCertificate.personalIdPlaceholder', { ns: 'stage' }),
  });

  get fields() {
    return [this.date, this.signatureId, this.actingUnder, this.customerPersonalId, this.supplierPersonalId];
  }

  toJson(): Nullable<AcceptanceCertificateDocPayload> {
    if (!this.date.value || !this.signatureId.value || !this.actingUnder.value) {
      return null;
    }

    return {
      date: formatDate(this.date.value),
      signatory_id: this.signatureId.value,
      acting_under: this.actingUnder.value,
      customer_personal_id: this.customerPersonalId.value,
      supplier_personal_id: this.supplierPersonalId.value,
    };
  }

  async loadFieldsData(): Promise<BaseResponse> {
    if (this.loadingState.isLoading) {
      this._fieldsDataRequest.cancel();
    }

    this.loadingState.loading();

    const response = await this._fieldsDataRequest.call<AcceptanceCertificateDocResponse>();

    if (response.isError) {
      this.loadingState.error();
    } else {
      this._fillFieldsByResponseData(response.data);
      this.loadingState.success();
    }

    return response;
  }

  private readonly _fillFieldsByResponseData = (data: AcceptanceCertificateDocResponse): void => {
    this.date.change(data.date ? new Date(data.date) : null);
    this.signatureId.change(data.signatory_id ?? null);
    this.actingUnder.change(data.acting_under ?? '');
    this.customerPersonalId.change(data.customer_personal_id ?? '');
    this.supplierPersonalId.change(data.supplier_personal_id ?? '');
  };
}
