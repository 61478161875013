import { UserRole } from '../types';

export const user = {
  en: {
    fields: {
      contacts: 'Contact details',
      name: 'Name',
      fio: 'Full name',
      phone: 'Phone number',
      email: 'Email',
      address: 'Address',
      type: 'Type',
      surname: 'Surname',
      patronymic: 'Patronymic',
    },
    userStatus: {
      active: 'Active',
      notActive: 'Not Active',
    },
    userRoles: {
      [UserRole.ownerAdmin]: 'Administrator',
      [UserRole.ownerManager]: 'Manager',
      [UserRole.ownerObserver]: 'Observer',
      [UserRole.clientAdmin]: 'Administrator',
      [UserRole.clientEmployee]: 'Employee',
      [UserRole.clientObserver]: 'Observer',
    },
  },
  ru: {
    fields: {
      contacts: 'Контактные данные',
      name: 'Имя',
      fio: 'ФИО',
      phone: 'Номер телефона',
      email: 'Email',
      address: 'Адрес',
      type: 'Тип',
      surname: 'Фамилия',
      patronymic: 'Отчество',
    },
    userStatus: {
      active: 'Активен',
      notActive: 'Не активен',
    },
    userRoles: {
      [UserRole.ownerAdmin]: 'Администратор',
      [UserRole.ownerManager]: 'Менеджер',
      [UserRole.ownerObserver]: 'Наблюдатель',
      [UserRole.clientAdmin]: 'Администратор',
      [UserRole.clientEmployee]: 'Сотрудник',
      [UserRole.clientObserver]: 'Наблюдатель',
    },
  },
  zh: {
    fields: {
      contacts: '联系方式',
      name: '名称',
      fio: '姓名',
      phone: '电话号码',
      email: '电子邮箱',
      address: '地址',
      type: '类别',
    },
    userStatus: {
      active: '活跃',
      notActive: '不活跃',
    },
    userRoles: {
      [UserRole.ownerAdmin]: '管理人',
      [UserRole.ownerManager]: '代办',
      // [UserRole.ownerObserver]: 'Наблюдатель',
      [UserRole.clientAdmin]: '管理人',
      // [UserRole.clientEmployee]: 'Сотрудник',
      // [UserRole.clientObserver]: 'Наблюдатель',
    },
  },
};
