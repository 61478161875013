import { Nullable } from '@/shared/types/values';

import { ICompany } from '../types/client';
import { ClientCompanyType, CompanyServer } from '../types/server';

export type CompanyModelParams = {
  id: number;
  type: ClientCompanyType;
  name: string;
  email: string;
  phone: Nullable<string>;
  address: Nullable<string>;
};

export class CompanyModel implements ICompany {
  readonly id: number;
  readonly type: ClientCompanyType;
  readonly name: string;
  readonly email: string;
  readonly phone: Nullable<string>;
  readonly address: Nullable<string>;

  constructor(params: CompanyModelParams) {
    this.id = params.id;
    this.type = params.type;
    this.name = params.name;
    this.email = params.email;
    this.address = params.address;
    this.phone = params.phone;
  }

  static fromJson(server: CompanyServer<ClientCompanyType>): CompanyModel {
    return new CompanyModel({
      id: server.id,
      type: server.type,
      name: server.name,
      email: server.email,
      phone: server.phone,
      address: server.address,
    });
  }
}
