import { Nullable } from '../types/values';

type Params = {
  name: string;
  surname?: Nullable<string>;
  patronymic?: Nullable<string>;
};

export const getFullName = ({ name, surname = '', patronymic = '' }: Params): string =>
  `${surname} ${name} ${patronymic}`.trim();
