import cn from 'classnames';
import * as React from 'react';

import s from './ContentCard.module.scss';

type ContentCardProps = React.PropsWithChildren<{
  className?: string;
}>;

const ContentCard = React.forwardRef<HTMLDivElement, ContentCardProps>(({ className, children }, ref) => {
  return (
    <div ref={ref} className={cn(s['content-card'], className)}>
      {children}
    </div>
  );
});

ContentCard.displayName = 'ContentCard';

export default ContentCard;
