import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { FormDatePicker } from '@/shared/ui/form/FormDatePicker';
import { FormInput } from '@/shared/ui/form/FormInput';
import { FormSelect } from '@/shared/ui/form/FormSelect';

import { AcceptanceCertificateFiedsModel } from '../../model/fieldsModel';

type AcceptanceCertificateFormProps = {
  model: AcceptanceCertificateFiedsModel;
};

const AcceptanceCertificateForm: React.FC<AcceptanceCertificateFormProps> = ({ model }) => {
  const { date, signatureId, actingUnder, customerPersonalId, supplierPersonalId, loadingState } = model;

  const { dictionariesStore } = useRootStore();

  return (
    <>
      <FormDatePicker model={date} disabled={loadingState.isLoading} />
      <FormInput model={actingUnder} disabled={loadingState.isLoading} />
      <FormSelect
        model={signatureId}
        options={dictionariesStore.signatories.list.items}
        disabled={loadingState.isLoading}
      />
      <FormInput model={customerPersonalId} disabled={loadingState.isLoading} />
      <FormInput model={supplierPersonalId} disabled={loadingState.isLoading} />
    </>
  );
};

export default observer(AcceptanceCertificateForm);
