import { Flex } from 'antd';
import * as React from 'react';

import { TranslationString } from '@/shared/types/localization';

import { CustomTypographyText } from '../../CustomTypographyText';

import { FormItemOptional } from './FormItemOptional';

import s from './FormItem.module.scss';

export type FormItemDescriptionProps = {
  description?: TranslationString;
  required?: boolean;
};

const FormItemDescription: React.FC<FormItemDescriptionProps> = ({ description, required }) => {
  if (!description) return null;

  return (
    <Flex className={s['form-item_description']} vertical>
      <CustomTypographyText type="secondary">{description}</CustomTypographyText>
      <FormItemOptional required={required} />
    </Flex>
  );
};

export default FormItemDescription;
