import { TranslationString } from '@/shared/types/localization';
import { Options } from '@/shared/types/values';

import { BidDifferenceType, BidFixingType, BidInfoKeys, BidStatus, BidType, TableType } from './types';

type StatusList = Array<BidStatus>;

const T_OPTIONS = { ns: 'bids' } as const;

export const mapBidTypeToLabel =
  (type: BidType): TranslationString =>
  (t) =>
    t(`bidType.${type}`, T_OPTIONS);

export const mapBidStatusToLabel =
  (status: BidStatus): TranslationString =>
  (t) =>
    t(`status.${status}`, T_OPTIONS);

export const mapFixingTypeToLabel =
  (type: BidFixingType): TranslationString =>
  (t) =>
    t(`fixingType.${type}`, T_OPTIONS);

export const mapDiffTypeToLabel =
  (type: BidDifferenceType): TranslationString =>
  (t) =>
    t(`differenceType.${type}`, T_OPTIONS);

export const fixingTypeOptions: Options<BidFixingType> = [
  {
    label: mapFixingTypeToLabel(BidFixingType.am),
    value: BidFixingType.am,
  },
  {
    label: mapFixingTypeToLabel(BidFixingType.pm),
    value: BidFixingType.pm,
  },
  {
    label: mapFixingTypeToLabel(BidFixingType.spot),
    value: BidFixingType.spot,
  },
];

export const differenceTypeOptions: Options<BidDifferenceType> = [
  {
    label: mapDiffTypeToLabel(BidDifferenceType.price),
    value: BidDifferenceType.price,
  },
  {
    label: mapDiffTypeToLabel(BidDifferenceType.percent),
    value: BidDifferenceType.percent,
  },
];

export const bidTypeOptions: Options<BidType> = [
  {
    label: mapBidTypeToLabel(BidType.buy),
    value: BidType.buy,
  },
  {
    label: mapBidTypeToLabel(BidType.sell),
    value: BidType.sell,
  },
];

const ownerBuySellStatusList: StatusList = [
  BidStatus.new,
  BidStatus.published,
  BidStatus.inProgress,
  BidStatus.approved,
  BidStatus.tradeCreated,
];

const clientReplyStatusList: StatusList = [
  BidStatus.review,
  BidStatus.approved,
  BidStatus.rejected,
  BidStatus.tradeCreated,
];

const ownerMyStatusList: StatusList = [
  BidStatus.published,
  BidStatus.inProgress,
  BidStatus.approved,
  BidStatus.tradeCreated,
];

const clientMyStatusList: StatusList = [BidStatus.review, ...ownerMyStatusList];

const getStatusOptions = (values: StatusList): Options<BidStatus> =>
  values.map((value) => ({
    label: mapBidStatusToLabel(value),
    value,
  }));

export const mapTableTypeToStatusOptions = (tableType: TableType, isMainRole: boolean): Options<BidStatus> => {
  switch (tableType) {
    case TableType.my:
      return getStatusOptions(isMainRole ? ownerMyStatusList : clientMyStatusList);

    case TableType.reply:
      return getStatusOptions(clientReplyStatusList);

    case TableType.buy:

    case TableType.sell:
      return getStatusOptions(isMainRole ? ownerBuySellStatusList : []);
  }
};

export const mapBidInfoToLabel = (isMainRole: boolean = false): Record<BidInfoKeys, TranslationString> => ({
  status: (t) => t('BidInfo.fields.status', T_OPTIONS),
  type: (t) => t('BidInfo.fields.type', T_OPTIONS),
  metal: (t) => t('BidInfo.fields.metal', T_OPTIONS),
  shape: (t) => t('BidInfo.fields.shape', T_OPTIONS),
  fixingType: (t) => t('BidInfo.fields.fixingType', T_OPTIONS),
  fixingDate: (t) => t('BidInfo.fields.fixingDate', T_OPTIONS),
  weightWithUnit: (t) => t('BidInfo.fields.weightWithUnit', T_OPTIONS),
  ligatureWeightWithUnit: (t) => t('BidInfo.fields.ligatureWeightWithUnit', T_OPTIONS),
  currency: (t) => t('BidInfo.fields.currency', T_OPTIONS),
  fixingPrice: (t) => t('BidInfo.fields.fixingPrice', T_OPTIONS),
  sellerName: (t) => t('BidInfo.fields.sellerName', T_OPTIONS),
  buyerName: (t) => t('BidInfo.fields.buyerName', T_OPTIONS),
  buyerCountry: (t) => t('BidInfo.fields.buyerCountry', T_OPTIONS),
  authorDeliveryBasis: (t) => t('BidInfo.fields.deliveryBasis', T_OPTIONS),
  authorDifference: (t) => t('BidInfo.fields.difference', T_OPTIONS),
  managerDeliveryBasis: (t) => t('BidInfo.fields.managerDeliveryBasis', T_OPTIONS),
  managerDifference: (t) => t('BidInfo.fields.managerDifference', T_OPTIONS),
  respondentDeliveryBasis: (t) =>
    t(isMainRole ? 'BidInfo.fields.respondentDeliveryBasis' : 'BidInfo.fields.deliveryBasis', T_OPTIONS),
  respondentDifference: (t) =>
    t(isMainRole ? 'BidInfo.fields.respondentDifference' : 'BidInfo.fields.difference', T_OPTIONS),
});
