import {
  DocumentServer,
  DocumentsServer,
  DocumentsWithStatusServer,
  DocumentWithStatusServer,
  FileModel,
} from '@/entities/file';
import { UploadFilesModel } from '@/shared/model/form/UploadFilesModel';
import { Nullable } from '@/shared/types/values';

import { WaitingFieldsModel, WaitingFieldsModelParams } from '../WaitingFieldsModel';

export type WaitingFieldsWithFileModelParams<Step> = WaitingFieldsModelParams<Step> & {
  file: UploadFilesModel;
};

export class WaitingFieldsWithFileModel<Step> extends WaitingFieldsModel<Step> {
  readonly file: UploadFilesModel;

  constructor({ file, ...params }: WaitingFieldsWithFileModelParams<Step>) {
    super(params);

    this.file = file;
  }

  static fromJson<Step>({
    data,
    ...params
  }: WaitingFieldsModelParams<Step> & {
    data: Nullable<DocumentServer | DocumentWithStatusServer | DocumentsServer | DocumentsWithStatusServer>;
  }) {
    const initialJson = data && 'document' in data ? data.document : data?.documents;

    return new WaitingFieldsWithFileModel({
      file: new UploadFilesModel({
        initialValue: FileModel.fileListFromJson(initialJson),
        maxCount: 1,
      }),
      ...params,
    });
  }
}
