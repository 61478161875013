import { CompanyType, TaxType } from '@/entities/company';

export const company = {
  en: {
    fields: {
      name: 'Company name',
      address: 'Address',
      email: 'Email',
      phone: 'Phone number',
      tax: 'Tax sign',
      country: 'Country',
      selectFormCountry: 'Select from the list',
    },
    taxName: {
      [TaxType.BR]: 'BR',
      [TaxType.CR]: 'CR',
      [TaxType.TIN]: 'TIN',
      [TaxType.TRN]: 'TRN',
      [TaxType.certificateNumber]: 'Certificate number',
      [TaxType.licenceNumber]: 'Licence number',
    },
    companyType: {
      [CompanyType.customer]: 'Customer',
      [CompanyType.supplier]: 'Supplier',
    },
  },
  ru: {
    fields: {
      name: 'Название компании',
      address: 'Адрес',
      email: 'Email',
      phone: 'Номер телефона',
      tax: 'Налоговый признак',
      country: 'Страна',
      selectFormCountry: 'Выберите из списка',
    },
    taxName: {
      [TaxType.BR]: 'BR',
      [TaxType.CR]: 'CR',
      [TaxType.TIN]: 'TIN',
      [TaxType.TRN]: 'TRN',
      [TaxType.certificateNumber]: 'Номер сертификата',
      [TaxType.licenceNumber]: 'Номер лицензии',
    },
    companyType: {
      [CompanyType.customer]: 'Покупатель',
      [CompanyType.supplier]: 'Поставщик',
    },
  },
  zh: {
    fields: {
      // name: 'Название компании',
      address: '地址',
      email: '电子邮箱',
      phone: '电话号码',
      tax: '税号',
      country: '国籍',
      selectFormCountry: '从列表中选择',
    },
    taxName: {
      [TaxType.BR]: 'BR',
      [TaxType.CR]: 'CR',
      [TaxType.TIN]: 'TIN',
      [TaxType.TRN]: 'TRN',
      [TaxType.certificateNumber]: '籾质编号',
      [TaxType.licenceNumber]: '证照号码',
    },
    companyType: {
      [CompanyType.customer]: '买方',
      [CompanyType.supplier]: '供应商',
    },
  },
};
