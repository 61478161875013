export const companies = {
  en: {
    title: 'Companies',
    actions: {
      addClient: 'Add a company',
    },
    colums: {
      name: 'Name',
      phone: 'Phone number',
      email: 'Email',
      address: 'Address',
      type: 'Type',
    },
    modal: {
      type: 'Type',
      customer: 'Customer',
      supplier: 'Supplier',
      titleCompany: 'Contact details',
      titleProfile: 'Administrator profile',
    },
    add: {
      title: 'Adding a company',
      add: 'Add',
      cancel: 'Cancel',
    },
    edit: {
      title: 'Editing a company',
      add: 'Save changes',
      cancel: 'Reset changes',
    },
    messages: {
      editSuccess: 'Company updated successfully',
      editError: 'An error occurred while updating the company',
      addSuccess: 'Company added successfully',
      addError: 'An error occurred while adding a company',
    },
  },
  ru: {
    title: 'Компании',
    actions: {
      addClient: 'Добавить компанию',
    },
    colums: {
      name: 'Название',
      phone: 'Номер телефона',
      email: 'Email',
      address: 'Адрес',
      type: 'Тип',
    },
    modal: {
      type: 'Тип',
      customer: 'Покупатель',
      supplier: 'Поставщик',
      titleCompany: 'Контактные данные',
      titleProfile: 'Профиль администратора',
    },
    add: {
      title: 'Добавление компании',
      add: 'Добавить',
      cancel: 'Отмена',
    },
    edit: {
      title: 'Редактирование компании',
      add: 'Сохранить изменения',
      cancel: 'Сбросить изменения',
    },
    messages: {
      editSuccess: 'Компания успешно обновлена',
      editError: 'Произошла ошибка при обновлении компании',
      addSuccess: 'Компания успешно добавлена',
      addError: 'Произошла ошибка при добавлении компании',
    },
  },
  zh: {
    title: '客户',
    actions: {
      addClient: '添加客户',
    },
    colums: {
      name: '名称',
      phone: '电话号码',
      email: '电子邮箱',
      address: '地址',
      type: '类别',
    },
    modal: {
      type: '类别',
      customer: '买方',
      supplier: '供应商',
      titleCompany: '公司',
      titleProfile: '个人资料',
    },
    add: {
      title: '添加客户',
      add: '添加',
      cancel: '取消',
    },
    edit: {
      title: '客户个人资料',
      add: '保存更改',
      cancel: '保存更改',
    },
    messages: {
      editSuccess: '客户更新成功',
      editError: '更新客户时出错',
      addSuccess: '客户添加成功',
      addError: '添加客户时出错',
    },
  },
};
