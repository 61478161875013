import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { RadioListModel } from '@/shared/model/form/RadioListModel';
import { ID } from '@/shared/types/meta';

import { CustomFormItem } from '../CustomFormItem';
import { RadioGroup } from '../RadioGroup';

type FormRadioGroupProps<Value> = {
  className?: string;
  model: RadioListModel<Value>;
  disabled?: boolean;
};

const FormRadioGroup = <Value extends ID>({ className, model, disabled = false }: FormRadioGroupProps<Value>) => {
  return (
    <CustomFormItem className={className} ref={model.ref} model={model}>
      <RadioGroup {...model.props} disabled={disabled || model.disabled} />
    </CustomFormItem>
  );
};

export default observer(FormRadioGroup);
