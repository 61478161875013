import { BaseUserServer, CompanyUserRoleServer, CreateUserPayload, EditUserPayload } from '@/entities/user';
import { ApiListResponse } from '@/shared/types/api';

export enum CompanyEmployeeStatus {
  invited = 'invited',
  authorized = 'authorized',
  blocked = 'blocked',
}

export type CompanyEmployeeRoleServer = CompanyUserRoleServer.clientEmployee | CompanyUserRoleServer.clientObserver;

export type EmployeeServer = BaseUserServer<CompanyEmployeeRoleServer> & {
  status: CompanyEmployeeStatus;
};

export type EmployeeListResponse = ApiListResponse<EmployeeServer>;

export type CreateEmployeePayload = CreateUserPayload & {
  role: CompanyEmployeeRoleServer;
};

export type EditEmployeePayload = EditUserPayload & {
  role: CompanyEmployeeRoleServer;
};

export type EmployeeOptionServer = BaseUserServer<
  CompanyUserRoleServer.clientEmployee | CompanyUserRoleServer.clientAdmin
> & {
  status: CompanyEmployeeStatus;
};

export type EmployeeOptionListResponse = ApiListResponse<EmployeeOptionServer>;
