import { TranslationString } from '@/shared/types/localization';
import { Options } from '@/shared/types/values';

import { ClientCompanyType, CompanyType, TaxType } from './types';

const T_OPTIONS = { ns: 'company' } as const;

const mapTaxTypeToLabel =
  (type: TaxType): TranslationString =>
  (t) =>
    t(`taxName.${type}`, T_OPTIONS);

export const taxTypeOptions: Options<TaxType> = [
  {
    label: mapTaxTypeToLabel(TaxType.BR),
    value: TaxType.BR,
  },
  {
    label: mapTaxTypeToLabel(TaxType.CR),
    value: TaxType.CR,
  },
  {
    label: mapTaxTypeToLabel(TaxType.TIN),
    value: TaxType.TIN,
  },
  {
    label: mapTaxTypeToLabel(TaxType.TRN),
    value: TaxType.TRN,
  },
  {
    label: mapTaxTypeToLabel(TaxType.certificateNumber),
    value: TaxType.certificateNumber,
  },
  {
    label: mapTaxTypeToLabel(TaxType.licenceNumber),
    value: TaxType.licenceNumber,
  },
];

export const mapClientCompanyTypeToLabel =
  (type: ClientCompanyType): TranslationString =>
  (t) =>
    t(`companyType.${type}`, T_OPTIONS);

export const clientCompanyOptions: Options<ClientCompanyType> = [
  {
    label: mapClientCompanyTypeToLabel(CompanyType.customer),
    value: CompanyType.customer,
  },
  {
    label: mapClientCompanyTypeToLabel(CompanyType.supplier),
    value: CompanyType.supplier,
  },
];
