import { CompanyType } from '@/entities/company';
import { TradeInfoServer } from '@/entities/trade';

import { BaseStageDataServer } from './baseStage';

export enum ManagerCreationStep {
  createTrade = 'create_trade',
}

export type ManagerCreationAction = ManagerCreationStep;

type MapUserTypeToCreationStageData = {
  [CompanyType.owner]: {
    [ManagerCreationStep.createTrade]: TradeInfoServer;
  };
  [CompanyType.customer]: null;
  [CompanyType.supplier]: null;
};

type BaseCreationStageDataServer<CT extends CompanyType, Step> = BaseStageDataServer<
  Step,
  MapUserTypeToCreationStageData[CT]
>;

export type ManagerCreationStageDataServer = BaseCreationStageDataServer<CompanyType.owner, ManagerCreationStep>;
export type OtherCreationStageDataServer = BaseCreationStageDataServer<Exclude<CompanyType, CompanyType.owner>, null>;

export type CreationStageDataServer = ManagerCreationStageDataServer | OtherCreationStageDataServer;

export type CreationAction = ManagerCreationAction;
