import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { SelectModel } from '@/shared/model/form/SelectModel';
import { ID } from '@/shared/types/meta';

import { CustomFormItem } from '../CustomFormItem';
import { CustomSelect, CustomSelectProps } from '../CustomSelect';

type FormSelectProps<Value> = CustomSelectProps<Value> & {
  className?: string;
  model: SelectModel<Value>;
};

const FormSelect = <Value extends ID>({ className, model, disabled, ...props }: FormSelectProps<Value>) => {
  return (
    <CustomFormItem className={className} ref={model.ref} model={model}>
      <CustomSelect
        showSearch
        allowClear={!model.required}
        {...model.props}
        {...props}
        disabled={model.props.disabled || disabled}
      />
    </CustomFormItem>
  );
};

export default observer(FormSelect);
