import { ICreationStage } from '@/entities/stage';

export const getRequiredFields = (isNeedLigatureWeight: boolean): Array<keyof ICreationStage> => [
  'productTypeId',
  isNeedLigatureWeight ? 'ligatureWeight' : 'chemicalWeight',
  'weightUnit',
  'fixingDate',
];

export const supplierFields: (keyof ICreationStage)[] = [
  'supplierCompany',
  'legalCompanyFrom',
  'buyPrice',
  'buyPriceUnit',
  'buyPriceTotal',
];

export const customerFields: (keyof ICreationStage)[] = [
  'customerCompany',
  'legalCompanyTo',
  'sellPrice',
  'sellPriceUnit',
  'sellPriceTotal',
];
