import { UploadFile } from 'antd';
import { RcFile, UploadFileStatus } from 'antd/es/upload/interface';
import { action, computed, makeObservable } from 'mobx';

import { BaseFileServer } from '@/entities/file';

import { TranslationString } from '../types/localization';
import { Nullable } from '../types/values';

import { ValueModel } from './ValueModel';

type UploadFileModelParams = {
  uid: string;
  name: string;
  status: Nullable<UploadFileStatus>;
  error: Nullable<TranslationString>;
  url?: string;
  type?: string;
  size?: number;
  originFileObj?: RcFile;
};

export class UploadFileModel implements UploadFile {
  private readonly _status: ValueModel<Nullable<UploadFileStatus>>;
  private readonly _error: ValueModel<Nullable<TranslationString>>;

  readonly uid: string;
  readonly name: string;
  readonly url?: string;
  readonly type?: string;
  readonly size?: number;
  readonly originFileObj?: RcFile;

  constructor(params: UploadFileModelParams) {
    this._status = new ValueModel(params.status);
    this._error = new ValueModel(params.error);

    this.uid = params.uid;
    this.name = params.name;
    this.url = params.url;
    this.type = params.type;
    this.size = params.size;
    this.originFileObj = params.originFileObj;

    makeObservable(this, {
      status: computed,
      error: computed,

      changeError: action.bound,
      changeStatus: action.bound,
      setDoneStatus: action.bound,
      setUploadingStatus: action.bound,
      setErrorStatus: action.bound,
      setRemoveStatus: action.bound,
    });
  }

  get status(): UploadFileStatus | undefined {
    return this._status.value ?? undefined;
  }

  get error(): TranslationString | undefined {
    return this._error.value ?? undefined;
  }

  changeError(error: Nullable<TranslationString>) {
    this._error.change(error);
  }

  changeStatus(status: Nullable<UploadFileStatus>) {
    this._status.change(status);
  }

  setUploadingStatus() {
    this.changeStatus('uploading');
  }

  setDoneStatus() {
    this.changeStatus('done');
  }

  setErrorStatus() {
    this.changeStatus('error');
  }

  setRemoveStatus() {
    this.changeStatus('removed');
  }

  static fromJson(raw: BaseFileServer | UploadFile | RcFile): UploadFileModel {
    const isServerFile = 'id' in raw && 'link' in raw;
    const isRcFile = raw instanceof File;

    let params: UploadFileModelParams;

    if (isServerFile) {
      params = {
        uid: String(raw.id),
        name: raw.name,
        url: raw.link,
        size: raw.size,
        error: null,
        status: 'done',
      };
    } else if (isRcFile) {
      params = {
        uid: String(raw.uid),
        name: raw.name,
        type: raw.type,
        size: raw.size,
        originFileObj: raw,
        status: null,
        error: null,
      };
    } else {
      params = {
        uid: String(raw.uid),
        name: raw.name,
        url: raw.url,
        type: raw.type,
        size: raw.size,
        originFileObj: raw.originFileObj,
        status: raw.status ?? null,
        error: raw.error ?? null,
      };
    }

    return new UploadFileModel(params);
  }

  static fileListFromJson(raw: Nullable<Array<BaseFileServer> | BaseFileServer> = null): Array<UploadFileModel> {
    if (!raw) {
      return [];
    }

    const files = Array.isArray(raw) ? raw : [raw];

    return files.map(this.fromJson);
  }
}
