import { action, makeObservable, reaction } from 'mobx';

import { IRootStore } from '@/app/store';
import { BidModel, BidType } from '@/entities/bid';
import { ProductType } from '@/entities/dictionary';
import { MessageType } from '@/entities/message';
import { ICreationStage } from '@/entities/stage';
import { apiStore, apiUrls } from '@/shared/api';
import { routerUrls } from '@/shared/config/routes';
import { ToggleModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import { TradeResponse } from '../../types';
import { CreateTradeModel } from '../CreateTradeModel';

import { customerFields, getRequiredFields, supplierFields } from './config';

type Params = {
  rootStore: IRootStore;
  bidId: Nullable<number>;
  data: ICreationStage;
};

const T_OPTIONS = { ns: 'trade' } as const;

export class CreateTradeStore extends CreateTradeModel {
  readonly modalState = new ToggleModel();

  private readonly _bidId: Nullable<number>;

  private _createTradeRequest = apiStore.createRequest<TradeResponse>({
    method: 'POST',
    url: apiUrls.trade.create,
  });

  constructor({ bidId, ...params }: Params) {
    super(params);

    this._bidId = bidId;

    makeObservable(this, {
      closeModal: action.bound,
      resetForm: action.bound,
      submitModal: action.bound,
    });

    this.addReactions([
      // При изменении полей "Поставщика" чистим ошибки полей "Покупателя"
      reaction(
        () => supplierFields.map((field) => this[field].value),
        () => customerFields.forEach((field) => this[field].resetError()),
      ),
      // При изменении полей "Покупателя" чистим ошибки полей "Поставщика"
      reaction(
        () => customerFields.map((field) => this[field].value),
        () => supplierFields.forEach((field) => this[field].resetError()),
      ),
    ]);
  }

  resetForm() {
    this.formFields.forEach((field) => {
      if (field !== 'buyPriceUnit' && field !== 'sellPriceUnit') {
        this[field].reset();
      }
    });
  }

  closeModal() {
    if (!this.requestStage.isLoading) {
      this.resetForm();
      this.modalState.close();
    }
  }

  private _validateModal = () => {
    const supplierSelected = this.supplierCompany.value !== null;
    const customerSelected = this.customerCompany.value !== null;

    getRequiredFields(this.isNeedLigatureWeight).forEach((field) => {
      this[field].validate();
    });

    if (this.productOptions) {
      this.productId.validate();
    }

    if (supplierSelected && !customerSelected) {
      supplierFields.forEach((field) => {
        this[field].validate();
      });

      customerFields.some((field) => this[field].value && this[field].validate());

      return;
    }

    if (customerSelected && !supplierSelected) {
      customerFields.forEach((field) => {
        this[field].validate();
      });

      supplierFields.some((field) => this[field].value && this[field].validate());

      return;
    }

    const supplierErrors = supplierFields.some((field) => this[field].validate());
    const customerErrors = customerFields.some((field) => this[field].validate());

    if (customerErrors && !supplierErrors) {
      customerFields.forEach((field) => {
        this[field].resetError();
      });

      customerFields.some((field) => this[field].value && this[field].validate());

      return;
    }

    if (supplierErrors && !customerErrors) {
      supplierFields.forEach((field) => {
        this[field].resetError();
      });

      supplierFields.some((field) => this[field].value && this[field].validate());

      return;
    }
  };

  async submitModal() {
    this._validateModal();

    if (this.isError) {
      this.scrollToErrorField();

      return;
    }

    if (this.requestStage.isLoading) {
      return;
    }

    this.requestStage.loading();

    const response = await this._createTradeRequest.call({
      multipartFormData: true,
      data: {
        ...this.toJson(),
        ...(this._bidId ? { bid_id: this._bidId } : {}),
      },
    });

    if (response.isError) {
      this.requestStage.error();
      this._rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('messages.createTradeError', T_OPTIONS),
      });

      return;
    }

    this.modalState.close();
    this.requestStage.success();
    this._rootStore.notificationsStore.addNotification({
      type: MessageType.success,
      message: (t) => t('messages.createTradeSuccess', T_OPTIONS),
    });

    this._rootStore.routerStore.navigate(routerUrls.trade.create(String(response.data.id)));
  }

  static fromDefaultParams({ rootStore }: Pick<Params, 'rootStore'>): CreateTradeStore {
    return new CreateTradeStore({
      rootStore,
      bidId: null,
      data: {
        supplierSpec: [],
        customerCompany: null,
        supplierCompany: null,
        legalCompanyFrom: null,
        legalCompanyTo: null,
        productTypeId: null,
        productId: null,
        chemicalWeight: '',
        ligatureWeight: '',
        weightUnit: null,
        fixingDate: null,
        sellPrice: '',
        sellPriceUnit: null,
        sellPriceTotal: '',
        buyPrice: '',
        buyPriceUnit: null,
        buyPriceTotal: '',
        supplierEmployee: null,
        customerEmployee: null,
      },
    });
  }

  static fromBidModel<Model extends BidModel>({
    bidModel,
    rootStore,
  }: {
    bidModel: Model;
    rootStore: IRootStore;
  }): CreateTradeStore {
    const { seller, buyer, type, fixingPrice, bidPrices, bidDictionaries } = bidModel;
    const product = bidDictionaries?.product;
    const productType = rootStore.dictionariesStore.productTypes.list.items.find(
      (item) => item.type === ProductType.metal,
    );

    const price = String(fixingPrice ?? '');
    const totalPrice = String(bidPrices?.totalOuncePriceWithDiff ?? '');
    const priceUnit = bidPrices?.priceUnit.value ?? null;

    return new CreateTradeStore({
      rootStore,
      bidId: bidModel.id,
      data: {
        supplierSpec: [],
        supplierCompany: seller?.company?.id ?? null,
        customerCompany: buyer?.company?.id ?? null,
        supplierEmployee: seller?.userId ?? null,
        customerEmployee: buyer?.userId ?? null,
        legalCompanyFrom: null,
        legalCompanyTo: null,
        productTypeId: productType?.value ?? null,
        productId: product?.value ?? null,
        chemicalWeight: String(bidModel.weight),
        ligatureWeight: String(bidModel.ligatureWeight),
        weightUnit: bidModel.weightUnitId,
        fixingDate: bidModel.fixingDate,
        ...(type === BidType.buy
          ? {
              buyPrice: price,
              buyPriceUnit: priceUnit,
              buyPriceTotal: totalPrice,
              sellPrice: '',
              sellPriceUnit: null,
              sellPriceTotal: '',
            }
          : {
              sellPrice: price,
              sellPriceUnit: priceUnit,
              sellPriceTotal: totalPrice,
              buyPrice: '',
              buyPriceUnit: null,
              buyPriceTotal: '',
            }),
      },
    });
  }
}
