import { isString, stringIsNumber } from '../types/typesGuard';

import { fixNumberDecimals } from './fixNumberDecimals';

const WEIGHT_COEFFICIENT = 0.9999;

export const getLigatureWeight = (chemicalWeight: number | string): number => {
  if (isString(chemicalWeight) && !stringIsNumber(chemicalWeight)) {
    return 0;
  }

  const weightAsNumber = Number(chemicalWeight);

  return fixNumberDecimals(weightAsNumber / WEIGHT_COEFFICIENT, 3);
};

export const getChemicalWeight = (ligatureWeight: number | string): number => {
  if (isString(ligatureWeight) && !stringIsNumber(ligatureWeight)) {
    return 0;
  }

  const weightAsNumber = Number(ligatureWeight);

  return fixNumberDecimals(weightAsNumber * WEIGHT_COEFFICIENT, 3);
};
