import { Flex } from 'antd';
import * as React from 'react';

import { SupportedLocale, TFunctionType } from '@/shared/types/localization';
import { RUIcon, USIcon, ZHIcon } from '@/shared/ui/icons';

import s from './Localization.module.scss';

export const mapLangToFlag: Record<SupportedLocale, React.ReactElement> = {
  [SupportedLocale.en]: <USIcon className={s.icon} />,
  [SupportedLocale.ru]: <RUIcon className={s.icon} />,
  [SupportedLocale.zh]: <ZHIcon className={s.icon} />,
};

export const getLangOptions = (langs: Array<SupportedLocale>, t: TFunctionType) => {
  return langs.map((lng) => ({
    value: lng,
    label: (
      <Flex align="center" gap={8}>
        {mapLangToFlag[lng]}
        {t(`locales.${lng}`, { ns: 'shared' })}
      </Flex>
    ),
  }));
};
