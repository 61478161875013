import { CompanyType } from '@/entities/company';
import { DocumentServer, DocumentWithStatusServer, FileServer } from '@/entities/file';
import { Nullable } from '@/shared/types/values';

import { BaseStageDataServer, DestinationCountryDocsServer } from './baseStage';

export enum ManagerTransportationStep {
  transitCountryCargoArrival = 'cargo_arrival_in_transit_country',
  cargoArrival = 'cargo_arrival_confirmation',

  supplierAcceptanceCertificate = 'upload_supplier_acceptance_certificate',
  supplierAcceptanceCertificateVerifying = 'supplier_acceptance_certificate_verifying',
  supplierSignedAcceptanceCertificate = 'upload_signed_supplier_acceptance_certificate',

  destinationCountryDocs = 'upload_destination_country_documents',
  destinationCountryAwb = 'upload_awb_for_destination_country',
  transitCountryCargoShipment = 'cargo_shipment_from_transit_country',
  destinationCountryCargoArrival = 'cargo_arrival_in_destination_country',
  transfer = 'transfer',
}

export enum ManagerSupplierAcceptanceCertificateVerifyingAction {
  approveSupplierAcceptanceCertificate = 'approve_supplier_acceptance_certificate_verifying',
  rejectSupplierAcceptanceCertificate = 'reject_supplier_acceptance_certificate_verifying',
}

export enum ManagerAwbDestinationAction {
  uploadFile = 'upload_awb_for_destination_country',
  flightNumber = 'set_flight_number_for_destination_country',
}

export type ManagerTransportationAction =
  | Exclude<
      ManagerTransportationStep,
      ManagerTransportationStep.destinationCountryAwb | ManagerTransportationStep.supplierAcceptanceCertificateVerifying
    >
  | ManagerAwbDestinationAction
  | ManagerSupplierAcceptanceCertificateVerifyingAction;

export enum CustomerTransportationStep {
  cargoArrival = 'cargo_arrival_confirmation',
  destinationCountryDocs = 'upload_destination_country_documents',
  transfer = 'transfer',
}

type MapUserTypeToTransportationStageData = {
  [CompanyType.owner]: {
    [ManagerTransportationStep.transitCountryCargoArrival]: Nullable<{ date: Date }>;
    [ManagerTransportationStep.cargoArrival]: Nullable<{ date: Date }>;
    [ManagerTransportationStep.supplierAcceptanceCertificate]: Nullable<DocumentWithStatusServer>;
    [ManagerTransportationStep.supplierSignedAcceptanceCertificate]: Nullable<DocumentServer>;
    [ManagerTransportationStep.supplierAcceptanceCertificateVerifying]: null;
    [ManagerTransportationStep.destinationCountryDocs]: Nullable<DestinationCountryDocsServer>;
    [ManagerTransportationStep.destinationCountryAwb]: Nullable<DocumentServer>;
    [ManagerTransportationStep.transitCountryCargoShipment]: Nullable<{ shipped: boolean }>;
    [ManagerTransportationStep.destinationCountryCargoArrival]: Nullable<{ date: Date }>;
  };
  [CompanyType.supplier]: null;
  [CompanyType.customer]: {
    [CustomerTransportationStep.cargoArrival]: Nullable<{ arrived: boolean }>;
    [CustomerTransportationStep.destinationCountryDocs]: Nullable<DestinationCountryDocsServer<FileServer>>;
  };
};

type BaseTransportationStageDataServer<CT extends CompanyType, Step> = BaseStageDataServer<
  Step,
  MapUserTypeToTransportationStageData[CT]
>;

export type ManagerTransportationStageDataServer = BaseTransportationStageDataServer<
  CompanyType.owner,
  ManagerTransportationStep
>;

export type CustomerTransportationStageDataServer = BaseTransportationStageDataServer<
  CompanyType.customer,
  CustomerTransportationStep
>;

export type TransportationStageDataServer =
  | ManagerTransportationStageDataServer
  | CustomerTransportationStageDataServer;

export type TransportationAction = ManagerTransportationAction;
