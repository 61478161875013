import { IRootStore } from '@/app/store';
import { CompanyModel } from '@/entities/company';
import { CustomerModel, SupplierModel } from '@/entities/user';

import { ITrade, TradeServer } from '../types';

import { BaseTradeModel } from './BaseTradeModel';

export class TradeModel extends BaseTradeModel<ITrade> implements ITrade {
  static fromJson(data: TradeServer, rootStore: IRootStore): TradeModel {
    return new TradeModel({
      data: {
        id: data.id,
        stageType: data.key,
        tradeStatus: data.status,
        productTypeId: data.product_type_id,
        productId: data.product_id,
        weight: data.weight,
        weightUnitId: data.weight_unit_id,
        customerCompany: data.customer && CompanyModel.fromJson(data.customer.company),
        supplierCompany: data.supplier && CompanyModel.fromJson(data.supplier.company),
        customerEmployee: data.customer && CustomerModel.fromJson(data.customer),
        supplierEmployee: data.supplier && SupplierModel.fromJson(data.supplier),
        fixingDate: new Date(data.fixing_date),
        sellAmount: this.amountFromJson(data.sell_price, data.sell_price_unit),
        sellAmountTotal: this.amountFromJson(data.sell_price_total, data.sell_price_unit),
        transitCountryCargoArrivalDate: this.dateFromJson(data.arrived_in_transit_country_at),
        transitCountryCargoShipmentDate: this.dateFromJson(data.shipped_from_transit_country_at),
        destinationCountryCargoArrivalDate: this.dateFromJson(data.arrived_at),
        cargoDeliveredDate: this.dateFromJson(data.delivered_at),
        customerPayment: data.transaction_amount_usd,
        paymentDate: this.dateFromJson(data.finished_at),
        etdDate: this.dateFromJson(data.etd),
        fixingPrice: data.bid?.fixing_price ?? null,
      },
      rootStore: rootStore,
    });
  }
}
