import { ApiRequest } from '@kts-front/call-api';
import { action, makeObservable } from 'mobx';

import { MessageType } from '@/entities/message';
import { ITradeWorkflowStore } from '@/entities/trade';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel } from '@/shared/model';
import { InputModel, InputModelParams } from '@/shared/model/form/InputModel';
import { Nullable } from '@/shared/types/values';
import { stringLengthValidator } from '@/shared/utils/validators';

import { OptionFieldsAction, ShipmentType, StageType } from '../../types';

type TrackingLinkAction = OptionFieldsAction.setDestinationTrackingLink | OptionFieldsAction.setTransitTrackingLink;

type AttachingTrackingLinkParams = InputModelParams<string> & {
  tradeWorkflowStore: ITradeWorkflowStore;
  trackingLinkAction: TrackingLinkAction;
};

type AttachingTrackingLinkPayload = {
  action: TrackingLinkAction;
  link: string;
};

const T_OPTIONS = { ns: 'stage' } as const;

export class AttachingTrackingLinkModel extends InputModel<string> {
  private readonly _trackingLinkAction: TrackingLinkAction;

  private readonly _tradeWorkflowStore: ITradeWorkflowStore;

  private readonly _approveRequest: ApiRequest<unknown>;

  readonly loadingStage = new LoadingStageModel();

  constructor({ tradeWorkflowStore, trackingLinkAction, ...params }: AttachingTrackingLinkParams) {
    super(params);

    this._trackingLinkAction = trackingLinkAction;

    this._approveRequest = apiStore.createRequest({
      method: 'POST',
      multipartFormData: true,
      url: apiUrls.trade.action(tradeWorkflowStore.tradeId),
    });

    this._tradeWorkflowStore = tradeWorkflowStore;

    makeObservable(this, {
      approveTrackingLink: action.bound,
    });
  }

  private _toJson(): Nullable<AttachingTrackingLinkPayload> {
    const value = this.value;

    if (!value) {
      return null;
    }

    return {
      action: this._trackingLinkAction,
      link: value,
    };
  }

  async approveTrackingLink(): Promise<void> {
    const payload = this._toJson();

    if (this.loadingStage.isLoading || !payload) {
      return;
    }

    this.loadingStage.loading();

    const response = await this._approveRequest.call({
      multipartFormData: true,
      data: payload,
    });

    if (response.isError) {
      this.loadingStage.error();
      this._tradeWorkflowStore.rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('optionalFields.messages.saveLinkError', { ns: 'stage' }),
      });

      return;
    }

    this._tradeWorkflowStore.rootStore.notificationsStore.addNotification({
      type: MessageType.success,
      message: (t) => t('optionalFields.messages.saveLinkSuccess', { ns: 'stage' }),
    });

    this.changeInitializedByValue(true);

    this.loadingStage.success();

    this._tradeWorkflowStore.reloadStage();
  }

  static fromStore(tradeWorkflowStore: ITradeWorkflowStore): AttachingTrackingLinkModel {
    const { tradeInfo, stageModel, rootStore } = tradeWorkflowStore;

    const modelDisabled = tradeInfo.tradeDisabled || tradeInfo.stageDisabled;

    const isArrivedToTransitCountry = Boolean(tradeInfo.transitCountryCargoArrivalDate);
    const isArrivedToDestinationCountry = Boolean(tradeInfo.destinationCountryCargoArrivalDate);

    const isShipmentStage = tradeInfo.currentStageType === StageType.shipment;
    const isTransportationStage = tradeInfo.currentStageType === StageType.transportation;

    let labelContext: Nullable<ShipmentType> = null;

    if (rootStore.userStore.isOwner && stageModel.isShipmentTypeTransit) {
      labelContext = isArrivedToTransitCountry ? ShipmentType.direct : ShipmentType.transit;
    }

    const isDestinationTrackingLink =
      stageModel.isShipmentTypeDirect || (stageModel.isShipmentTypeTransit && isArrivedToTransitCountry);

    const trackingLinkAction = isDestinationTrackingLink
      ? OptionFieldsAction.setDestinationTrackingLink
      : OptionFieldsAction.setTransitTrackingLink;

    const initialValue = isDestinationTrackingLink ? tradeInfo.destinationTrackingLink : tradeInfo.transitTrackingLink;

    return new AttachingTrackingLinkModel({
      initialValue: initialValue ?? '',
      trackingLinkAction,
      label: (t) =>
        t('optionalFields.flightTrackingLink.label', {
          ...T_OPTIONS,
          context: labelContext,
        }),
      placeholder: (t) => t('optionalFields.flightTrackingLink.placeholder', T_OPTIONS),
      disabled: modelDisabled || isArrivedToDestinationCountry || (!isShipmentStage && !isTransportationStage),
      validators: [stringLengthValidator(128)],
      tradeWorkflowStore,
    });
  }
}
