import { BaseResponse } from '@kts-front/types';
import { action, computed, makeObservable, reaction, runInAction } from 'mobx';

import { CompanyType } from '@/entities/company';
import { CustomerServer, OwnerModel, SupplierServer, UserRole, UserServer } from '@/entities/user';
import { CustomerModel } from '@/entities/user/model/CustomerModel';
import { SupplierModel } from '@/entities/user/model/SupplierModel';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, LocalStore, ValueModel } from '@/shared/model';
import { RequestPollingModel } from '@/shared/model/RequestPollingModel';
import { ownLocalStorage, ownSessionStorage } from '@/shared/model/storage';
import { ApiErrorCode, ApiErrorData } from '@/shared/types/api';
import { Nullable } from '@/shared/types/values';

import { UserModelType } from '../types';

import { IUserStore } from './types';

export class UserStore extends LocalStore implements IUserStore {
  private _userModel = new ValueModel<Nullable<UserModelType>>(null);

  readonly notificationsPollingEvent: RequestPollingModel;

  readonly userLoadingStage = new LoadingStageModel();

  constructor() {
    super();

    this.notificationsPollingEvent = new RequestPollingModel({
      request: this._getNotificationsCount,
      timeout: 10_000,
    });

    makeObservable(this, {
      authorized: computed,
      userModel: computed,
      userRole: computed,
      companyType: computed,

      isOwnerAdmin: computed,
      isOwnerManager: computed,
      isOwnerObserver: computed,
      isOwnerMainRole: computed,
      isOwner: computed,

      isClientAdmim: computed,
      isClientEmployee: computed,
      isClientObserver: computed,
      isClientMainRole: computed,
      isClient: computed,

      isCustomer: computed,
      isSupplier: computed,
      newNotificationsCount: computed,

      logout: action.bound,
    });

    this.addReactions([
      reaction(
        () => this.authorized && !this.isOwnerMainRole,
        (isNeedPolling) => {
          if (isNeedPolling) {
            this.notificationsPollingEvent.watchProgress();
          } else {
            this.notificationsPollingEvent.stopPolling();
          }
        },
      ),
    ]);
  }

  get userModel(): Nullable<UserModelType> {
    return this._userModel.value;
  }

  get companyType(): Nullable<CompanyType> {
    return this.userModel?.type ?? null;
  }

  get ownerModel(): Nullable<OwnerModel> {
    return this.userModel instanceof OwnerModel ? this.userModel : null;
  }

  get customerModel(): Nullable<CustomerModel> {
    return this.userModel instanceof CustomerModel ? this.userModel : null;
  }

  get supplierModel(): Nullable<SupplierModel> {
    return this.userModel instanceof SupplierModel ? this.userModel : null;
  }

  get userRole(): Nullable<UserRole> {
    return this.userModel?.role ?? null;
  }

  get isOwnerAdmin(): boolean {
    return this.userRole === UserRole.ownerAdmin;
  }

  get isOwnerManager(): boolean {
    return this.userRole === UserRole.ownerManager;
  }

  get isOwnerObserver(): boolean {
    return this.userRole === UserRole.ownerObserver;
  }

  get isOwnerMainRole(): boolean {
    return this.isOwnerManager || this.isOwnerAdmin;
  }

  get isOwner(): boolean {
    return this.isOwnerMainRole || this.isOwnerObserver;
  }

  get isClientAdmim(): boolean {
    return this.userRole === UserRole.clientAdmin;
  }

  get isClientEmployee(): boolean {
    return this.userRole === UserRole.clientEmployee;
  }

  get isClientObserver(): boolean {
    return this.userRole === UserRole.clientObserver;
  }

  get isClientMainRole(): boolean {
    return this.isClientEmployee || this.isClientAdmim;
  }

  get isClient(): boolean {
    return this.isClientMainRole || this.isClientObserver;
  }

  get isCustomer(): boolean {
    return this.userModel instanceof CustomerModel;
  }

  get isSupplier(): boolean {
    return this.userModel instanceof SupplierModel;
  }

  get authorized(): boolean {
    return Boolean(this._userModel.value);
  }

  get newNotificationsCount(): number {
    return this.userModel?.newNotificationsCount.value ?? 0;
  }

  getCurrentUser = async (): Promise<BaseResponse> => {
    if (this.userLoadingStage.isLoading) {
      return { isError: true };
    }

    this.userLoadingStage.loading();

    const response = await this._currentUserRequest.call();

    if (response.isError) {
      this.userLoadingStage.error();

      const data: Nullable<ApiErrorData> = response.data?.data ?? null;

      if (data?.code === ApiErrorCode.tokenNotValid || data?.code === ApiErrorCode.userBlocked) {
        this.logout();
      }

      return { isError: true };
    }

    runInAction(() => {
      this.setUserFromJson(response.data);
      this.userLoadingStage.success();
    });

    return { isError: false };
  };

  setUserFromJson = (raw: UserServer): void => {
    let userModel: UserModelType;

    if (raw.company) {
      userModel =
        raw.company.type === CompanyType.customer
          ? CustomerModel.fromJson(raw as CustomerServer)
          : SupplierModel.fromJson(raw as SupplierServer);
    } else {
      userModel = OwnerModel.fromJson(raw);
    }

    this._setUser(userModel);
  };

  logout(): void {
    this._clearStorage();
    this._setUser(null);
  }

  private readonly _currentUserRequest = apiStore.createRequest<UserServer>({
    url: apiUrls.user.current,
  });

  private readonly _setUser = (value: Nullable<UserModelType>) => {
    this._userModel.change(value);
  };

  private readonly _getNotificationsCount = async (): Promise<BaseResponse> => {
    if (!this.userModel) {
      return { isError: true };
    }

    const response = await this._currentUserRequest.call();

    if (response.isError) {
      const data: Nullable<ApiErrorData> = response.data?.data ?? null;

      if (data?.code === ApiErrorCode.tokenNotValid || data?.code === ApiErrorCode.userBlocked) {
        this.logout();
      }

      return { isError: true };
    }

    if (this.userModel) {
      this.userModel.newNotificationsCount.change(response.data.new_notifications_count);
    }

    return { isError: false };
  };

  private readonly _clearStorage = (): void => {
    ownLocalStorage.clear();
    ownSessionStorage.clear();
  };
}
