import { ClientCompanyType, CompanyType, CompanyWithAdminServer } from '@/entities/company';
import { Nullable } from '@/shared/types/values';

/**
 * @field ownerAdmin - Администратор компании владельца (Arvar)
 * @field ownerManager - Менеджер компании владельца (Arvar)
 * @field ownerObserver - Наблюдатель компании владельца (Arvar)
 */
export enum OwnerUserRoleServer {
  ownerAdmin = 'administrator',
  ownerManager = 'manager',
  ownerObserver = 'observer',
}

/**
 * @field clientAdmin - Администратор компании клиента
 * @field clientEmployee - Сотрудник компании клиента
 * @field clientObserver - Наблюдатель компании клиента
 */
export enum CompanyUserRoleServer {
  clientAdmin = 'administrator',
  clientEmployee = 'employee',
  clientObserver = 'observer',
}

export type UserRoleServer = OwnerUserRoleServer | CompanyUserRoleServer;

/**
 * @field role - Роль пользователя
 * @field id - ID пользователя
 * @field email - Почта
 * @field name - Имя
 * @field surname - Фамилия
 * @field patronymic - Отчество
 * @field phone - Телефон
 * @field new_notifications_count - Кол-во новых уведомлений
 */
export type BaseUserServer<UR extends UserRoleServer = UserRoleServer> = {
  role: UR;
  id: number;
  email: string;
  name: string;
  surname: string;
  patronymic: Nullable<string>;
  phone: Nullable<string>;
  accepted: boolean;
  new_notifications_count: number;
};

/**
 * @field company - Компания пользователя
 */
export type UserWithCompanyServer<
  CT extends Nullable<ClientCompanyType>,
  UR extends CT extends ClientCompanyType ? CompanyUserRoleServer : OwnerUserRoleServer,
> = BaseUserServer<UR> & {
  company: CT extends ClientCompanyType ? CompanyWithAdminServer<CT> : null;
};

export type AdminServer = UserWithCompanyServer<null, OwnerUserRoleServer.ownerAdmin>;

export type ManagerServer = UserWithCompanyServer<null, OwnerUserRoleServer.ownerManager>;

export type ObserverServer = UserWithCompanyServer<null, OwnerUserRoleServer.ownerObserver>;

export type OwnerServer = AdminServer | ManagerServer | ObserverServer;

export type CompanyAdminServer = UserWithCompanyServer<ClientCompanyType, CompanyUserRoleServer.clientAdmin>;

export type SupplierServer = UserWithCompanyServer<CompanyType.supplier, CompanyUserRoleServer>;
export type CustomerServer = UserWithCompanyServer<CompanyType.customer, CompanyUserRoleServer>;

export type UserServer = OwnerServer | SupplierServer | CustomerServer;

export type CreateUserPayload = {
  email: string;
  name: string;
  surname: string;
  patronymic?: string;
  phone?: string;
};

export type EditUserPayload = Pick<CreateUserPayload, 'name' | 'surname'> & {
  patronymic: Nullable<string>;
  phone: Nullable<string>;
};
