import { Options } from '@/shared/types/values';

import { NotificationCategory, NotificationService } from './types/server';

const T_OPTIONS = { ns: 'notifications' } as const;

export const notificationServices: Array<NotificationService> = [
  NotificationService.telegram,
  NotificationService.email,
  NotificationService.chat,
];

export const notificationCategories: Array<NotificationCategory> = [
  NotificationCategory.statusChange,
  NotificationCategory.docUpload,
  NotificationCategory.docVerification,
];

export const notificationCategoryOptions: Options<NotificationCategory> = notificationCategories.map((category) => ({
  label: (t) => t(`category.${category}`, T_OPTIONS),
  value: category,
}));
