import { IngotIdType, IngotServer } from '@/entities/stage/types';
import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { Nullable } from '@/shared/types/values';
import { numberParser } from '@/shared/utils';
import { emptyValueValidator, numberValidator, stringLengthValidator } from '@/shared/utils/validators';

const T_OPTIONS = { ns: 'stage' } as const;

type IngotModelParams = {
  ingotNumber: string;
  weight: string;
  ligatureWeight: string;
  metalPurity: string;
  year: Nullable<Date>;
  uin: string;
};

export class IngotModel {
  readonly ingotNumber: InputModel;
  readonly weight: InputModel;
  readonly ligatureWeight: InputModel;
  readonly metalPurity: InputModel;
  readonly year: DatePickerModel;
  readonly uin: InputModel;

  readonly fieldUin: BaseFieldModel<any>[];
  readonly fieldYear: BaseFieldModel<any>[];

  private readonly _fields: BaseFieldModel<any>[];

  constructor(params: IngotModelParams) {
    (this.ingotNumber = new InputModel({
      initialValue: params.ingotNumber,
      placeholder: (t) => t('customerSpecification.ingotNumberPlaceholder', T_OPTIONS),
      required: true,
      validators: [emptyValueValidator(), stringLengthValidator(16)],
      parser: (value) => value.replace(/[^0-9a-zA-Zа-яА-ЯёЁ]/g, ''),
    })),
      (this.weight = new InputModel({
        initialValue: params.weight,
        placeholder: (t) => t('customerSpecification.weightPlaceholder', T_OPTIONS),
        required: true,
        validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
        parser: numberParser({ maxFractionDigits: 3 }),
      })),
      (this.ligatureWeight = new InputModel({
        initialValue: params.ligatureWeight,
        placeholder: (t) => t('customerSpecification.weightPlaceholder', T_OPTIONS),
        required: true,
        validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
        parser: numberParser({ maxFractionDigits: 3 }),
      })),
      (this.metalPurity = new InputModel({
        initialValue: params.metalPurity,
        placeholder: (t) => t('customerSpecification.metalPurityPlaceholder', T_OPTIONS),
        required: true,
        validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
        parser: numberParser(),
      })),
      (this.year = new DatePickerModel({
        initialValue: params.year,
        placeholder: (t) => t('customerSpecification.year', T_OPTIONS),
        required: true,
        validators: [emptyValueValidator()],
        ignoreOnBlurValidation: true,
      })),
      (this.uin = new InputModel({
        initialValue: params.uin,
        placeholder: (t) => t('customerSpecification.uin', T_OPTIONS),
        required: true,
        validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
        parser: (value) => (value as string).replace(/[^0-9+]/g, ''),
      })),
      (this.fieldUin = [this.ingotNumber, this.weight, this.ligatureWeight, this.metalPurity, this.uin]);
    this.fieldYear = [this.ingotNumber, this.weight, this.ligatureWeight, this.metalPurity, this.year];
    this._fields = [...this.fieldUin, this.year];
  }

  getFields = (value: IngotIdType): BaseFieldModel<any>[] => {
    if (value === IngotIdType.year) {
      return this.fieldYear;
    }

    return this.fieldUin;
  };

  reset(): void {
    this._fields.forEach((field) => field.reset());
  }

  static fromDefaultParams(): IngotModel {
    return new IngotModel({
      ingotNumber: '',
      weight: '',
      ligatureWeight: '',
      metalPurity: '',
      year: null,
      uin: '',
    });
  }

  static formJson(data: IngotServer): IngotModel {
    return new IngotModel({
      ingotNumber: data.bar_number,
      weight: data.weight.toString(),
      ligatureWeight: data.ligature_weight ? data.ligature_weight.toString() : '',
      metalPurity: data.metal_purity.toString(),
      year: new Date(Number(data.year), 0, 1),
      uin: data.uin?.toString() || '',
    });
  }
}
