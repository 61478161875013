import { SupportedLocale } from '../types/localization';

export const shared = {
  en: {
    date: {
      today: 'Today',
      yesterday: 'Yesterday',
      yesterdayWithTime: 'Yesterday, {{ time }}',
      tomorrow: 'Tomorrow',
    },
    weightUnits: {
      oz_one: 'ounce',
      oz_other: 'ounces',
    },
    placeholders: {
      search: 'Search',
      select: 'Select from the directory',
      date: 'Specify the date',
      price: 'Specify the price',
      sum: 'Specify the sum',
    },
    buttons: {
      add: 'Add',
      approve: 'Approve',
      edit: 'Edit',
      apply: 'Apply',
      save: 'Save',
      delete: 'Delete',
      upload: 'Upload',
      reset: 'Reset',
      cancel: 'Cancel',
      generate: 'Generate',
      create: 'Create',
      return: 'Return',
      insert: 'Insert',
    },
    validationErrors: {
      buyPriceUnit: 'Enter buy price unit',
      email: 'Enter correct email',
      paymentUnit: 'Enter payment unit',
      phoneNumber: 'Enter correct phone number',
      required: 'Field is required',
      sellPriceUnit: 'Enter sell price unit',
      weightUnit: 'Enter weight unit',
      fileSize: 'File should not be larger than {{ fileSize }}\u00A0MB',
      fileFormat: 'Invalid file format',
      stringLength: 'Value should not be longer than {{ length }}\u00A0characters',
      numberLimit: 'The value must be greater than {{ min }}{{ unit }} and less than {{ max }}{{ unit }}',
      numberLimitByMin: 'The value must be greater than {{ min }}{{ unit }}',
      numberLimitByMax: 'The value must be less than {{ max }}{{ unit }}',
      invalidNumber: 'The value must be a valid number and not equal to zero',
      exceedsTotalFilesSize: 'The total size of all files must not exceed {{ fileSize }}\u00A0MB',
      uniqueness: 'The value must be unique',
    },
    locales: {
      [SupportedLocale.ru]: 'Russian',
      [SupportedLocale.en]: 'English',
      [SupportedLocale.zh]: 'Chinese',
    },
    components: {
      UploadDragger: {
        title: 'Drop files here or click to upload',
        subTitle: 'File should not be larger than 100\u00A0MB',
      },
      OfflineStatus: {
        title: 'Check your internet connection',
        subTitle: 'Check your Wi-Fi or mobile network and try again',
        button: 'Retry',
      },
      EmptySearchStub: {
        description: 'No data found',
        button: 'Reset search',
      },
      StageContentDesktop: {
        title: 'Current step',
      },
      StageContentTablet: {
        labels: {
          required: 'Required steps',
          optional: 'Optional steps',
        },
      },
      FormItem: {
        optional: 'optional',
      },
      TableCheckboxListFilter: {
        emptyList: 'List is empty',
        emptyFilteredList: 'Nothing found matching your request',
      },
    },
    userHandling: {
      name: 'Name',
      phoneNumber: 'Phone number',
      address: 'Address',
    },
    errorBoundary: {
      title: 'An unknown error occurred',
      subTitle: 'Reload the page',
      buttonText: 'Reboot',
    },
  },
  ru: {
    date: {
      today: 'Сегодня',
      yesterday: 'Вчера',
      yesterdayWithTime: 'Вчера, {{ time }}',
      tomorrow: 'Завтра',
    },
    weightUnits: {
      oz_one: 'унция',
      oz_few: 'унции',
      oz_many: 'унций',
      oz_other: 'унции',
    },
    placeholders: {
      search: 'Найти',
      select: 'Выберите из справочника',
      date: 'Укажите дату',
      price: 'Укажите цену',
      sum: 'Укажите сумму',
    },
    buttons: {
      add: 'Добавить',
      edit: 'Редактировать',
      approve: 'Подтвердить',
      apply: 'Применить',
      save: 'Сохранить',
      delete: 'Удалить',
      upload: 'Загрузить',
      reset: 'Сбросить',
      cancel: 'Отменить',
      generate: 'Сгенерировать',
      create: 'Создать',
      return: 'Вернуться',
      insert: 'Вставить',
    },
    validationErrors: {
      buyPriceUnit: 'Укажите валюту покупки',
      email: 'Введён некорректный email',
      paymentUnit: 'Укажите валюту платежа',
      phoneNumber: 'Введён неверный формат номера',
      required: 'Это обязательное поле',
      sellPriceUnit: 'Укажите валюту продажи',
      weightUnit: 'Укажите единицу измерения веса',
      fileSize: 'Файл не должен превышать {{ fileSize }}\u00A0MB',
      fileFormat: 'Недопустимый формат файла',
      stringLength: 'Значение не должно превышать {{ length }}\u00A0символов',
      numberLimit: 'Значение должно быть больше {{ min }}{{ unit }} и меньше {{ max }}{{ unit }}',
      numberLimitByMin: 'Значение должно быть больше {{ min }}{{ unit }}',
      numberLimitByMax: 'Значение должно быть меньше {{ max }}{{ unit }}',
      invalidNumber: 'Значение должно быть корректным числом и не равно нулю',
      exceedsTotalFilesSize: 'Общий размер всех файлов не должен превышать {{ fileSize }}\u00A0MB',
      uniqueness: 'Значение должно быть уникальным',
    },
    locales: {
      [SupportedLocale.ru]: 'Русский',
      [SupportedLocale.en]: 'Английский',
      [SupportedLocale.zh]: 'Китайский',
    },
    components: {
      UploadDragger: {
        title: 'Перетащите файлы сюда или нажмите, чтобы загрузить',
        subTitle: 'Файл не должен быть больше 100\u00A0MB',
      },
      OfflineStatus: {
        title: 'Проверьте подключение к интернету',
        subTitle: 'Проверьте подключение к Wi-Fi или мобильной сети и повторите попытку',
        button: 'Повторить попытку',
      },
      EmptySearchStub: {
        description: 'Ничего не найдено',
        button: 'Сбросить поиск',
      },
      StageContentDesktop: {
        title: 'Текущий шаг',
      },
      StageContentTablet: {
        labels: {
          required: 'Обязательные шаги',
          optional: 'Необязательные шаги',
        },
      },
      FormItem: {
        optional: 'oпционально',
      },
      TableCheckboxListFilter: {
        emptyList: 'Список пуст',
        emptyFilteredList: 'По вашему запросу ничего не найдено',
      },
    },
    userHandling: {
      name: 'Название',
      phoneNumber: 'Номер телефона',
      address: 'Адрес',
    },
    errorBoundary: {
      title: 'Произошла неизвестная ошибка',
      subTitle: 'Перезагрузите страницу',
      buttonText: 'Перезагрузить',
    },
  },
  zh: {
    date: {
      today: '今日',
      yesterday: '昨日',
      yesterdayWithTime: '昨日, {{ time }}',
      tomorrow: '明日',
    },
    // weightUnits: {
    //   oz_one: 'унция',
    //   oz_few: 'унции',
    //   oz_many: 'унций',
    // },
    placeholders: {
      search: '寻找',
      select: '从目录中选择',
      date: '请输入日期',
      price: '价格',
      // sum: 'Укажите сумму',
    },
    buttons: {
      add: '添加',
      approve: '确认',
      apply: '应用',
      save: '保存',
      delete: '删除',
      upload: '上传',
      reset: '重置',
      cancel: '取消',
      generate: '生成',
      create: '生成',
      edit: '编辑',
      return: '返回',
      // insert: 'Вставить',
    },
    validationErrors: {
      buyPriceUnit: '指定购买货币',
      email: '输入的电邮不正确',
      paymentUnit: '指定付款货币',
      phoneNumber: '输入的号码格式不准确',
      required: '必填',
      sellPriceUnit: '指定销售货币',
      weightUnit: '指定重量单位',
      fileSize: '文件不得超过 {{ fileSize }}\u00A0MB',
      fileFormat: '文件格式無效',
      stringLength: '该值不得超过 {{ length }}\u00A0个字符',
      numberLimit: '值应大于{{ min }}{{ unit }}且小于{{ max }}{{ unit }}',
      numberLimitByMin: '值应大于{{ min }}{{ unit }}',
      numberLimitByMax: '值应小于{{ max }}{{ unit }}',
      invalidNumber: '值应是有效数字且不为零',
      // exceedsTotalFilesSize: 'Общий размер всех файлов не должен превышать {{ fileSize }}\u00A0MB',
      // uniqueness: 'Значение должно быть уникальным',
    },
    locales: {
      [SupportedLocale.ru]: '俄文',
      [SupportedLocale.en]: '英文',
      [SupportedLocale.zh]: '中文',
    },
    components: {
      UploadDragger: {
        title: '将文件拖至此处或点击上传',
        subTitle: '文件不得大于100\u00A0MB',
      },
      OfflineStatus: {
        title: '请检查您的互联网连接',
        subTitle: '请检查您的Wi-Fi或移动数据连接，然后重试',
        button: '再试',
      },
      EmptySearchStub: {
        description: '未找到匹配结果',
        button: '重置搜索',
      },
      StageContentDesktop: {
        title: '当前步骤',
      },
      StageContentTablet: {
        labels: {
          required: '强制性步骤',
          optional: '可选步骤',
        },
      },
      FormItem: {
        optional: '可选',
      },
      TableCheckboxListFilter: {
        emptyList: '列表為空',
        emptyFilteredList: '沒有找到符合您請求的結果',
      },
    },
    userHandling: {
      name: '名称',
      phoneNumber: '电话号码',
      address: '地址',
    },
    errorBoundary: {
      title: '发生未知错误',
      subTitle: '重新加载页面',
      buttonText: '重新加载',
    },
  },
};
