import { BaseResponse } from '@kts-front/types';

import { ListModel } from '@/shared/model';

import { IDictionariesStore, IDictionaryStore } from '../../types';
import { CompanyDictionaryModel } from '../CompanyDictionaryModel';

export class CompaniesStore implements IDictionaryStore<CompanyDictionaryModel> {
  readonly list = new ListModel<CompanyDictionaryModel, number>();

  private _dictionariesStore: IDictionariesStore;

  constructor(dictionariesStore: IDictionariesStore) {
    this._dictionariesStore = dictionariesStore;
  }

  async load(): Promise<BaseResponse> {
    const { loadCompanies } = this._dictionariesStore;

    const response = await loadCompanies();

    return response;
  }
}
